import React from 'react'
import { AppToolbar, ReSurvey, ReIndicator } from '../../components'
import { useStyles } from './style'

const Questionnaire = ({ context }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppToolbar fullWidth />
      <main className={classes.main}>
        {context === 'survey' ? <ReSurvey /> : <ReIndicator />}
      </main>
    </div>
  )
}

export default Questionnaire
