import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '1200px !important',
    height: '800px !important',
    borderRadius: '80px',
    padding: '35px 65px',
    overflowY: 'clip !important'
  },
  titleBorder: {
    display: 'block',
    width: '77px',
    height: 0,
    border: '3px solid #465270',
    marginTop: '10px',
    marginBottom: '0px'
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: theme.spacing(4),
    alignItems: 'flex-start'
  },
  dialogRoot: {
    overflowY: 'clip !important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'space-around'
  },
  skipButton: {
    color: theme.palette.button.default,
    border: '2px solid #465270',
    textTransform: 'none',
    fontWeight: 700,
    fontSize: 14,
    minWidth: '145px',
    padding: '8px 28px',
    '&:hover': {
      border: '2px solid #465270'
    }
  },
  missingEntriesContainer: {
    position: 'relative'
  },
  missingEntriesBox: {
    position: 'absolute',
    top: '52px',
    left: 0,
    zIndex: 9,
    backgroundColor: 'white',
    border: '1px solid #CACCCF',
    borderRadius: '4px',
    padding: '20px',
    width: '360px',
    overflow: 'scroll',
    maxHeight: '55vh'
  },
  missingEntriesLabel: {
    color: '#52575C',
    fontSize: '14px',
    fontWeight: 400
  },
  missingEntriesText: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 400,
    marginTop: '10px'
  },
  missingEntriesDivider: {
    width: '100%',
    height: 0,
    borderTop: '1px solid #5EC3CD',
    margin: '10px 0'
  },
  dateBtn: {
    textTransform: 'none',
    margin: '10px 0 4px'
  },
  trackerMissingWrapper: {
    height: '150px',
    width: '240px',
    overflowY: 'scroll'
  },
  trackerMissing: {
    fontSize: '10px',
    marginBottom: theme.spacing(0.5)
  },
  trackerUpToDate: {
    fontSize: '12px',
    color: '#219653'
  },
  missingEntriesDays: {
    paddingTop: theme.spacing(2),
    color: 'rgba(70, 82, 112, 1)'
  },
  trackLaterLink: {
    cursor: 'pointer'
  }
}))
