import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import { Typography, IconButton, Tooltip, Grid, Skeleton } from '@mui/material'
import AccordionDetails from '@mui/material/AccordionDetails'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import classNames from 'classnames'
import { useStyles } from './style'

const InformationalCard = ({ title, description, removeHeaderBottomSpaceOnOpen, subHeader, accState = false, loading, ...props }) => {
  const classes = useStyles()
  const [accordionExpanded, setAccordionExpanded] = useState(accState)

  const toggleAccordionState = () => {
    setAccordionExpanded(!accordionExpanded)
  }

  if (loading) {
    return <Skeleton variant='rectangular' height={64} />
  }

  return (
    <div className={classes.root}>
      <Accordion
        square
        classes={{
          root: classes.accordion
        }}
        expanded={accordionExpanded}
      >
        <Grid
          container
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          className={classNames(classes.header, { [classes.headerNoBottomSpace]: removeHeaderBottomSpaceOnOpen && accordionExpanded })}
        >
          <Grid item xs={8}>
            <Grid
              container
              direction='row'
              alignItems='center'
              justifyContent='flex-start'
            >
              <Typography
                variant='h3'
                className={classes.heading}
                style={props.minHeight ? { minHeight: props.minHeight } : {}}
              >{title}
              </Typography>
              {description && (
                <Tooltip title={description}>
                  <InfoOutlinedIcon className={classes.informationIcon} />
                </Tooltip>
              )}
            </Grid>
            {accordionExpanded && (
              <Grid container>
                <Grid item>
                  {subHeader}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <IconButton onClick={toggleAccordionState} size='large'>
              {accordionExpanded
                ? <IndeterminateCheckBoxOutlinedIcon className={classes.collapseIcon} />
                : <AddBoxOutlinedIcon className={classes.expandIcon} />}
            </IconButton>
          </Grid>
        </Grid>
        <AccordionDetails>
          <div className={classes.content}>
            {props.children}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default InformationalCard
