import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Grid, Skeleton, Typography } from '@mui/material'
import {
  FullScreenToolbar,
  Overlay,
  ToolPathwayItem,
  ImageModal
} from '../../components'
import { useStyles } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { userSlice, contentSlice, trackerSlice } from '../../reducers'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { useLocation } from 'react-router'
import { clearToolPathwayContent } from '../../reducers/content/slice'
import { replaceIp } from '../../helpers/utils'
import { featureSelector } from '../../reducers/user/selectors'
import Forbidden from '../403'
import { useEventContext } from '../../contexts/event'

export default function ToolPathway () {
  const classes = useStyles()
  const { state } = useLocation()
  const [loading, setLoading] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()

  const user = useSelector(userSlice.selectors.selectUser)
  const userRead = useSelector(userSlice.selectors.selectUserRead)
  const showing = useSelector(userSlice.selectors.selectShowToolpathOrQuestionnaire)
  const features = useSelector(featureSelector)
  const { getUser } = useEventContext()
  const pathwayToShow = showing === 'toolpathway' ? user?.toolpathway : user?.questionnairePathway

  const currentContent = useSelector(
    contentSlice.selectors.selectToolPathwayCurrentContent
  )
  const oldContentId = useRef(null)
  const [getContentBurst] = useCorvusEventList([
    contentSlice.eventBus.getToolPathwayContent
  ])
  const [saveUserReadBurst] = useCorvusEventList([trackerSlice.eventBus.saveUserRead])

  const fetchContent = useCallback(
    id => {
      setLoading(true)
      getContentBurst.trigger(id)
    },
    [getContentBurst]
  )

  useEffect(() => {
    if (user?.toolpathway?.length && state) {
      fetchContent(state.id)
    }
  }, [user, state, fetchContent])

  useEffect(() => {
    return () => {
      if (oldContentId.current !== null) {
        saveUserReadBurst.trigger({ contentId: oldContentId.current })
        dispatch(clearToolPathwayContent())
        setLoading(true)
      }
    }
  }, [saveUserReadBurst, dispatch])

  useEffect(() => {
    if (currentContent) {
      if (currentContent.id !== oldContentId.current) {
        if (oldContentId.current !== null) {
          saveUserReadBurst.trigger({ contentId: oldContentId.current })
        }
        oldContentId.current = currentContent.id
      }
      setLoading(false)
    }
  }, [currentContent, saveUserReadBurst])

  const getItemStatus = id => {
    if (userRead.find(i => i.sk === id.toString())) return 'read'
    else return 'pending'
  }

  const openModal = () => {
    if (currentContent?.acf?.hd_image) {
      setModalOpen(true)
    }
  }

  if (!loading && !currentContent) {
    return <Typography>Something went wrong... please try again</Typography>
  }

  return (
    !features.TOOL_PATHWAYS && !getUser.isWorking ? <Forbidden />
      : !getUser.isWorking
        ? <Overlay>
          <FullScreenToolbar />
          <ImageModal
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
            image={currentContent?.acf?.hd_image ? currentContent?.acf?.hd_image : currentContent?.jetpack_featured_media_url}
            alt={currentContent?.slug}
          />
          <Grid container justifyContent='center' className={classes.container}>
            <Grid id='tool-pathway-content' item xs={5} className={classes.content}>
              {!loading && currentContent?.title && currentContent?.content ? (
                <>
                  <Typography
                    variant='h4'
                    className={classes.contentTitle}
                    dangerouslySetInnerHTML={{
                      __html: currentContent.title.rendered
                    }}
                  />
                  {currentContent.acf?.type !== 'Video' && currentContent.jetpack_featured_media_url && (
                    <div onClick={openModal}>
                      <img src={currentContent?.acf?.hd_image ? currentContent?.acf?.hd_image : currentContent.jetpack_featured_media_url} width='100%' alt={currentContent.slug} />
                    </div>
                  )}
                  <div
                    id='toolpathway-current-content'
                    className={classes.customizeContent}
                    dangerouslySetInnerHTML={{
                      __html: replaceIp(currentContent.content.rendered)
                    }}
                  />
                </>
              ) : (
                <Skeleton height={600} variant='rectangular' />
              )}
            </Grid>
            <Grid
              id='tool-pathway-playlist'
              item
              xs={3}
              className={classes.toolpathwayPlaylist}
            >
              {pathwayToShow ? (
                <>
                  <Typography variant='h6' className={classes.toolpathwayTitle}>
                    Tool Pathway
                  </Typography>
                  <Grid container direction='column' className={classes.toolpathwayPlaylistContent}>
                    {pathwayToShow.map((c, i) => (
                      <ToolPathwayItem
                        disabled={loading}
                        key={c.id}
                        item={c}
                        selectContent={() => fetchContent(c.id)}
                        selected={currentContent && currentContent.id === c.id}
                        itemStatusForUser={getItemStatus(c.id)}
                        bottomTrail={i !== pathwayToShow.length - 1}
                      />
                    ))}
                  </Grid>
                </>
              ) : (
                <Skeleton height={600} />
              )}
            </Grid>
          </Grid>
        </Overlay> : <>Loading</>
  )
}
