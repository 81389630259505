import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  chipSuccess: {
    backgroundColor: `${theme.palette.success.main}90`,
    color: '#0b6345'
  },
  chipWarning: {
    backgroundColor: `${theme.palette.warning.main}90`,
    color: '#755b19'
  },
  seatsAvailable: {
    borderLeft: `1px solid ${theme.palette.secondary.dark}`,
    paddingLeft: theme.spacing(1)
  }
}))
