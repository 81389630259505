import makeStyles from '@mui/styles/makeStyles'

const drawerWidth = '260'

export const useStyles = makeStyles(theme => ({
  root: {
    color: '#fff'
  },
  appToolbar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: '#fff',
    height: '88px',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'none'
  },
  profileImageWrapper: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#D7D7D7',
    border: '1px solid #52575C',
    marginRight: theme.spacing(1.5),
    overflow: 'hidden',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center'
  },
  profileImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  fullAppToolbar: {
    width: '100%',
    backgroundColor: '#fff',
    minHeight: '88px',
    display: 'flex',
    justifyContent: 'center'
  },
  toolbarOpts: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(2)
    }
  },
  header: {
    color: theme.palette.secondary.dark
  },
  invisible: {
    color: '#FFF'
  },
  noHover: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  marginBorderRight: {
    paddingRight: theme.spacing(2),
    borderRight: '2px solid #DBDDE0',
    color: '#303155'
  }
}))
