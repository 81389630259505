import { Typography, Button, Divider } from '@mui/material'
import React from 'react'
import { useStyles } from './style.js'
import GetAppIcon from '@mui/icons-material/GetApp'
import { indicatorLevelDescriptions } from '../../../helpers/copy'

const SmallDivider = () => {
  const classes = useStyles()
  return (
    <div className={classes.smallDivider} />
  )
}

const IndicatorSurveyResult = ({ score, level, pdfLink, levelKey }) => {
  const classes = useStyles()
  const lowLevelKey = ['breakdown', 'fragmentation'].includes(levelKey)
  return (
    <div className={classes.root}>
      {!lowLevelKey && (
        <>
          <Typography className={classes.resultHeader} variant='body2'>Your Resilience Score is {score}</Typography>
          <Typography className={classes.resultLevel} variant='body2'>You are {level === 'Break Even' ? `in the middle of ${level}` : level}</Typography>
          <SmallDivider />
        </>
      )}
      <div className={classes.results}>
        {!lowLevelKey && pdfLink !== '' && (
          <>
            <Typography variant='subtitle1'>Your Personalized Report</Typography>
            <div className={classes.reportsBox}>

              <Button
                className={classes.reportsBtn}
                startIcon={<GetAppIcon />}
                variant='outlined'
                onClick={() => window.open(`${pdfLink}`)}
              >
                Download Your Report
              </Button>
            </div>
          </>)}
        <Typography className={classes.resultInsights} variant='body2'>Insights</Typography>
        {indicatorLevelDescriptions[level].map((text, i) =>
          <Typography key={i} className={classes.resultText} variant='body2'><div dangerouslySetInnerHTML={{ __html: text }} /></Typography>
        )}
        <Divider />
      </div>
    </div>
  )
}

export default IndicatorSurveyResult
