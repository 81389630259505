import React from 'react'
import { Grid } from '@mui/material'
import { LoginHeader, LoginBody } from '../../components'
import { useStyles } from './style'

const Login = () => {
  const { fullHeight } = useStyles()

  return (
    <div>
      <LoginHeader />
      <Grid container justifyContent='center' alignItems='center' className={fullHeight}>
        <LoginBody />
      </Grid>
    </div>
  )
}

export default Login
