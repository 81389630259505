import React from 'react'
import { Slider } from '@mui/material'
import { useStyles } from './style'

const QuestionScore = ({ handleSend, initialValue, type = 'evaluation', reverse = false }) => {
  const classes = useStyles()
  initialValue = initialValue || [1, 6]
  const parseReversedValue = (value) => {
    const parsed = value.map(v => 7 - v)
    return parsed
  }

  const [value, setValue] = React.useState(reverse ? parseReversedValue(initialValue) : initialValue)
  const handleChange = (_, newValue, idx) => {
    setValue(newValue)
  }

  const handleSave = () => {
    handleSend({ scoreValue: reverse ? parseReversedValue(value) : value })
  }

  const marks = [
    {
      value: 1,
      label: type === 'evaluation' ? 'Completely disagree' : 'Very rarely/ Never'
    },
    {
      value: 2,
      label: type === 'evaluation' ? 'Strongly disagree' : 'Very occasionally'
    },
    {
      value: 3,
      label: type === 'evaluation' ? 'Disagree a bit' : 'Sometimes'
    },
    {
      value: 4,
      label: type === 'evaluation' ? 'Agree a bit' : 'Often'
    },
    {
      value: 5,
      label: type === 'evaluation' ? 'Strongly agree' : 'Very frequently'
    },
    {
      value: 6,
      label: type === 'evaluation' ? 'Completely agree' : 'All the time'
    }
  ]

  return (
    <div className={classes.scoreRoot}>
      <Slider
        classes={{ root: classes.sliderRoot, markLabel: classes.markLabel, thumb: classes.thumb, rail: classes.rail, track: initialValue ? classes.answered : null }}
        onChange={handleChange}
        onBlur={handleSave}
        value={value}
        marks={marks}
        min={1}
        max={6}
        valueLabelDisplay='off'
        // scale={reverse ? (v) => 7 - v : (v) => v}
        steps={1}
      />
    </div>
  )
}

export default QuestionScore
