import { useEffect, useState, useCallback } from 'react'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateMoment from '@mui/lab/AdapterMoment'
import { Modal, Paper, TextField, Typography, Button, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import { useStyles } from './style'
import { Close } from '@mui/icons-material'
import { TrackerConfiguration } from '../../../../components'

const style = (isTracker) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isTracker ? 700 : 400
})

export const EntryModal = ({ open, handleClose, onSubmit, onDelete, isTracker = false, isEdit = false, currentStart, currentEnd, currentTrackerSettings, minDate, maxDate }) => {
  const classes = useStyles()
  const [startDate, setStartDate] = useState(currentStart || moment())
  const [endDate, setEndDate] = useState(currentEnd || moment().add(1, 'month'))
  const [trackerSettings, setTrackerSettings] = useState({
    main: 3,
    sleep: 3,
    stress: 3,
    wellbeing: 3,
    contentedness: 3
  })

  const [isTrackerMandatory, setIsTrackerMandatory] = useState({
    main: true,
    sleep: false,
    stress: false,
    wellbeing: false,
    contentedness: false
  })

  useEffect(() => {
    if (currentStart) setStartDate(moment(currentStart))
  }, [currentStart])

  useEffect(() => {
    if (currentEnd) setEndDate(moment(currentEnd))
  }, [currentEnd])

  useEffect(() => {
    if (isTracker && currentTrackerSettings) setTrackerSettings(currentTrackerSettings)
  }, [isTracker, currentTrackerSettings])

  const checkEntryDate = useCallback(() => {
    if (startDate.isAfter(endDate)) return true
    if (!startDate.isBetween(moment(minDate).subtract(1, 'day'), moment(maxDate).add(1, 'day'))) return true
    if (!endDate.isBetween(moment(minDate).subtract(1, 'day'), moment(maxDate).add(1, 'day'))) return true
    if (endDate.isBefore(moment())) return true
    return false
  }, [startDate, endDate, minDate, maxDate])

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style(isTracker)}>
        <Paper elevation={3} className={classes.modalWrapper}>
          <IconButton className={classes.modalCloseButton} onClick={handleClose}><Close /></IconButton>
          <Typography variant='h5'>
            {isEdit ? 'Edit' : 'New'} Entry
          </Typography>
          {
            isTracker && (
              <div>
                <TrackerConfiguration
                  values={trackerSettings}
                  isMandatoryValues={isTrackerMandatory}
                  handleChanges={(name, value) => setTrackerSettings({ ...trackerSettings, [name]: value })}
                  handleMandatoryChanges={(name, value) => setIsTrackerMandatory({ ...isTrackerMandatory, [name]: value })}
                  isWorking={false}
                />
              </div>
            )
          }
          <LocalizationProvider dateAdapter={AdapterDateMoment}>
            <div className={classes.datePickerWrapper}>
              <DesktopDatePicker
                label='Start'
                inputProps={{ 'data-test': 'new-cohort-invite-input-start' }}
                value={startDate}
                minDate={moment(minDate)}
                maxDate={moment(maxDate)}
                inputFormat='DD/MM/YYYY'
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DesktopDatePicker
                label='End'
                inputProps={{ 'data-test': 'new-cohort-invite-input-end' }}
                value={endDate}
                minDate={startDate}
                maxDate={moment(maxDate)}
                inputFormat='DD/MM/YYYY'
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </LocalizationProvider>
          <div className={classes.modalButtonWrapper}>
            {
              isEdit && (
                <Button
                  color='secondary'
                  variant='outlined'
                  fullWidth
                  onClick={() => {
                    onDelete()
                    handleClose()
                  }}
                >
                  Delete entry
                </Button>
              )
            }
            <Button
              data-test='new-cohort-features-button-done'
              color='secondary'
              variant='outlined'
              fullWidth
              disabled={checkEntryDate()}
              onClick={() => {
                onSubmit(startDate, endDate, trackerSettings, isTrackerMandatory)
                handleClose()
              }}
            >
              Done
            </Button>
          </div>
        </Paper>
      </Box>
    </Modal>
  )
}
