import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ palette: { button, primary } }) => ({
  container: {
    position: 'relative',
    backgroundColor: '#F2F2F2',
    borderRadius: '100px',
    overflow: 'hidden',
    width: 'auto',
    height: ({ height }) => height || '35px'
  },
  toggle: {
    position: 'absolute',
    height: '100%',
    width: ({ size }) => `${(100 / size)}%`,
    borderRadius: '100px',
    transition: 'all 0.3s ease-in-out',
    top: 0,
    backgroundColor: ({ bgColor }) => bgColor || primary.main,
    left: ({ selectedPosition, size }) => `${100 * (selectedPosition / size)}%`
  },
  buttonBase: {
    width: ({ itemWidth }) => itemWidth || '100%',
    height: '100%'
  },
  optionContainer: {
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 8px',
    minWidth: '72px',
    minHeight: '25px'

  },
  optionTypo: {
    fontSize: '14px',
    color: 'black',
    transition: 'all 0.3s ease-in-out'
  },
  optionTypoSelected: {
    color: 'white'
  }
}))
