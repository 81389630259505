import { createSelector } from '@reduxjs/toolkit'
import name from './name'

const selectSliceData = state => state[name]

const selectUser = state => selectSliceData(state).user
const selectTrackerAnswers = state => selectSliceData(state).user.trackerAnswers
const selectFeatures = state => selectSliceData(state).user.features
const selectUserRead = state => selectSliceData(state).userRead
const selectShowToolpathOrQuestionnaire = state => selectSliceData(state).showToolPathwarOrQuestionnaire
const loadedPathways = state => selectSliceData(state).loadedPathways
const selectedEntity = state => selectSliceData(state).selectedEntity
const dashboardData = state => selectSliceData(state).dashboardData
const tenant = state => selectSliceData(state).tenant
const selectedView = state => selectSliceData(state).selectedView
const viewLoading = state => selectSliceData(state).viewLoading
const userRole = state => selectSliceData(state).userRole

const featureSelector = createSelector([selectFeatures], features =>
  features?.reduce((acc, feat) => ({ ...acc, [feat.featureId]: true }), {}) || {}
)

export {
  selectUser,
  selectTrackerAnswers,
  selectedView,
  selectedEntity,
  dashboardData,
  tenant,
  viewLoading,
  selectUserRead,
  selectShowToolpathOrQuestionnaire,
  loadedPathways,
  userRole,
  featureSelector
}
