import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
  },
  accordion: {
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    borderRadius: 8,
    background: '#fff',
    paddingLeft: 15
  },
  accordionStateIcon: {
    fill: '#A0A4A8'
  },
  accordionSummaryContent: {
    margin: '24px 0 !important'
  },
  header: {
    padding: theme.spacing(1)
  },
  headerNoBottomSpace: {
    paddingBottom: 0
  },
  heading: {
    color: '#11263C',
    fontSize: 20,
    lineHeight: '26px',
    fontWeight: 'bold'
  },
  informationIcon: {
    cursor: 'pointer',
    margin: '0 6px',
    width: 19,
    height: 19,
    fill: '#D0D1D2'
  },
  collapseIcon: {
    fill: '#A0A4A8'
  },
  expandIcon: {
    fill: theme.palette.primary.dark
  },
  popover: {
    backgroundColor: 'grey',
    padding: '16px',
    color: 'white'
  },
  content: {
    color: '#465270',
    fontSize: 16,
    textAlign: 'left',
    width: '100%'
  }
}))
