import React from 'react'
import { Typography, Box, IconButton, Grid } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { useFormContext } from 'react-hook-form'
import { PAGE_STEPS } from '../form'

const ReviewFormData = ({ goToPage }) => {
  const { getValues } = useFormContext()

  const getInfo = getValues()

  return (
    <div>
      <Box mt={2.5} display='flex' justifyContent='space-between' sx={{ width: 600, backgroundColor: '#F6F8FB' }}>
        <Grid display='flex'>
          <Typography color='#465270' mt={1} mb={1} ml={2} fontSize='14px'>Organisation name</Typography>
          <Typography color='##000000' mt={1} mb={1} ml={2} fontSize='14px' fontWeight='bold'>{getInfo.name}</Typography>
        </Grid>
        <IconButton onClick={() => goToPage(PAGE_STEPS.basic_info)}>
          <Edit />
        </IconButton>
      </Box>
      <Box mt={2} display='flex' sx={{ width: 600, backgroundColor: '#F6F8FB', height: 52 }}>
        <Typography color='#465270' mt={2} ml={2} fontSize='14px'>ID</Typography>
        <Typography color='##000000' mt={2} ml={2} fontSize='14px' fontWeight='bold'>{getInfo.name.replace(/ /g, '_').toLowerCase()}</Typography>
      </Box>
      <Box mt={2} display='flex' sx={{ width: 600, backgroundColor: '#F6F8FB' }}>
        <Typography color='#465270' mt={1} mb={1} ml={2} fontSize='14px'>Admin</Typography>
        <Box sx={{ flexDirection: 'column' }}>
          {getInfo.orgAdminEmail.map(email => (
            <Typography key={email.adminEmail} color='##000000' mt={1} mb={1} ml={2} fontSize='14px' fontWeight='bold'>{email.adminEmail}</Typography>
          ))}
        </Box>
        <Grid container direction='row-reverse'>
          <IconButton onClick={() => goToPage(PAGE_STEPS.basic_info)}>
            <Edit />
          </IconButton>
        </Grid>
      </Box>
      <Typography mt={2.5} fontWeight='bold' fontSize={16}>
        Licenses
      </Typography>
      <div>
        {getInfo.licenses.map((license) => (
          <Box mt={1} key={license.sk} sx={{ width: 600, backgroundColor: '#F6F8FB', height: 132 }}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Typography color='#25282B' ml={1} fontSize='16px' fontWeight='bold'>{license.name}</Typography>
              <IconButton onClick={() => goToPage(PAGE_STEPS.plan_selection)}>
                <Edit />
              </IconButton>
            </Grid>
            <Box mt={-1.5}>
              <ul>
                {license.seats === '1' ? <li> {license.seats} Seat</li> : <li>{license.seats} Seats</li>}
                <li>Start date: {license.startDate.format('DD-MM-YYYY')}</li>
                <li>End Date: {license.endDate.format('DD-MM-YYYY')}</li>
              </ul>
            </Box>
          </Box>
        ))}
      </div>
    </div>
  )
}

export default ReviewFormData
