// import { useSelector } from 'react-redux'
import { MenuItem, Select } from '@mui/material'
import moment from 'moment'
import { useStyles } from './styles'
// import { userSlice } from '../../../reducers'

const TrackerAnswer = ({ values, day, updateAnswer, questionAnswer }) => {
  // const user = useSelector(userSlice.selectors.selectUser)
  const classes = useStyles()
  return (
    <Select small='true' variant='standard'
      defaultValue={null}
      value={values[moment(day).format('YYYY-MM-DD')]?.answer ?? ''}
      disabled={values[moment(day).format('YYYY-MM-DD')]?.hasAnswered || moment().isSameOrBefore(day, 'day')}
      onChange={e => updateAnswer(e.target.value, undefined, day)}
      className={questionAnswer?.needsUpdate ? classes.needsUpdateTextField : classes.defaultTextField }
    >
      <MenuItem value={1}>1</MenuItem>
      <MenuItem value={2}>2</MenuItem>
      <MenuItem value={3}>3</MenuItem>
      <MenuItem value={4}>4</MenuItem>
      <MenuItem value={5}>5</MenuItem>
      <MenuItem value={6}>6</MenuItem>
    </Select>
  )
}

export default TrackerAnswer
