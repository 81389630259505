import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    position: 'relative'
  },
  bottomCircularProgress: {
    position: 'absolute',
    color: '#CACCCF'
  },
  progressTypo: {
    position: 'absolute',
    left: '50%',
    top: '47%',
    transform: 'translate(-50%, -50%)',
    fontSize: handleFontSize,
    lineHeight: handleFontSize
  }
}))

const handleFontSize = ({ size }) => {
  if (size === 'small') return '12px'
  if (size === 'medium') return '24px'
  return '36px'
}
