import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {

  },
  header: {
    padding: '0 8px',
    marginBottom: 10,
    color: '#465270',
    fontWeight: 500,
    fontSize: 14
  },
  row: {
    padding: 16,
    background: '#F6F8FB',
    marginBottom: 10,
    color: '#000000',
    fontSize: 14
  }
}))
