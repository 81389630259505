import React, { createContext, useContext, useEffect } from 'react'
import { useConnectionContext, useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { indicatorSlice, settingsSlice, surveySlice, trackerSlice, userOnboardingSlice, userSlice } from '../reducers'
import { setCookies } from '../utils/setCookie'

export const EventContext = createContext(null)

export const useEventContext = () => {
  return useContext(EventContext)
}

export const EventContextProvider = ({ children }) => {
  const { connection, isConnected } = useConnectionContext()
  const [getUser] = useCorvusEventList([userSlice.eventBus.getUser])
  const [getUserOnboarding] = useCorvusEventList([userOnboardingSlice.eventBus.getUserOnboarding])
  const [getUserNotes] = useCorvusEventList([userSlice.eventBus.getUserNotes])
  const [getUserSettings] = useCorvusEventList([settingsSlice.eventBus.getUserSettings])
  const [getUserProfilePic] = useCorvusEventList([userSlice.eventBus.getUserProfilePic])
  const [getTrackerSteps] = useCorvusEventList([trackerSlice.eventBus.getTrackerSteps])
  const [getUserIndicatorAnswers] = useCorvusEventList([indicatorSlice.eventBus.getUserAnswers])
  const [getUserResilienceAnswers] = useCorvusEventList([surveySlice.eventBus.getUserAnswers])
  const [getUserToolpathway] = useCorvusEventList([userSlice.eventBus.getUserToolpathway])
  const [getUserQuestionnairePathway] = useCorvusEventList([userSlice.eventBus.getUserQuestionnairePathway])
  const [getMostPopular] = useCorvusEventList([userSlice.eventBus.getMostPopular])
  const [updateUserSettings] = useCorvusEventList([settingsSlice.eventBus.updateUserSettings])
  const [handleWPAuthorization] = useCorvusEventList([{ eventName: 'WP Authorisation Was Requested', onSuccess: setCookies }])

  useEffect(() => {
    if (connection?.session?.isAuthenticated && isConnected) {
      getUser.trigger()
      getUserNotes.trigger()
      getUserSettings.trigger()
      getUserProfilePic.trigger()
      getTrackerSteps.trigger()
      getUserIndicatorAnswers.trigger()
      getUserResilienceAnswers.trigger()
      getUserToolpathway.trigger()
      getUserQuestionnairePathway.trigger()
      getMostPopular.trigger()
      getUserOnboarding.trigger()
      handleWPAuthorization.trigger()
    }
  }, [
    connection?.session?.isAuthenticated,
    isConnected,
    getUser,
    getUserNotes,
    getUserSettings,
    getUserProfilePic,
    getTrackerSteps,
    getUserIndicatorAnswers,
    getUserResilienceAnswers,
    getUserToolpathway,
    getUserQuestionnairePathway,
    getMostPopular,
    getUserOnboarding,
    handleWPAuthorization
  ])

  return (
    <EventContext.Provider value={{
      getUser,
      getUserNotes,
      getUserSettings,
      getUserProfilePic,
      getTrackerSteps,
      getUserToolpathway,
      getUserQuestionnairePathway,
      getUserOnboarding,
      updateUserSettings
    }}
    >
      {children}
    </EventContext.Provider>
  )
}
