import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  header: {
    margin: theme.spacing(2, 0, 1, 1)
  }
}))
