import { Box } from '@mui/system'
import { Button, Modal, Typography } from '@mui/material'
import { useStyles } from './style'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
  p: 3
}

const AlreadyInvitedUserModal = ({ open, result, email, cohort, confirm, cancel }) => {
  const classes = useStyles()
  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography variant='h5' className={classes.title}>
          {result?.reason}
        </Typography>
        <div className={classes.message}>
        <Typography color='text.primary'>
            The user <b >{email}</b> was invited to <b>{result?.cohort}</b> cohort.
          </Typography>
          <Typography color='text.primary'>
            Would you like to invite <b >{email}</b> user to <b>{cohort}</b> cohort?
          </Typography>
        </div>
        <div className={classes.buttons}>
          <Button id='confirm' onClick={confirm} variant='contained'>
            Confirm
          </Button>
          <Button id='cancel' onClick={cancel} variant='contained'>
            Cancel
          </Button>
        </div>
        
      </Box>
    </Modal>
  )
}
export default AlreadyInvitedUserModal
