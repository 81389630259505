import React, { useContext } from 'react'
import { Typography } from '@mui/material'
import { ReButton } from '../../../components'
import { useStyles } from './style'
import { InvitationContext } from '..'

const StepZero = () => {
  const classes = useStyles()
  const context = useContext(InvitationContext)
  const onNextClick = () => {
    context.setStep(1)
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Welcome to the Resilience Dynamic Dashboard®</Typography>
      <p>Resilience is your capacity for change.</p>

      <p>Understand and support your resilience and wellbeing using your very own dashboard. You will feel better, and perform better as a result.</p>

      <p>The dashboard has a range of features, offering a way to see your own resilience, understand how to make shifts in it, and ultimately optimise your resilience for whatever context you operate within; work or personal life.</p>
      <img src='https://static.resilienceengine.live/img/additional-user-settings-step-one-dynamic.png' alt='Additional User Settings Step One' />
      <p>
        <ReButton dataTestId='Welcome-page-button-next' action={onNextClick} className={classes.formButton} name='Create your profile' />
      </p>
    </div>
  )
}
export { StepZero }
