import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  title: {
      color: theme.palette.secondary.main
  },  
  message: {
      padding: '20px',
      '& b': {
        color: theme.palette.primary.main
      }
  },
  buttons: {
    float: 'right',
    '& #cancel': {
        backgroundColor: theme.palette.secondary.main,
        margin: '5px',
    }
  }
}))
