import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    width: '100%'
  },
  image: {
    paddingTop: theme.spacing(6)
  },
  text: {
    padding: `${theme.spacing(3)} 250px`,
    fontSize: '16px',
    color: '#25282B'
  },
  title: {
    fontWeight: 'bold'
  },
  trackLaterLink: {
    cursor: 'pointer'
  }
}))
