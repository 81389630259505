import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  titleText: {
    fontSize: 12
  },
  label: {
    textTransform: 'capitalize',
    color: '#A0A4A8',
    fontSize: 13,
    fontWeight: 'bold'
  },
  paperColor: {
    minWidth: '20px',
    marginRight: theme.spacing(0.5),
    borderRadius: '5px'
  },
  legend: {
    margin: `${theme.spacing(2)} 0`
  },
  clickable: {
    cursor: 'pointer'
  },
  selected: {
    cursor: 'pointer',
    // color: theme.palette.primary.main,
    borderBottom: `3px solid ${theme.palette.primary.main}`
  }
}))
