import { useSelector } from 'react-redux'
import { userSlice } from './reducers'
import { PrivateRoute } from '@emerald-works/react-auth'
// import { Navigate, useParams } from 'react-router-dom'
import { Forbidden } from './pages'

const OrgRoute = (props) => {
  // const { tenantKey } = useParams()
  const selectedView = useSelector(userSlice.selectors.selectedView)
  if (selectedView?.type === 'org') return <PrivateRoute {...props} />
  else { return <Forbidden /> }
  // return <Navigate to={`/${tenantKey}/dashboard`} />
}

export default OrgRoute
