import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  header: {
    margin: theme.spacing(2, 0, 1, 1)
  },
  yellowCircle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#FFC107'
  },
  greenCircle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#4CAF50'
  },
  avatar: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}))
