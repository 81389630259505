import React from 'react'
import { Typography } from '@mui/material'
import QuestionScore from './score'
import QuestionVariability from './variability'
import { useSelector } from 'react-redux'
import { surveySlice, userSlice, indicatorSlice } from '../../reducers'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { useStyles } from './style'

const SurveyQuestion = ({ question, answer }) => {
  const { root, textStyle } = useStyles()

  const [saveAnswerBurst] = useCorvusEventList([surveySlice.eventBus.saveAnswer])
  const [saveIndicatorAnswerBurst] = useCorvusEventList([indicatorSlice.eventBus.saveAnswer])
  const user = useSelector(userSlice.selectors.selectUser)

  const initialScore = answer !== undefined ? [answer.range.low, answer.range.high] : null
  const initialVariability = answer !== undefined ? answer.variability : null

  const [sValue, setScoreValue] = React.useState(initialScore)
  const [vValue, setVariabilityValue] = React.useState(initialVariability)

  const handleSave = ({ scoreValue, variabilityValue }) => {
    if (scoreValue) setScoreValue(scoreValue)
    if (variabilityValue) setVariabilityValue(variabilityValue)

    const rangeValue = scoreValue || sValue
    const varValue = variabilityValue || vValue

    if (rangeValue && varValue) {
      const answer = {
        question: question,
        cycle: `${(user.currentCycle || 1)}`,
        range: {
          low: rangeValue[0],
          high: rangeValue[1]
        },
        variability: varValue
      }

      question.type === 'indicator'
        ? saveIndicatorAnswerBurst.trigger({ answer, ssoId: user.ssoId, questionnaireType: 'indicator' })
        : saveAnswerBurst.trigger({ answer, ssoId: user.ssoId, questionnaireType: 'resilience' })
    }
  }

  return (
    <div className={root}>
      <Typography className={textStyle} variant='body2'>{question.id}. {question.text}*</Typography>
      <QuestionScore
        handleSend={handleSave}
        initialValue={initialScore}
        reverse={question.reverse || false}
        type={question.type}
      />
      <QuestionVariability handleSend={handleSave} initialValue={initialVariability} reverse={question.reverse || false} />
    </div>
  )
}

export default SurveyQuestion
