import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    width: '100%'
  },
  header: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: '48px'
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: '16px'
  },
  content: {
    fontSize: '14px',
    fontWeight: 400,
    margin: '16px 0'
  },
  backButton: {
    textTransform: 'none',
    fontWeight: 700,
    fontSize: 14
  },
  breadcrumb: {
    color: '#A0A4A8',
    fontWeight: 700,
    fontSize: 14,
    cursor: 'pointer',
    textDecoration: 'none'
  },
  tipImage: {
    background: 'transparent',
  }
}))
