import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    width: '100%',
    backgroundColor: '#FFFFFF'
  },
  paper: {
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    borderRadius: '8px',
    padding: `0 ${theme.spacing(3)}`
  },
  tableHeader: {
    backgroundColor: '#FFFFFF'
  },
  header: {
    backgroundColor: 'rgba(232, 232, 232, 1)'
  },
  add: {
    float: 'right',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  textField: {
    border: '1px solid #E8E8E8',
    borderRadius: 8,
    fontSize: '12px',
    minWidth: '250px',
    color: '#000000'
    // marginTop: theme.spacing(2)
  },
  inputClient: {
    minWidth: '250px'
  }
}))
