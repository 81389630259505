import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '140px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '360px',
    textAlign: 'center'
  },
  logo: {
    position: 'absolute',
    width: '200px',
    top: '45px'
  },
  button: {
    marginTop: '30px',
    color: theme.palette.button.color
  },
  field: {
    margin: '10px 0'
  }
}))
