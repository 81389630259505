import { Button, Paper, Snackbar, Tab, Tabs, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { ArrowLeft } from '@mui/icons-material'
import React, { createContext, useCallback, useEffect, useMemo, useRef } from 'react'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import StepOne from './step-one'
import StepTwo from './step-two'
import { useStyles } from './style'
import useNavigation from '../../../helpers/useNavigation'
import { useSelector } from 'react-redux'
import { userSlice } from '../../../reducers'
import { useLocation, useParams } from 'react-router-dom'
import Forbidden from '../../403'

const memberListSize = 20

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={0} ref={ref} variant='filled' {...props} />
})

export const ViewTeamContext = createContext({})
const ViewTeam = () => {
  const classes = useStyles()
  const registerAdhocOnce = useRef(false)
  const navigate = useNavigation()
  const location = useLocation()
  const params = useParams()
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const [step, setStep] = React.useState(1)
  const [teamName, setTeamName] = React.useState('')
  const [teamId, setTeamId] = React.useState('')
  const [members, setMembers] = React.useState([])
  const [result, setResult] = React.useState({ open: false, error: false, message: '' })
  const [keyList, setKeyList] = React.useState([null])
  const previousKeyList = React.useMemo(() => keyList[keyList.length - 2], [keyList])

  const handleClose = () => setResult(r => ({ ...r, message: '', open: false }))

  const [fetchTeamInfo] = useCorvusEventList([{
    eventName: 'Team Info Was Requested',
    onSuccess: (res) => {
      setTeamName(res.info.name)
      setTeamId(res.info.teamId)
    }
  }])
  const [fetchTeamUsers] = useCorvusEventList([{
    eventName: 'Team Users Were Requested', onSuccess: res => {
      if (!res.users?.length) return
      const { teamInfo: { pk, sk } } = res.users[res.users.length - 1]
      setMembers(res.users)
      setKeyList(kl => [...kl, { pk, sk }])
    }
  }])

  const [addEmailToTeam] = useCorvusEventList([{ eventName: 'Team Pending Email Was Added' }])
  const [removeFromTeam] = useCorvusEventList([{ eventName: 'Team User Was Removed' }])

  const genericLoading = useMemo(() =>
    fetchTeamInfo.isWorking || fetchTeamUsers.isWorking || addEmailToTeam.isWorking || removeFromTeam.isWorking
    , [addEmailToTeam.isWorking, fetchTeamInfo.isWorking, fetchTeamUsers.isWorking, removeFromTeam.isWorking])

  useEffect(() => {
    if (!selectedView.id || !teamId || registerAdhocOnce.current) return
    registerAdhocOnce.current = true
    addEmailToTeam.registerAdhocOnSuccessListener(() => {
      fetchTeamUsers.trigger({ tenantKey: selectedView.id, teamId, size: memberListSize, lastKey: null })
      setKeyList([null])
      setResult({ open: true, error: false, message: 'User added successfully' })
    })
    removeFromTeam.registerAdhocOnSuccessListener(() => {
      fetchTeamUsers.trigger({ tenantKey: selectedView.id, teamId, size: memberListSize, lastKey: null })
      setKeyList([null])
      setResult({ open: true, error: false, message: 'User removed successfully' })
    })
  }, [addEmailToTeam, fetchTeamUsers, removeFromTeam, selectedView.id, teamId])

  const fetchNext = useCallback(() => {
    fetchTeamUsers.trigger({ tenantKey: selectedView.id, teamId, size: memberListSize, lastKey: keyList[keyList.length - 1] })
  }, [fetchTeamUsers, keyList, selectedView.id, teamId])

  const fetchPrevious = useCallback(() => {
    setKeyList(KL => {
      const newKL = KL.slice(0, KL.length - 2)
      fetchTeamUsers.trigger({ tenantKey: selectedView.id, teamId, size: memberListSize, lastKey: newKL[newKL.length - 1] })
      return newKL
    })
  }, [fetchTeamUsers, selectedView.id, teamId])

  const addToTeam = useCallback((email) => {
    addEmailToTeam.trigger({ tenantKey: selectedView.id, teamId, email })
  }, [addEmailToTeam, selectedView.id, teamId])

  const renderStep = useMemo(() => {
    switch (step) {
      case 0:
        return <StepOne />
      case 1:
        return <StepTwo />
      default:
        return <Typography>Unknown page</Typography>
    }
  }, [step])

  useEffect(() => {
    if (selectedView?.id && teamId && !members.length) {
      fetchNext()
    }
  }, [fetchNext, selectedView, teamId, members])

  useEffect(() => {
    if (!fetchTeamInfo) return
    if (location.state?.teamId) { // coming from create
      setTeamName(location.state.name)
      setTeamId(location.state.teamId)
    } else { // coming from team list
      fetchTeamInfo.trigger({ tenantKey: selectedView.id, teamId: params.teamId })
    }
  }, [location.state, fetchTeamInfo, params.teamId, selectedView.id])

  const isIndividualView = (selectedView && selectedView.type === 'individual') || !selectedView

  return (
    isIndividualView ? <Forbidden />
      : <div>
        <Paper className={classes.container}>
          <div>
            <Button color='secondary' size='small' startIcon={<ArrowLeft />} onClick={() => navigate('/dashboard/teams')}>Back to list</Button>
            <Typography variant='h4' className={classes.header}>{teamName}</Typography>
          </div>
          <Tabs indicatorColor='secondary' textColor='secondary' value={step} onChange={(_e, v) => setStep(v)}>
            <Tab label='Basic Information' />
            <Tab label='Members' />
          </Tabs>
          <ViewTeamContext.Provider value={{
            teamName,
            teamId,
            setTeamName,
            members,
            setMembers,
            fetchNext,
            fetchPrevious,
            keyList,
            previousKeyList,
            removeFromTeam,
            addToTeam,
            genericLoading
          }}>
            {renderStep}
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={result.open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={result.error ? 'error' : 'success'} sx={{ width: '100%' }}>
                {result.error ? 'Something went wrong' : result.message}
              </Alert>
            </Snackbar>
          </ViewTeamContext.Provider>
        </Paper>
      </div>
  )
}

export default ViewTeam
