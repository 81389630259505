import { Box, Button, TextField } from '@mui/material'
import React, { useContext } from 'react'
import { useStyles } from './style'
import { NewTeamContext } from '..'
import useNavigation from '../../../../helpers/useNavigation'
import { useSelector } from 'react-redux'
import { userSlice } from '../../../../reducers'
import { ReButton } from '../../../../components'

export default function StepOne () {
  const navigate = useNavigation()
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const classes = useStyles()
  const { teamName, setTeamName, createTeam } = useContext(NewTeamContext)

  const handleCreateTeam = (name) => {
    createTeam.trigger({ name, tenantKey: selectedView.id })
  }

  return (
    <div className={classes.root}>
      <TextField label='Group name' value={teamName} onChange={e => setTeamName(e.target.value)} />
      <Box display='flex' justifyContent='space-between' width={250} gap={2} marginTop={3}>
        <ReButton
          name='Save'
          action={() => handleCreateTeam(teamName)}
          fullWidth
          variant='contained'
          color='secondary'
          disabled={!teamName.length}
          loading={createTeam.isWorking}
        />
        <Button disabled={createTeam.isWorking} onClick={() => navigate('/dashboard/teams')} fullWidth variant='outlined' color='secondary'>Cancel</Button>
      </Box>
    </div>
  )
}
