import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

// Store
export const tracker = createSlice({
  name: reducerName,
  initialState: {
    showOrgProfile: false,
    tenantData: {
      totalUsers: 0,
      trackerAnswers: [],
      evaluationReportResult: false
    }
  },
  reducers: {
    setTenantData: (state, { payload }) => {
      state.tenantData = payload
    },
    setShowOrgProfile: (state, { payload }) => {
      state.showOrgProfile = payload
    }
  }
})

export const {
  setTenantData,
  setShowOrgProfile
} = tracker.actions

export default tracker.reducer
