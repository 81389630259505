import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    filter: 'drop-shadow(0px 12px 26px rgba(16, 30, 115, 0.06))',
    height: theme.spacing(54),
    width: theme.spacing(48),
    cursor: 'pointer'
  },
  cover: {
    borderRadius: theme.spacing(1),
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: theme.spacing(33),
    padding: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: theme.spacing(21)
  },
  titleWrapper: {
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    textTransform: 'capitalize',
    color: '#A0A4A8',
    fontWeight: 700,
    fontSize: 12
  },
  buttonIcon: {
    color: '#A0A4A8'
  },
  text: {
    fontWeight: 700,
    fontSize: 12
  }
}))
