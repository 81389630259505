import { CircularProgress, Grid, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useStyles } from './style'

const CICOProgress = ({ progress, size, loading }) => {
  const _size = useMemo(() => {
    if (size === 'small') return 37
    if (size === 'medium') return 74
    return 100
  }, [size])

  const classes = useStyles({ size })
  if (loading) return <CircularProgress size={_size} variant='indeterminate' />
  return (
    <Grid item className={classes.circularProgressWrapper}>
      <CircularProgress size={_size} variant='determinate' className={classes.bottomCircularProgress} value={100} />
      <CircularProgress size={_size} variant='determinate' value={progress} />
      <Typography className={classes.progressTypo}>{Math.trunc(progress)}%</Typography>
    </Grid>
  )
}

export default CICOProgress
