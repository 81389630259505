import { useCallback, useEffect, useState } from 'react'
import { userSlice } from '../../reducers'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import useFeatures from '../../hooks/useFeatures'
import OneSignal from 'react-onesignal';
import { useSelector } from 'react-redux';

async function runOneSignal({ onSubscriptionChange, checkSubscription }) {
  await OneSignal.init({
    appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
    safari_web_id: process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID
  });
  OneSignal.on('subscriptionChange', onSubscriptionChange)
  checkSubscription()
  OneSignal.showSlidedownPrompt()
}
const OneSignalComponent = ({ children }) => {
  const features = useFeatures()
  const user = useSelector(userSlice.selectors.selectUser)
  const [saveUserDeviceBurst] = useCorvusEventList([userSlice.eventBus.saveUserDevice])
  const [isOneSignalInitialized, setIsOneSignalInitialized] = useState(false)

  const onSubscriptionChange = useCallback(async (isSubscribed) => {
    console.log('onSignal isSubscribed', isSubscribed)
    if (isSubscribed) {
      const userId = await OneSignal.getUserId()
      const registerParams = { oneSignalUserId: userId }
      saveUserDeviceBurst.trigger(registerParams)
    }
  }, [saveUserDeviceBurst])

  const checkSubscription = useCallback(async () => {
    const userId = await OneSignal.getUserId()
    console.log('checkSubscription userId', userId)
    if (userId) {
      const alreadyAdded = user?.oneSignalUserIds?.find(id => id === userId)
      if (!alreadyAdded) {
        const registerParams = { oneSignalUserId: userId }
        saveUserDeviceBurst.trigger(registerParams)
      }
    }
  }, [user, saveUserDeviceBurst])

  useEffect(() => {
    if (isOneSignalInitialized) return
    if (features.RESILIENCE_TRACKER && user) {
      setIsOneSignalInitialized(true)
      runOneSignal({ onSubscriptionChange, checkSubscription })
    }
  }, [isOneSignalInitialized, features.RESILIENCE_TRACKER, onSubscriptionChange, checkSubscription, user])

  return children
}

export default OneSignalComponent
