import React from 'react'
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import moment from 'moment'

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    width: '100%'
  },
  section: {
    marginBottom: '20px',
    padding: 2,
    alignItems: 'center'
  },
  image: {
    objectFit: 'cover'
  },
  notesText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '12px',
    paddingBottom: 5,
    margin: '0px 20px'
  }
})

const NotesPdf = ({ data, user }) => {
  const notes = data.length > 0
    ? data.filter(answer => answer.question.name === 'note')
      .map(answer => {
        return { text: answer.note, date: moment(answer.createdAt).format('DD/MM/YYYY HH:mm') }
      })
    : []

  return (
    <Document>
      <Page size='A4' wrap style={styles.page}>
        <View fixed>
          <Image
            src='/img/pdf-top-v2.png'
            alt='Resilience Engine Logo'
          />
        </View>
        <View style={styles.section}>
          <Text>{user.name} Notes</Text>
        </View>
        {!!notes.length && notes.map((note, idx) => (
          <View key={idx} style={styles.notesText}>
            <Text style={{ width: '70%' }} > {note.text} </Text>
            <Text style={{ width: '30%' }} > {note.date} </Text>
          </View>
        ))}
      </Page>
    </Document>
  )
}

export default NotesPdf
