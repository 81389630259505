import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ palette: { secondary: { dark } }, ...theme }) => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(6, 4),
    maxWidth: '800px',
    background: 'white',
    borderRadius: '20px'
  },
  text: {
    padding: `${theme.spacing(0)} ${theme.spacing(0)}  ${theme.spacing(2)}  ${theme.spacing(0)}`,
    fontSize: '24px',
    fontWeight: 'normal',
    color: dark
  },
  title: {
    fontWeight: '600',
    color: dark,
    paddingBottom: `${theme.spacing(2)}`,
    paddingTop: `${theme.spacing(2)}`,
    textAlign: 'left'
  },
  modal: {
    background: 'white',
    position: 'relative',
    overflow: 'visible'
  },
  arrow: {
    width: '200px',
    height: '200px',
    background: 'red'
  },
  laterButton: {
    textTransform: 'none',
    fontWeight: 'bold'
  }
}
))
