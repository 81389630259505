import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  modalRoot: {
    padding: theme.spacing(3)
  },
  modalTop: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  },
  modalContent: {
    height: '330px'
  },
  disabled: {
    '& .MuiSlider-thumb': {
      width: '38px',
      height: '38px'
    }
  },
  sliderRail1: {
    height: '12px',
    background: 'linear-gradient(270.01deg, #C3C1E7 2.99%, #E5D4BA 100%)',
    borderRadius: '71px'
  },
  sliderRail2: {
    height: '12px',
    background: 'linear-gradient(269.75deg, #C3C1E7 3.19%, #DDCFC4 100%)',
    borderRadius: '71px'
  },
  sliderRail3: {
    height: '12px',
    background: 'linear-gradient(269.75deg, #C3C1E7 3.19%, #D7CCCD 100%)',
    borderRadius: '71px'
  },
  sliderRail4: {
    height: '12px',
    background: 'linear-gradient(269.75deg, #C3C1E7 3.19%, #D1C9D5 100%)',
    borderRadius: '71px'
  },
  sliderRail5: {
    height: '12px',
    background: 'linear-gradient(269.75deg, #C3C1E7 3.19%, #CAC5DE 100%)',
    borderRadius: '71px'
  },
  sliderRail6: {
    height: '12px',
    borderRadius: '71px'
  },
  sliderTrack1: {
    height: '12px',
    background: 'linear-gradient(269.75deg, #C4824B 3.19%, #E9961A 97.36%)',
    borderRadius: '71px'
  },
  sliderTrack2: {
    height: '12px',
    background: 'linear-gradient(269.75deg, #C4824B 3.19%, #E9961A 97.36%)',
    borderRadius: '71px'
  },
  sliderTrack3: {
    height: '12px',
    background: 'linear-gradient(269.95deg, #A47075 3.03%, #E9961A 99.95%)',
    borderRadius: '71px'
  },
  sliderTrack4: {
    height: '12px',
    background: 'linear-gradient(270.01deg, #845DA0 2.99%, #E9961A 100%)',
    borderRadius: '71px'
  },
  sliderTrack5: {
    height: '12px',
    background: 'linear-gradient(270.01deg, #644CC9 2.99%, #E9961A 100%)',
    borderRadius: '71px'
  },
  sliderTrack6: {
    height: '12px',
    background: 'linear-gradient(270.01deg, #453AF1 2.99%, #E9961A 100%)',
    borderRadius: '71px'
  },
  sliderThumb1: {
    color: '#E9961A',
    width: '38px',
    height: '38px'
  },
  sliderThumb2: {
    color: '#C4824B',
    width: '38px',
    height: '38px'
  },
  sliderThumb3: {
    color: '#A47075',
    width: '38px',
    height: '38px'
  },
  sliderThumb4: {
    color: '#845DA0',
    width: '38px',
    height: '38px'
  },
  sliderThumb5: {
    color: '#644CC9',
    width: '38px',
    height: '38px'
  },
  sliderThumb6: {
    color: '#453AF1',
    width: '38px',
    height: '38px'
  },
  sliderMark: {
    width: '3px',
    height: '9px',
    color: '#CACCCF',
    backgroundColor: '#CACCCF',
    top: '48px'
  },
  sliderMarkLabel: {
    top: '60px',
    fontSize: '12px',
    fontWeight: 700,
    color: '#707585'
  },
  sliderMarkLabelActive: {
    top: '60px',
    fontSize: '16px',
    fontWeight: 700,
    color: '#707585'
  }
}))
