import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

export const userOnboarding = createSlice({
  name: reducerName,
  initialState: {
    onBoardingStatus: [],
    currentOnBoardingStep: 0,
    isOnBoarding: false,
    isOnFeature: false,
  },
  reducers: {
    setOnBoardingStatus: (state, action) => {
      state.onBoardingStatus = action.payload.map(({ sk }) => sk)
    },
    updateOnBoardingStatus: (state, action) => {
      const { sk } = action.payload
      const newOnBoardingStatus = [ ...state.onBoardingStatus ]
      newOnBoardingStatus.push(sk)
      state.onBoardingStatus = [...new Set(newOnBoardingStatus)]
    },
    setCurrentOnBoardingStep: (state, action) => {
      state.currentOnBoardingStep = action.payload
    },
    setIsOnBoarding: (state, action) => {
      state.isOnBoarding = action.payload
    },
    setIsOnFeature: (state, action) => {
      state.isOnFeature = action.payload
    }
  }
})

export const {
  setOnBoardingStatus,
  updateOnBoardingStatus,
  setCurrentOnBoardingStep,
  setIsOnBoarding,
  setIsOnFeature
} = userOnboarding.actions

// Reducer
export default userOnboarding.reducer
