import name from './name'

const selectSliceData = state => state[name]

const selectResponses = state => selectSliceData(state).responses
const selectSteps = state => selectSliceData(state).steps
const selectResult = state => selectSliceData(state).result

export {
  selectResponses,
  selectSteps,
  selectResult
}
