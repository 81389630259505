import * as actions from './slice'
import * as userAction from '../user/slice'

export const saveTrackerAnswer = (cb) => ({
  eventName: 'saveTrackerAnswer',
  onSuccess: [
    { redux: true, action: userAction.addUserTrackerAnswer },
    { redux: true, action: userAction.addDashboardDataTrackerAnswers },
    { redux: false, action: (info) => cb(info) }
  ]
})

export const trackerSaveBatchAnswer = (cb) => ({
  eventName: 'trackerSaveBatchAnswer',
  onSuccess: [
    { redux: true, action: userAction.addDashboardDataTrackerAnswers },
    { redux: true, action: userAction.setlastUpdateDay },
    { redux: false, action: (info) => cb(info) }
  ]
})

export const saveUserRead = {
  eventName: 'saveUserRead',
  onSuccess: [{ redux: true, action: userAction.setUserRead }]
}

export const getTrackerSteps = {
  eventName: 'getTrackerSteps',
  onSuccess: [{ redux: true, action: actions.setTrackerSteps }]
}

export const getShowTracker = {
  eventName: 'getShowTracker',
  onSuccess: {
    action: actions.setShowTracker,
    redux: true
  }
}
