import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: '64px'
  },
  smallDivider: {
    display: 'block',
    height: 0,
    width: '92px',
    borderBottom: '3px solid #CACCCF',
    margin: '10px 0 50px'
  },
  results: {
    width: '70%'
  },
  resultHeader: {
    fontSize: '20px',
    fontWeight: 700,
    color: theme.palette.primary.dark,
    marginBottom: '16px'
  },
  resultLevel: {
    fontSize: '25px',
    textTransform: 'uppercase',
    color: '#52575c',
    fontWeight: 400
  },
  resultText: {
    fontSize: 16,
    fontWeight: 400,
    margin: '16px 0'
  },
  resultTextBold: {
    fontSize: '16px',
    fontWeight: 700,
    margin: '32px 0'
  },
  reportsBox: {
    display: 'flex',
    padding: '16px'
  },
  reportsBtn: {
    color: theme.palette.secondary.dark,
    border: '1px solid #465270',
    textTransform: 'none',
    fill: theme.palette.secondary.dark,
    marginRight: '28px'
  },
  resultInsights: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#52575c',
    marginBottom: '16px'
  }
}))
