import React from 'react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import LoadingOverlay from 'react-loading-overlay'
import { useStyles } from './style'
import { ReSwitchButtonV2 } from '../../components'
import { useSelector } from 'react-redux'
import { userSlice } from '../../reducers'

// api mapping
const baseOptions = [{ label: 'Weekly', value: 3 }, { label: 'Off', value: 1 }]
const additionalOptions = [{ label: 'Daily', value: 2 }, baseOptions[0], baseOptions[1]]
const mandatoryOrOptional = [{ label: 'Mandatory', value: true }, { label: 'Optional', value: false }]

const trackerOptions = [
  { label: 'Demand / Capacity / Energy', value: 'main', hasAdditional: true },
  { label: 'Sleep', value: 'sleep', hasAdditional: true },
  { label: 'Stress', value: 'stress', hasAdditional: true },
  { label: 'Wellbeing', value: 'wellbeing' },
  { label: 'Contentedness', value: 'contentedness' }
]

const TrackerConfiguration = ({ values, isMandatoryValues, handleChanges, handleMandatoryChanges, isWorking, dataTestPrefix = '' }) => {
  const classes = useStyles()
  const theme = useTheme()
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const isIndividualView = (selectedView && selectedView.type === 'individual') || !selectedView
  return (
    <LoadingOverlay
      active={isWorking}
      spinner
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(50, 50, 50, 0.15)'
        }),
        spinner: (base) => ({
          ...base,
          '& svg circle': {
            stroke: theme.palette.secondary.main
          }
        }),
        content: (base) => ({
          ...base,
          color: theme.palette.secondary.main
        })
      }}
      fadeSpeed={300}
      text='Updating...'
    >
      <div className={classes.selectorWrapper}>
        <div className={classes.selectorItem}>
          <Typography>Questions</Typography>
          {isIndividualView ? <Typography>Frequency</Typography> : <Typography>Frequency and Obligatoriness</Typography>}
        </div>
        {trackerOptions.map(i => (
          <TrackerButton
            key={i.value}
            values={values}
            isMandatoryValues={isMandatoryValues}
            dataTestPrefix={dataTestPrefix}
            handleChanges={handleChanges}
            handleMandatoryChanges={handleMandatoryChanges}
            isIndividualView={isIndividualView}
            buttonConfig={i}
          />
        ))}
      </div>
    </LoadingOverlay>
  )
}

const TrackerButton = ({ values, isMandatoryValues, dataTestPrefix, handleChanges, handleMandatoryChanges, isIndividualView, buttonConfig }) => {
  const classes = useStyles()
  return (
    <div className={classes.selectorItem}>
      <Typography>{buttonConfig.label}</Typography>
      <div className={classes.selectorItemRight}>
        <ReSwitchButtonV2
          dataTestPrefix={`${dataTestPrefix}-${buttonConfig.value}`}
          disabledOptions={isMandatoryValues[buttonConfig.value] ? [1] : []}
          state={values[buttonConfig.value]}
          itemWidth='72px'
          onClick={({ value }) => handleChanges(buttonConfig.value, value)}
          options={buttonConfig.hasAdditional ? additionalOptions : baseOptions}
          height='35px'
          bgColor='#2F80ED'
        />
        {!isIndividualView &&
          <ReSwitchButtonV2
            state={isMandatoryValues[buttonConfig.value]}
            itemWidth='80px'
            onClick={({ value }) => handleMandatoryChanges(buttonConfig.value, value)}
            options={mandatoryOrOptional}
            height='35px'
            bgColor='#2F80ED'
          />}
      </div>
    </div>
  )
}

export default TrackerConfiguration
