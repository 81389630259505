import React, { useCallback, useContext } from 'react'
import { Box, List, ListItem, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useStyles } from './style'
import { ReButton } from '../../../../components'
import { cohortSlice, userSlice } from '../../../../reducers'
import { NewCohortContext } from '..'
import useNavigation from '../../../../helpers/useNavigation'

export default function StepFour() {
  const classes = useStyles()
  const navigate = useNavigation()
  const { getValues } = useFormContext()
  const { cohortWasFinished, cohortStatusWasChanged } = useContext(NewCohortContext)
  const features = useSelector(cohortSlice.selectors.selectFeatureList)
  const form = getValues()
  const { id: tenantKey } = useSelector(userSlice.selectors.selectedView)

  const canFinish = useCallback(() => {
    const { invites, entries } = getValues()
    if (invites.length < 1) return false
    if (!entries.length) return false
    return true
  }, [getValues])

  const finish = async () => {
    await Promise.all([
      cohortWasFinished.promise({ cohortId: getValues('cohortId'), message: getValues('message'), tenantKey }),
      cohortStatusWasChanged.promise({ cohortId: getValues('cohortId'), tenantKey })
    ])
    navigate('/dashboard/cohorts')
  }
  const finishLater = () => {
    navigate('/dashboard/cohorts')
  }

  return (
    <div className={classes.root}>
      <div className={classes.inviteListWrapper}>
        <Typography className={classes.title} variant='h5'>Cohort users invited</Typography>
        {form.invites.length !== 0 &&
          <List className={classes.inviteList}>
            {form.invites.map((invite, index) => (
              <ListItem className={classes.inviteListItem} key={index}>{invite.sk}</ListItem>
            ))}
          </List>}
        {form.invites.length < 1 && <Typography className={classes.missingWarning} variant='body1'>You must invite at least one user</Typography>}
      </div>
      <div>
        <Typography className={classes.title} variant='h5'>Cohort features</Typography>
        {
          features.length !== 0 &&
          (
            <div>
              {features.filter(feat => form.entries.some(entry => feat.id === entry.group)).map((feature, index) => (
                <div key={index}>
                  <Typography key={index} variant='h6' color='secondary'>{feature.title}</Typography>
                  <ul>
                    {form.entries.filter(entry => entry.group === feature.id).map((entry, index) => (
                      <li key={index}>Entry: {new Date(entry.start_time).toDateString()} - {new Date(entry.end_time).toDateString()}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )
        }
        {form.entries.length === 0 && <Typography className={classes.missingWarning} variant='body1'>No features added</Typography>}
      </div>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <ReButton
          dataTestId='new-cohort-review-button-finish'
          className={classes.nextButton}
          name='Finish and send invites'
          disabled={!canFinish()}
          action={finish}
          loading={cohortWasFinished.event.isWorking || cohortStatusWasChanged.event.isWorking}
        />
        <ReButton
          dataTestId='new-cohort-review-button-finish'
          className={classes.nextButton}
          name='Finish Later'
          action={finishLater}
        />
      </Box>
    </div>
  )
}
