import { Divider, IconButton, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { ReButton } from '../../components'
import { getWeeklyDates } from '../../utils/getWeeklyDates'
import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { useSelector } from 'react-redux'
import { trackerSlice, userSlice } from '../../reducers'
import LoadingOverlay from 'react-loading-overlay'
import TrackerRow from './tracker-row'
import { getNextWeekDay } from '../../utils/getNextWeekDay'

LoadingOverlay.propTypes = undefined

const updateAnswers = (current, incomingTrackerAnswers) => {
  const newAnswers = { ...current }
  incomingTrackerAnswers.forEach(item => {
    const { question, createdAt, answer, note } = item
    if (!newAnswers[question.name]) return
    newAnswers[question.name][moment(createdAt).format('YYYY-MM-DD')] = { date: moment(createdAt), question, answer, note, hasAnswered: true }
  })
  return newAnswers
}

const RetroactiveTracker = () => {
  const user = useSelector(userSlice.selectors.selectUser)
  const [startDate, setStartDate] = useState(null)
  const [answers, setAnswers] = useState({})
  const changeWeek = (v) => setStartDate(s => s.clone().add(v, 'week').startOf('week').add(1, 'day'))
  const weeklyDates = useMemo(() => getWeeklyDates(startDate), [startDate])

  const [getTrackerAnswersOfTheWeek] = useCorvusEventList([{
    eventName: 'getTrackerAnswersOfTheWeek',
    onSuccess: res => {
      if (!res?.trackerAnswers) return
      const { trackerAnswers } = res
      setAnswers(current => {
        return updateAnswers(current, trackerAnswers)
      })
    }
  }])
  const [trackerSaveBatchAnswer] = useCorvusEventList([trackerSlice.eventBus.trackerSaveBatchAnswer((res) => {
    if (!res?.trackerAnswers.length) return
    const { trackerAnswers } = res
    setAnswers(current => {
      return updateAnswers(current, trackerAnswers)
    })
  })])

  const loading = useMemo(() => getTrackerAnswersOfTheWeek.isWorking || trackerSaveBatchAnswer.isWorking, [getTrackerAnswersOfTheWeek.isWorking, trackerSaveBatchAnswer.isWorking])

  const saveEntries = useCallback(() => {
    const toSave = Object.entries(answers)
      .map(([, v]) => v) // unwrap questions
      .map(i => Object.entries(i).map(([, v]) => v)) // unwrap dates
      .flat() // flatten
      .filter(i => i.needsUpdate) // filter out non-updated entries
    trackerSaveBatchAnswer.trigger({ batch: toSave })
  }, [answers, trackerSaveBatchAnswer])

  const trackerSteps = useSelector(trackerSlice.selectors.selectTrackerSteps)
  const questions = useMemo(() => {
    return trackerSteps.reduce((acc, step) => [...acc, ...step.questions], [])
  }, [trackerSteps])

  useEffect(() => {
    if (startDate !== null || !user?.lastUpdateDay) return
    const nextDay = getNextWeekDay(user?.lastUpdateDay ?? new Date())
    setStartDate(moment(nextDay).startOf('week').add(1, 'day'))
  }, [startDate, user.lastUpdateDay])

  useEffect(() => {
    if (startDate === null) return
    getTrackerAnswersOfTheWeek.trigger({ start: startDate, end: startDate.clone().add(5, 'days') })
  }, [getTrackerAnswersOfTheWeek, startDate])

  useEffect(() => {
    const result = questions.reduce((acc, q) => {
      acc[q.name] = weeklyDates.reduce((acc, day) => ({ ...acc, [day.format('YYYY-MM-DD')]: { date: day, question: q, answer: '', note: '', hasAnswered: false } }), {})
      return acc
    }, {})
    setAnswers(result)
  }, [weeklyDates, questions])

  if (!startDate) return <Typography>Loading...</Typography>

  return (
    <Box maxWidth='1200px'>
      <Paper>
        <Box p={2}>
          <Box mb={2}><Typography variant='h5'>Resilience Tracker</Typography></Box>
          <Box>
            <Box display='flex'>
              <Box display='flex' width='270px' alignItems='center'>
                <Typography>Days untracked</Typography>
              </Box>
              <Box display='flex' alignItems='center' width='30px'><IconButton onClick={() => changeWeek(-1)} disabled={loading}><ArrowLeft /></IconButton></Box>
              <Box display='flex' flex={2} justifyContent='space-between'>
                {weeklyDates.map((day, idx) => (
                  <Box key={idx} marginY={2} width='100px' display='flex' alignItems='center' justifyContent='center'>
                    <Typography
                      color={moment().isSameOrBefore(day, 'day') || moment(user.createdAt).isAfter(day, 'day') ? 'action' : 'primary'}
                      variant='subtitle2'
                      fontWeight='normal'
                    >
                      {day.format('DD MMM YYYY')}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box display='flex' alignItems='center' width='30px'><IconButton onClick={() => changeWeek(1)} disabled={loading}><ArrowRight /></IconButton></Box>
            </Box>
            <Divider />
            <LoadingOverlay active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: 'rgba(50, 50, 50, 0.15)'
                })
              }}>
              <Box>
                {questions.map((q, idx) => (<TrackerRow key={idx} rowQuestion={q} days={weeklyDates} values={answers[q.name] ?? []} setAnswers={setAnswers} answers={answers} />))}
              </Box>
            </LoadingOverlay>
          </Box>
          <Box display='flex' justifyContent='flex-end' mt={2}>
            <ReButton name='Save entries' action={saveEntries} loading={loading} />
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default RetroactiveTracker