import { ButtonBase, IconButton, Input, Typography } from '@mui/material'
import { useStyles } from './style'
import { Delete, Image } from '@mui/icons-material'

const AvatarUploader = ({ localPicture, setLocalPicture, handleFileUpload, fullWidth }) => {
  const classes = useStyles({ fullWidth })
  return (
    <>
      <Typography variant='body1'>Profile Picture / Avatar</Typography>
      {
        !localPicture &&
        <label style={{ width: 'fit-content' }} htmlFor='avatar-file-upload'>
          <Input onChange={handleFileUpload} inputProps={{ accept: 'image/*', 'data-test': 'Welcome-settings-button-upload-file' }} id='avatar-file-upload' type='file' style={{ display: 'none' }} />
          <ButtonBase className={classes.uploadWrapper} component='span'>
            <div className={classes.uploadDescription}>
              <Image />
              <Typography variant='body1'>Drag your image here to upload or</Typography>
            </div>
            <div className={classes.uploadButton}>Upload File</div>
          </ButtonBase>
        </label>
      }
      {
        localPicture !== null &&
        <div className={classes.uploadPictureWrapper}>
          <div>
            <img src={localPicture.value} className={classes.uploadPicture} alt='profile' />
            <Typography>{localPicture.fileName}</Typography>
          </div>
          <IconButton onClick={() => setLocalPicture(null)}><Delete /></IconButton>
        </div>
      }
    </>)
}
export default AvatarUploader
