import { useEffect } from 'react'
import { useSession } from '@emerald-works/react-auth'
import { userSlice } from '../reducers'
import { useDispatch } from 'react-redux'
import ability, { updateAbility } from '../config/ability'
import { AbilityContext } from '../components/can'

const AbilityContextProvider = ({ children }) => {
  const { session } = useSession()
  const dispatcher = useDispatch()

  useEffect(() => {
    if (session) {
      const userRole = { role: session?.idToken?.payload['cognito:groups']?.toString() }
      dispatcher(userSlice.actions.setUserRole(userRole))
      updateAbility(ability, userRole)
    }
  }, [session, dispatcher])
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}

export default AbilityContextProvider
