import { useParams, useNavigate } from 'react-router-dom'

// Callback function when burst is available or change
export default () => {
  const { tenantKey } = useParams()
  const navigate = useNavigate()

  return (path, options) => {
    navigate(`/${tenantKey}/${path}`, options)
  }
}
