import makeStyles from '@mui/styles/makeStyles'
export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  drawerContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  },
  inviteHeader: {
    padding: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(3)
  },
  stepsHeader: {
    margin: theme.spacing(2, 0, 4, 0)
  },
  profileWrapper: {
    backgroundColor: '#F6F8FB',
    height: '55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    padding: theme.spacing(0, 2)
  },
  profileImageWrapper: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#D7D7D7',
    border: '1px dashed #52575C',
    marginRight: theme.spacing(1.5),
    overflow: 'hidden',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center'
  },
  profileImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '550px',
    gap: '10px'
  },
  uploadWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '350px',
    height: '118px',
    borderRadius: '4px',
    backgroundColor: '#f6f8fb',
    border: '1px dashed #dbdde0',
    gap: '15px',
    marginTop: theme.spacing(2)
  },
  uploadDescription: {
    display: 'flex',
    gap: '10px'
  },
  uploadButton: {
    padding: '5px 20px',
    border: '2px solid #465270',
    borderRadius: '4px'
  },
  formButton: {
    marginTop: theme.spacing(2),
    width: '150px'
  }
}))
