import { Typography, Paper, Popover, Grid, Tooltip } from '@mui/material'
import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useStyles } from './style.js'

const DashboardAverage = ({ type, title, value, description }) => {
  const classes = useStyles()
  const [popoverAnchor, setPopoverAnchor] = React.useState()

  const open = Boolean(popoverAnchor)
  const anchorId = open ? 'average-popover' : undefined

  const closePopover = () => {
    setPopoverAnchor(null)
  }

  if (!title || !value) {
    return null
  }
  return (
    <Paper elevation={0} className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.header}>
          <Typography className={classes.headerTypo} variant='subtitle2'>{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          {value !== undefined && (
            <div className={classes.content}>
              {type === 'score' && (
                <div className={classes.relativeBox}>
                  <Typography variant='h5' className={classes.text}>
                    {value} <span className={classes.subText}>out of 6</span>
                  </Typography>
                  {description !== '' && (
                    <Tooltip title={description}>
                      <InfoOutlinedIcon className={classes.popoverIcon} />
                    </Tooltip>
                  )}
                </div>
              )}
              {type === 'notes' && (
                <div>
                  <Typography className={classes.noteText} variant='h5'>{value}</Typography>
                  <Typography className={classes.moreText} variant='h5'>Read more</Typography>
                </div>
              )}
              {type !== 'notes' && type !== 'score' && (
                <div className={classes.relativeBox}>
                  <Typography className={classes.text} variant='h5'>{value}</Typography>
                  {description !== '' && (
                    <Tooltip title={description}>
                      <InfoOutlinedIcon className={classes.popoverIcon} />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
      <Popover
        id={anchorId}
        open={open}
        anchorEl={popoverAnchor}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Typography
          className={classes.popover}
          onMouseLeave={closePopover}
        >
          {description}
        </Typography>
      </Popover>

    </Paper>
  )
}

export default DashboardAverage
