import { CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import React, { useContext } from 'react'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { useStyles } from './style'
import { EditCohortContext } from '..'
import { Controller, useFormContext } from 'react-hook-form'
import { cohortSlice, userSlice } from '../../../../reducers'

export default function StepOne () {
  const classes = useStyles()
  const [isEditing, setIsEditing] = React.useState(false)
  const { cohortInfoWasRequested, cohortNameWasUpdated, licenseListWasRequested } = useContext(EditCohortContext)
  const { control, setValue, getValues, formState: { errors } } = useFormContext()
  const licenseList = useSelector(cohortSlice.selectors.selectLicenseList)
  const { id: tenantKey } = useSelector(userSlice.selectors.selectedView)

  const getSelectedLicenseDates = () => {
    const licenseId = getValues('licenseId')
    if (!licenseId.length) { return null }
    const license = licenseList.find(l => l.sk === licenseId)
    return <Typography variant='caption'>Starts: {moment(license.startDate).format('DD/MM/YYYY')} - Ends: {moment(license.endDate).format('DD/MM/YYYY')}</Typography>
  }

  const handleEditCohortNameButton = async () => {
    if (isEditing) {
      if (getValues('originalCohortName') !== getValues('cohortName')) {
        try {
          await cohortNameWasUpdated.promise({ cohortId: getValues('cohortId'), newName: getValues('cohortName'), tenantKey })
          setValue('originalCohortName', getValues('cohortName'))
        } catch (err) {
          alert(err.message)
          setValue('cohortName', getValues('originalCohortName'))
        }
      }
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }

  if (cohortInfoWasRequested.event.isWorking) {
    return <Typography>Loading...</Typography>
  }

  return (
    <div className={classes.root}>
      <Typography variant='caption' className={classes.description}>Name the cohort and select a license</Typography>
      <Controller
        name='cohortName'
        control={control}
        rules={{ required: true }}
        render={({ field }) =>
          <TextField
            inputProps={{ 'data-test': 'new-cohort-basic-input-name' }}
            InputProps={{
              endAdornment: (
                <IconButton
                  disabled={cohortNameWasUpdated.event.isWorking}
                  onClick={handleEditCohortNameButton}
                  className={classes.fieldBtn} aria-label='edit' size='small'
                >
                  {!isEditing ? <EditIcon fontSize='small' /> : <SaveIcon fontSize='small' />}
                </IconButton>
              )
            }}
            disabled={!isEditing || cohortNameWasUpdated.event.isWorking}
            error={!!errors.cohortName}
            {...field}
            className={classes.input}
            variant='outlined'
            label='Cohort name'
          />}
      />
      <Controller
        name='licenseId'
        control={control}
        rules={{ required: true }}
        render={({ field }) =>
          <FormControl>
            <InputLabel>Select a license</InputLabel>
            <Select
              inputProps={{ 'data-test': 'new-cohort-basic-select-license' }}
              error={!!errors.licenseId} disabled={licenseListWasRequested.isWorking || !!getValues('cohortId')}
              variant='outlined' label='Select a license'
              {...field}
            >
              <MenuItem value=''>None</MenuItem>
              {
                licenseList.map((license) => (
                  <MenuItem key={license.sk} value={license.sk}>
                    {license.name} - {license.seats} seats
                  </MenuItem>
                ))
              }
            </Select>
            {licenseListWasRequested.isWorking && <CircularProgress className={classes.licenseLoader} />}
            {!licenseListWasRequested.isWorking && !cohortInfoWasRequested.event.isWorking && getSelectedLicenseDates()}
          </FormControl>}
      />
    </div>
  )
}
