import React from 'react'
import { Typography } from '@mui/material'
import { useStyles } from './style'

const LowScoreMessage = () => {
  const classes = useStyles()
  return (
    <div className={classes.content}>
      <div className={classes.empty}>
        <Typography className={classes.lowScoreText}>
          Your resilience is at risk right now, and we are really aware that you need support to understand what you can do to feel better.
          We don't send out or offer downloading of the Resilience Dynamic®Questionnaire reports when resilience levels are low. We have found that the best experience is to work through the report together with your coach. This will make the insights practical for you, rather than overwhelming.
          Right now, we recommend that you stop, rest, and seek care and support from those that love you - your family and friends.
          Your coach will help you increase your resilience with some simple and practical steps. Until then, allow yourself to stop and rest.
          If you have any queries with this approach, please do not hesitate to get in touch with us. We are at your service.
          Email <a href='mailto:support@resilienceengine.com'> support@resilienceengine.com</a>.
        </Typography>
      </div>
    </div>

  )
}

export default LowScoreMessage
