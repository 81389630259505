import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useStyles } from './style'

const ChartLegend = ({ chartOptions, setChartOptions }) => {
  const classes = useStyles()

  const setActive = (label) => {
    if (chartOptions.length > 0) {
      const newData = chartOptions.map(data => {
        let isSelected = !!data.isSelected
        if (data.name === label) {
          isSelected = !data.isSelected
        }
        return { ...data, isSelected }
      })
      setChartOptions(newData)
    }
  }

  return (
    <Grid container className={classes.legend}>
      {chartOptions.map((label, key) => (
        <Grid item key={key} marginRight={2}>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            className={classes.clickable}
            onClick={() => setActive(label.name)}
          >
            <Grid item>
              <div className={classes.paperColor} style={{ border: `3px solid ${label.color}` }} />
            </Grid>
            <Grid item className={label.isSelected ? classes.selected : ''}>
              <Typography className={classes.label}> {label.name} </Typography>
            </Grid>
          </Grid>
        </Grid>
      )
      )}
    </Grid>

  )
}

export default ChartLegend
