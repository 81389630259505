import React, { useState } from 'react'
import { Grid, Dialog, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, Input, CircularProgress, TextField } from '@mui/material'
import AdapterMoment from '@mui/lab/AdapterMoment'
import { userSlice, adminSlice } from '../../reducers'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { useSelector } from 'react-redux'
import DatePicker from '@mui/lab/DatePicker'
import { useStyles } from './style'
import moment from 'moment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

const CycleModal = ({ open, handleClose }) => {
  const classes = useStyles()
  const [client, setClient] = useState('')
  const [date, setDate] = useState(moment())
  const user = useSelector(userSlice.selectors.selectUser)
  const [saveCycleBurst] = useCorvusEventList([
    adminSlice.eventBus.saveCycle
  ])

  const handleChange = (event) => {
    setClient(event.target.value || '')
  }

  const handleSave = () => {
    if (setDate && client && user?.ssoId) {
      saveCycleBurst.trigger({ ssoId: user?.ssoId, client, date })
      handleClose()
    }
  }

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
    >
      <DialogContent>
        <form className={classes.container}>
          <Grid
            container
            spacing={3}
            justifyContent='space-between'
          >
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label='Start date'
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor='client-cycle-id'> Client </InputLabel>
                <Select
                  native
                  value={client}
                  onChange={handleChange}
                  variant='outlined'
                  className={classes.inputClient}
                  input={<Input id='client-cycle-id' />}
                >
                  <option aria-label='None' value='' />
                  <option value='devsquad'>Devsquad</option>
                  <option value='google'>Google</option>
                  <option value='re-test'>RE Test</option>
                  <option value='re-live'>RE Live</option>
                </Select>
              </FormControl>
            </Grid>

          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} color='secondary'>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSave} color='primary' disabled={!client}>
          {saveCycleBurst.isWorking ? <CircularProgress size={20} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CycleModal
