import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    borderRadius: '10px',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    display: 'flex'
  },
  imageContainer: {
    textAlign: 'center'
  },
  image: {
    width: '100%'
  }
}))
