import { Skeleton } from "@mui/material"
import { Box } from "@mui/system"

const GenericLoading = ({ overrideHeight }) => {
  return (
    <Box paddingY={2} ><Skeleton variant='rectangular' height={overrideHeight ?? '200px'} /></Box>
  )
  
}

export default GenericLoading