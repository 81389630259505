import React from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { useStyles } from './style'

const Loading = ({ message }) => {
  const classes = useStyles()
  return (
    <div className={classes.content}>
      <CircularProgress size={80} />
      {message && (<Typography color='primary'> {message} </Typography>)}
    </div>

  )
}

export default Loading
