import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  uploadWrapper: props => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: props.fullWidth ? '100%' : '350px',
    height: '128px',
    borderRadius: '4px',
    backgroundColor: '#f6f8fb',
    border: '1px dashed #dbdde0',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  }),
  uploadDescription: {
    display: 'flex',
    gap: '10px'
  },
  uploadButton: {
    padding: '5px 20px',
    border: '2px solid #465270',
    borderRadius: '4px'
  },
  uploadPictureWrapper: props => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: props.fullWidth ? '100%' : '600px',
    borderRadius: '4px',
    backgroundColor: '#f6f8fb',
    border: '1px dashed #dbdde0',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3)
  }),
  uploadPictureContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  uploadPicture: {
    width: '45px',
    height: '45px',
    objectFit: 'cover',
    borderRadius: '5px'
  }
}))
