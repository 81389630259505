import ToolPathwayCard from '../tool-pathway-card'
import YourTrackCard from '../your-track-card'
import { Grid, Paper } from '@mui/material'
import { useStyles } from './style'

const PathwayCard = () => {
  const classes = useStyles()
  return (
    <Paper elevation={2} className={classes.container}>
      <Grid container>
        <Grid item xs={6}>
          <YourTrackCard />
        </Grid>
        <Grid item xs={6}>
          <ToolPathwayCard />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PathwayCard
