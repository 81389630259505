import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from './style'

const ToolPathwayItemBigger = ({ item, selectContent, itemStatusForUser, bottomTrail }) => {
  const classes = useStyles()

  const title = item.title.rendered
  const description = item.excerpt.rendered
  // const category = item.categories && item.categories.length ? item.categories[0].name : ''
  const { acf, duration, jetpack_featured_media_url } = item

  return (
    <Grid onClick={selectContent} container justifyContent='flex-start' className={classes.container} style={!bottomTrail ? { paddingBottom: 0 } : {}}>
      {bottomTrail && <div className={classes.bottomTrail} />}
      <Grid
        item
        className={classes.imageContainer}
        style={{ backgroundImage: `url(${itemStatusForUser === 'read' ? '/icons/pathway-done.svg' : jetpack_featured_media_url})` }}
      />
      <Grid container item alignItems='center' xs>
        <Grid container item direction='column' alignItems='flex-start' justifyContent='center'>
          <Grid container alignItems='flex-end' className={classes.titleContainer}>
            <Typography variant='subtitle1' className={classes.title} dangerouslySetInnerHTML={{ __html: title }} />
          </Grid>
          {/* <Typography variant='body2'>{category}</Typography> */}
          <Grid container className={classes.additionalInfo}>
            {acf && <Typography variant='caption' className={classes.typeTypo}>{acf?.type || 'Article'}</Typography>}
            {acf && duration && <Typography variant='caption'> | </Typography>}
            {duration && <Typography variant='caption'>Estimated time • {duration} min</Typography>}
          </Grid>
          {description &&
            <Grid container className={classes.descriptionWrapper}>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </Grid>}
          {/* <Grid className={classes.iconsContainer}>
            <Grid container justifyContent='center' alignItems='center' wrap='nowrap'>
              <ButtonBase onClick={e => e.stopPropagation()} className={classes.iconButton}>
                <img src='/icons/unread.svg' alt='read icon' />
                <Typography variant='body2'>Unread</Typography>
              </ButtonBase>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' wrap='nowrap'>
              <ButtonBase onClick={e => e.stopPropagation()} className={classes.iconButton}>
                <img src='/icons/bookmark.svg' alt='bookmark icon' />
                <Typography variant='body2'>Bookmark</Typography>
              </ButtonBase>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ToolPathwayItemBigger
