import React, { useMemo, useState } from 'react'
import { InformationalCard } from '../../components'
import { Skeleton } from '@mui/material'
import { useStyles } from './style'
import { clientConfigSlice, settingsSlice, userSlice } from '../../reducers'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { useDispatch, useSelector } from 'react-redux'
import { ProfileInfoField } from './profile-info-field'
import { AvatarUploader } from '..'
import { sendS3File } from '../../helpers/utils'
import { usePromiseEvent } from '../../hooks'

const ProfileInfoCard = ({ currentPersonalSettings, setCurrentPersonalSettings, data, user, accState }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = data === null
  const clientConfig = useSelector(clientConfigSlice.selectors.selectClientConfig)
  const personalFields = useMemo(() => clientConfig.demographicFields.filter(f => f.fieldCategory === 'personal'), [clientConfig])
  const [sendPersonalSettings, getUserProfilePic] = useCorvusEventList([userSlice.eventBus.saveUserPersonalSettings, userSlice.eventBus.getUserProfilePic])
  const [updateUserSettings] = useCorvusEventList([settingsSlice.eventBus.updateUserSettings])

  const [localPicture, setLocalPicture] = useState(user?.profilePic?.url ? { value: user?.profilePic?.url } : null)
  const requestPreSignedUrl = usePromiseEvent('Profile Picture PreSigned Url Upload Was Requested')

  const handleSave = () => sendPersonalSettings.trigger(currentPersonalSettings)
  const handleChange = (e, shouldSend) => {
    const { name, value } = e.target
    const newUserSettings = { ...currentPersonalSettings }
    newUserSettings[name] = value
    setCurrentPersonalSettings(newUserSettings)
    if (shouldSend) sendPersonalSettings.trigger(newUserSettings)
  }

  const handleSaveName = () => updateUserSettings.trigger({ type: 'info', name: 'name', value: user.name })
  const handleChangeName = (e) => {
    dispatch(userSlice.actions.setUserName(e.target.value))
  }
  const handleFileUpload = (e) => {
    if (!e.target || !e.target.files || !e.target.files.length) return
    const file = e.target.files[0]
    const fileReader = new FileReader()
    e.target.value = ''
    fileReader.readAsDataURL(file)
    fileReader.onload = async (e) => {
      setLocalPicture({
        value: e.target.result,
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        fileContents: file
      })
      const result = await requestPreSignedUrl.promise({ type: file.type })
      await sendS3File(result.url, file, file.type)
      getUserProfilePic.trigger()
    }
  }

  return (
    <div className={classes.root}>
      <InformationalCard title='Profile information' accState={accState}>
        {isLoading
          ? (
            <span>
              <Skeleton style={{ marginBottom: '-20px', marginTop: '-10px' }} variant='text' animation='wave' height='80px' width='100%' />
              <Skeleton style={{ marginBottom: '-20px' }} variant='text' animation='wave' height='80px' width='100%' />
              <Skeleton style={{ marginBottom: '-20px' }} variant='text' animation='wave' height='80px' width='100%' />
            </span>
          )
          : (
            <div className={classes.cardsContainer}>
              <div>
                <AvatarUploader localPicture={localPicture} setLocalPicture={setLocalPicture} handleFileUpload={handleFileUpload} fullWidth />
              </div>
              <ProfileInfoField
                field={{ fieldName: 'Full name', fieldKey: 'name', fieldType: 'input' }}
                value={user.name}
                canEdit
                handleChange={handleChangeName}
                handleSave={handleSaveName}
              />
              <ProfileInfoField
                field={{ fieldName: 'Email*', fieldKey: 'email', fieldType: 'input' }}
                value={data.email}
              />
              <ProfileInfoField
                field={{ fieldName: 'Active since', fieldKey: 'activeSince', fieldType: 'input' }}
                value={data.activeSince.split('T')[0]}
              />
              {personalFields.map((field) => (
                <ProfileInfoField
                  field={field}
                  value={currentPersonalSettings[field.fieldKey]}
                  canEdit
                  handleChange={handleChange}
                  handleSave={handleSave}
                />
              ))}
            </div>
          )}
      </InformationalCard>
    </div>
  )
}

export default ProfileInfoCard
