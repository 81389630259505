
import { createTheme, responsiveFontSizes } from '@mui/material'
import { createPalette } from './palette'
import { createTypography } from './typography'

const createMuiTheme = (options = {}) => {
  const {
    palette: paletteInput = {},
    typography: typographyInput = {},
    ...others
  } = options

  const palette = createPalette(paletteInput)
  const typography = createTypography(typographyInput)

  const theme = createTheme({
    palette,
    typography,
    ...others
  })

  return responsiveFontSizes(theme)
}

export default createMuiTheme
