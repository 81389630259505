import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '90vh'
  },
  footer: {
    margin: '24px 78px'
  }
}))
