import React, { useEffect, useState } from 'react'
import { Fade, Grid, Typography, IconButton, Dialog } from '@mui/material'
import { useStyles } from './style'
import { ReButton } from '../..'
import PopperWrapper from '../popper-wrapper'
import { Close } from '@mui/icons-material'

export default function SelfAssessmentReport({ next, previous, cancelOnBoarding, isOnFeature }) {
  const classes = useStyles()
  const [ref, setRef] = useState(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setRef(document.querySelector('.onboarding-my-reports-and-notes'))
      setLoaded(true)
    }, 0)
  }, [])

  const Wrapper = ({ children }) => isOnFeature
    ? <Dialog open classes={{ paperWidthSm: classes.modal }} spacing={3}>{children}</Dialog>
    : <PopperWrapper element={ref} placement='right'>{children}</PopperWrapper>

  return (
    <Wrapper>
      <Fade in={loaded} timeout={400}>
        <Grid
          container
          justifyContent='center'
          direction='column'
          flexWrap='nowrap'
          className={classes.root}
        >
          <Grid style={{ position: 'absolute', top: 20, right: 20 }}>
            <IconButton onClick={cancelOnBoarding} color='primary'><Close style={{ fontSize: '30px' }} /></IconButton>
          </Grid>
          <Grid item xs={12}>
            <img
              style={{ minHeight: '400px' }}
              src='https://static.resilienceengine.live/img/onboarding-self-assessment-report-v2.svg'
              alt='Additional User Settings Congratulations'
            />
            <Typography variant='h3' className={classes.title}>
              Your Self-Assessment Report
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom={3}>
            <Typography component='p' variant='body3'>
              After completing the Resilience Dynamic® Indicator or Questionnaire, review your detailed report to get a better understanding of your resilience level. You'll also find some recommended resilience-boosting tools in your report.
            </Typography>
            <br />
            <Typography component='p' variant='body3'>
              You can download and view your report at any time from My Reports and Notes.
            </Typography>
          </Grid>
          {isOnFeature
            ? <Grid item display='flex' justifyContent='center' gap={5}><ReButton fullWidth name='Understood' dataTestId='close_congratulations_modal' action={cancelOnBoarding} /></Grid>
            : <Grid item display='flex' justifyContent='flex-end' gap={5}>
              <ReButton fullWidth name='Previous' dataTestId='close_congratulations_modal' action={previous} />
              <ReButton fullWidth name='Next' dataTestId='close_congratulations_modal' action={next} />
            </Grid>
          }
        </Grid>
      </Fade>
    </Wrapper>
  )
}
