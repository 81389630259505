import name from './name'

const selectSliceData = state => state[name]

const selectTenantTrackerData = state => selectSliceData(state).tenantTrackerData
const selectShowOrgProfile = state => selectSliceData(state).showOrgProfile
const selectTenantEvaluationData = state => selectSliceData(state).tenantResilienceData

export {
  selectTenantTrackerData,
  selectShowOrgProfile,
  selectTenantEvaluationData
}
