import React, { useEffect, useState, useCallback } from 'react'
import MultiLineChart from './muti-line-chart'
import ChartLegend from './chart-legend'
import { Grid } from '@mui/material'
import { UsersBadge } from '../../components'
import { useStyles } from './style'
import { resilienceNames, wellbeingNames } from './tracker-chart-utils'

const useClientRect = () => {
  const [rect, setRect] = useState(null)
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect())
    }
  }, [])
  return [rect, ref]
}

const TrackerChart = ({ chartData, users, isOrgProfile, type }) => {
  const classes = useStyles()
  const [data, setData] = useState(chartData)
  const [chartOptions, setChartOptions] = useState(type === 'resilience' ? resilienceNames : wellbeingNames)
  const [rect, graphCard] = useClientRect()

  useEffect(() => {
    if (chartData.length > 0) {
      setData(chartData)
    }
  }, [chartData])

  return (
    <div className={classes.root} ref={graphCard}>
      {rect !== null && (
        <Grid container item direction='row' xs={12}>
          <Grid item xs={12}>
            <ChartLegend chartOptions={chartOptions} setChartOptions={setChartOptions} />
          </Grid>
          {isOrgProfile && (
            <Grid container justifyContent='flex-start'>
              <UsersBadge totalUsers={users} />
            </Grid>
          )}
          <Grid item xs={12}>
            <MultiLineChart data={data} type={type} chartOptions={chartOptions} />
          </Grid>
        </Grid>
      )}
    </div>

  )
}

export default TrackerChart
