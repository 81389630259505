import React from 'react'
import { useStyles } from './style'

const Overlay = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.overlay}>
      {children}
    </div>
  )
}

export default Overlay
