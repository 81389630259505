import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(2, 3, 0, 3)
  },
  text: {
    fontSize: '16px',
    color: '#25282B'
  },
  title: {
    fontWeight: 'bold'
  },
  trackLaterLink: {
    cursor: 'pointer'
  }
}))
