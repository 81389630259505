import * as actions from './slice'

export const orgListWasRequested = {
  eventName: 'Organisations List Was Requested',
  onSuccess: [{ redux: true, action: actions.setClientList }]
}

export const getClientInfo = {
  eventName: 'Client Info Was Requested',
  onSuccess: [{ redux: true, action: actions.setClientInfo }]
}

export const createOrg = {
  eventName: 'Organisation Was Created',
  onSuccess: [{ redux: true, action: actions.addClientToList }]
}
