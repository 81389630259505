import * as actions from './slice'

export const getUserOnboarding = {
  eventName: 'User Onboarding Was Requested',
  onSuccess: [{ redux: true, action: actions.setOnBoardingStatus }]
}

export const saveUserOnboarding = {
  eventName: 'User Onboarding Was Saved',
  onSuccess: [{ redux: true, action: actions.updateOnBoardingStatus }]
}
