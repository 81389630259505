import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    borderRadius: '4px',
    flexDirection: 'row',
    height: '120px',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '12px 0'
  },
  variabilityRoot: {
    flex: 1.25,
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: 'rgba(70, 82, 112, 0.1)',
    minWidth: '600px',
    height: '100%',
    alignItems: 'center'
  },
  textStyle: {
    flex: 0.75,
    backgroundColor: '#F6F8FB',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 20
  },
  radioLabel: {
    flex: 1,
    textAlign: 'center'
  },
  radioRoot: {
    color: '#5EC3CD !important'
  },
  radioRootLabel: {
    fontSize: '12px',
    fontWeight: 400
  }
}))
