import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    maxWidth: '700px'
  },
  title: {
    margin: theme.spacing(2, 0)
  },
  missingWarning: {
    marginLeft: theme.spacing(2)
  },
  nextButton: {
    marginTop: theme.spacing(3)
  }
}))
