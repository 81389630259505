import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  defaultTextField: {
    '& .MuiInputBase-input': {
      borderBottom: `1px solid ${theme.palette.action.main}`,
    }
  },
  needsUpdateTextField: {
    '& .MuiInputBase-input': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    }
  }
}))
