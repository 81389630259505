import * as actions from './slice'

export const getLicenses = {
  eventName: 'License List Was Requested',
  onSuccess: [{ redux: true, action: actions.setLicenses }]
}

export const getFeatures = {
  eventName: 'Plan Feature List Was Requested',
  onSuccess: [{ redux: true, action: actions.setFeatures }]
}
