import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    margin: '8px 0',
    width: '100%',
    border: '3px solid #E8E8E8',
    borderRadius: theme.spacing(1)
  },
  popover: {
    backgroundColor: 'grey',
    padding: '16px',
    color: 'white',
    fontSize: '12px',
    maxWidth: '180px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start'
  },
  headerTypo: {
    textAlign: 'left'
  },
  popoverIcon: {
    margin: '-8px 8px',
    width: 19,
    height: 19,
    color: '#D0D1D2',
    position: 'absolute',
    right: '-18px'
  },
  content: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    textAlign: 'initial'
  },
  text: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main
  },
  subText: {
    textTransform: 'none',
    color: '#465270',
    fontWeight: '400'
  },
  noteText: {
    color: '#465270',
    fontWeight: '400',
    fontSize: '14px',
    position: 'relative',
    maxHeight: '130px',
    overflow: 'hidden',
    paddingRight: '1rem',
    '&:before': {
      position: 'absolute',
      content: '...',
      bottom: 0,
      right: '1rem',
      zIndez: 1
    },
    '&:after': {
      content: '',
      position: 'absolute',
      bottom: 0,
      right: '1rem',
      width: '1rem',
      height: '1rem',
      zIndex: 0
    }
  },
  moreText: {
    color: '#465270',
    fontWeight: '400',
    fontSize: '14px',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: '8px 0'
  },
  relativeBox: {
    position: 'relative',
    width: '100%'
  }
}))
