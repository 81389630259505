import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

export const settings = createSlice({
  name: reducerName,
  initialState: {
    userSettings: undefined
  },
  reducers: {
    setUserSettings: (state, { payload }) => {
      state.userSettings = payload
    }
  }
})

export const {
  setUserSettings
} = settings.actions

// Reducer
export default settings.reducer
