import name from './name'

const selectSliceData = state => state[name]

const selectUserOnboarding = state => selectSliceData(state).onBoardingStatus

const selectCurrentOnBoardingStep = state => selectSliceData(state).currentOnBoardingStep

const selectIsOnBoarding = state => selectSliceData(state).isOnBoarding

const selectIsOnFeature = state => selectSliceData(state).isOnFeature

export { selectUserOnboarding, selectCurrentOnBoardingStep, selectIsOnBoarding, selectIsOnFeature }
