import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { userSlice } from '../reducers'

const useFeatures = () => {
  const user = useSelector(userSlice.selectors.selectUser)
  const features = useMemo(() => user?.features
    ? user.features.reduce((acc, feat) => {
      if (feat.featureId === 'RESILIENCE_TRACKER') {
        return { ...acc, [feat.featureId]: feat }
      }
      return { ...acc, [feat.featureId]: true }
    }, {})
    : {},
  [user.features])

  return features
}

export default useFeatures
