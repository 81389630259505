import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

export const cohort = createSlice({
  name: reducerName,
  initialState: {
    licenseList: [],
    featureList: []
  },
  reducers: {
    setLicenses: (state, { payload }) => {
      state.licenseList = payload
    },
    setFeatures: (state, { payload }) => {
      state.featureList = payload.map((item, idx) => ({ id: idx + 1, title: item.featureName, pk: item.pk }))
    }
  }
})

export const {
  setLicenses,
  setFeatures
} = cohort.actions

// Reducer
export default cohort.reducer
