import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    '& H3': {
      maxWidth: '80%'
    },
    '& p': {
      margin: '12px 0'
    }
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  customList: {
    margin: 0,
    padding: '0 0 0 5px',
    listStyleType: 'none',
    '& li': {
      marginBottom: 10
    }
  },
  scoreLabel: {
    fontSize: 20,
    color: theme.palette.primary.main
  },
  text: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main
  },
  btnContainer: {
    textAlign: 'center'
  },
  cardsContainerVertical: {
    display: 'flex',
    gap: '26px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
}))
