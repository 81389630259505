import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Routes from './routes'
import { OneSignalComponent } from './components'
import RootContextProvider from './contexts/root'
import AbilityContextProvider from './contexts/ability'
import { EventContextProvider } from './contexts/event'
import { UserManagementProvider } from './contexts/userManagement'
import { BackdropContextProvider } from './contexts/backdrop'

const App = () => {
  return (
    <RootContextProvider>
      <AbilityContextProvider>
        <EventContextProvider>
          <UserManagementProvider>
            <BackdropContextProvider>
              <OneSignalComponent>
                <CssBaseline />
                <Routes />
              </OneSignalComponent>
            </BackdropContextProvider>
          </UserManagementProvider>
        </EventContextProvider>
      </AbilityContextProvider>
    </RootContextProvider>
  )
}

export default App
