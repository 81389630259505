import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    color: '#fff'
  },
  image: {
    width: '100%'
  },
  fullHeight: {
    width: '100%'
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  footer: {
    margin: '12px 100px'
  }
}))
