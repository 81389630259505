import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  container: {
    gap: '5%'
  },
  content: {
    width: '650px',
    maxHeight: 'calc(100vh - 88px)',
    overflowY: 'auto',
    padding: '0 25px'
  },
  contentTitle: {
    marginBottom: '18px',
    color: theme.palette.secondary.dark
  },
  contentCategory: {
    marginBottom: '40px'
  },
  toolpathwayPlaylist: {
    minWidth: '400px',
    maxHeight: 'calc(100vh - 88px)',
  },
  toolpathwayPlaylistContent: {
    maxHeight: 'calc(100% - 40px)',
    overflow: 'auto',
    flexWrap: 'nowrap'
  },
  toolpathwayTitle: {
    color: theme.palette.text.greyDark,
    fontWeight: 'bold',
    height: '40px'
  },
  customizeContent: {
    '& img': {
      width: '100%',
      height: 'auto'
    }
  }
}))
