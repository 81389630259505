import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2, 0),
    borderRadius: '10px',
    height: '100%',
    boxShadow: '0px 0px 0px rgba(16, 30, 115, 0.06)'
  },
  content: {
    height: '100%',
    textAlign: 'center'
  },
  informationIcon: {
    cursor: 'pointer',
    margin: '0 1px',
    width: 19,
    height: 19,
    fill: '#465270'
  },
  resilienceLevelTitle: {
    color: theme.palette.secondary.dark
  },
  toolpathwayTitle: {
    color: theme.palette.secondary.dark,
    lineHeight: 1.2,
    marginRight: '3px'
  },
  readMore: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    fontSize: '10px',
    cursor: 'pointer'
  },
  flagContainer: {
    gap: '5px'
  }
}))
