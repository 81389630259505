import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    color: '#fff',
    height: '100vh'
  },
  text: {
    color: '#52575C',
    fontSize: '20px'
  },
  image: {
    height: 160,
    width: 160
  }
}))
