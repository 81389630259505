import React from 'react'
import { Typography } from '@mui/material'
import { useStyles } from './style'

const Empty = ({ message, alt, column }) => {
  const classes = useStyles()
  return (
    <div className={classes.content}>
      <div className={classes.empty} style={{ flexDirection: column ? 'column' : 'row' }}>
        <img src='/img/sleeping-cloud.svg' alt={alt || 'No results for dashboard'} />
        <Typography className={classes.emptyDataText}>{message || 'There is no data to display'}</Typography>
      </div>
    </div >

  )
}

export default Empty
