import React from 'react'
import { Stepper, Step, StepButton } from '@mui/material'

const SurveyTimeline = ({ steps, step, setActiveStep }) => {
  return (
    <Stepper nonLinear activeStep={step} alternativeLabel>
      {steps && steps.length && steps.map(({ slug, position }, index) => (
        <Step key={index}>
          <StepButton completed={index + 1 <= step} icon={index.toString()}>
            {slug}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  )
}

export default SurveyTimeline
