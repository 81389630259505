import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1, 0),
    borderRadius: '10px',
    height: '100%',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)'
  }
}))
