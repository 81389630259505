import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

// Store
export const tracker = createSlice({
  name: reducerName,
  initialState: {
    responses: [],
    steps: [],
    showTracker: false,
    tenantTrackerData: {
      totalUsers: 0,
      data: []
    }
  },
  reducers: {
    setTrackerResponses: (state, { payload }) => {
      state.responses = payload
    },
    setTrackerSteps: (state, { payload }) => {
      state.steps = payload
    },
    updateTrackerSteps: (state, { payload }) => {
      if (payload.newTrackerSteps) {
        state.steps = payload.newTrackerSteps
      }
    },
    setShowTracker: (state, { payload }) => {
      state.showTracker = payload
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setTrackerResponses,
  setTrackerSteps,
  updateTrackerSteps,
  setShowTracker
} = tracker.actions

// Reducer
export default tracker.reducer
