import React from 'react'
import { Radio, FormControlLabel, RadioGroup } from '@mui/material'
import { useStyles } from './style'

const parseReversedValue = (value) => {
  const parsed = 4 - value
  return parsed
}

const QuestionVariability = ({ handleSend, initialValue, reverse }) => {
  const { variabilityRoot, radioLabel, radioRoot, radioRootLabel } = useStyles()
  const [value, setValue] = React.useState(reverse ? parseReversedValue(initialValue) : initialValue)


  const handleChange = (event) => {
    const value = parseInt(event.target.value)
    setValue(value)
    handleSend({ variabilityValue: reverse ? parseReversedValue(value) : value })
  }

  return (
    <div className={variabilityRoot}>
      <RadioGroup name='variability' value={value} onChange={handleChange} row>
        <FormControlLabel
          className={radioLabel}
          classes={{ label: radioRootLabel }}
          value={1}
          label='Variability Low'
          labelPlacement='bottom'
          control={<Radio classes={{ checked: initialValue ? radioRoot : null }} />}
        />
        <FormControlLabel
          className={radioLabel}
          classes={{ label: radioRootLabel }}
          value={2}
          label='Variability Medium'
          labelPlacement='bottom'
          control={<Radio classes={{ checked: initialValue ? radioRoot : null }} />}
        />
        <FormControlLabel
          className={radioLabel}
          classes={{ label: radioRootLabel }}
          value={3}
          label='Variability High'
          labelPlacement='bottom'
          control={<Radio classes={{ checked: initialValue ? radioRoot : null }} />}
        />
      </RadioGroup>
    </div>
  )
}

export default QuestionVariability
