import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  orgButton: {
    textTransform: 'none',
    float: 'right'
  },
  peopleIcon: {
    color: '#CACCCF'
  }
}))
