import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 10,
    padding: 30,
    color: '#FFFFFF'
  },
  text: {
    flexGrow: 1
  },
  box: {
    padding: theme.spacing(0, 3)
  }
}))
