import { Button, Paper, Tab, Tabs, Typography } from '@mui/material'
import { ArrowLeft } from '@mui/icons-material'
import React, { createContext } from 'react'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import StepOne from './step-one'
import { useStyles } from './style'
import useNavigation from '../../../helpers/useNavigation'

export const NewTeamContext = createContext({})
const NewTeam = () => {
  const classes = useStyles()
  const navigate = useNavigation()
  const [teamName, setTeamName] = React.useState('')
  const [createTeam] = useCorvusEventList([{
    eventName: 'Team Creation Was Requested',
    onSuccess: (res) => {
      navigate(`dashboard/teams/${res.teamId}`, { state: { ...res } })
    }
  }])

  return (
    <div>
      <Paper className={classes.container}>
        <div>
          <Button color='secondary' size='small' startIcon={<ArrowLeft />} onClick={() => navigate('/dashboard/teams')}>Back to list</Button>
          <Typography variant='h4' className={classes.header}>
            New Team
          </Typography>
        </div>
        <Tabs indicatorColor='secondary' textColor='secondary' value={0}>
          <Tab label='Basic Information' disabled />
          <Tab label='Members' disabled />
        </Tabs>
        <NewTeamContext.Provider value={{ teamName, setTeamName, createTeam }}>
          <StepOne />
        </NewTeamContext.Provider>
      </Paper>
    </div>
  )
}

export default NewTeam
