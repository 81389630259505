import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  grid: {
    textAlign: 'center',
    overflow: 'auto'
  },
  root: {
    textAlign: 'center',
    padding: theme.spacing(3),
    overflow: 'auto'
  },
  text: {
    padding: theme.spacing(1),
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#52575C'
  },
  title: {
    fontWeight: '400',
    color: '#25282B',
    paddingBottom: `${theme.spacing(4)}`
  },
  rowSpacing: {
    paddingTop: `${theme.spacing(4)}`
  },
  emailTextField: {
    width: '300px',
    padding: '10px'
  }
}))
