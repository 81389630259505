import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'flex-start',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)'
  }
}))
