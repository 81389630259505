import React, { useState } from 'react'
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Menu,
  MenuItem,
  Paper,
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  DialogTitle,
  Button,
  CircularProgress
} from '@mui/material'
import { useStyles } from './styles'
import { CustomOverlay, ReButton, Empty } from '../../components'
import { useNavigate } from 'react-router-dom'
import { Add, ArrowLeft, ArrowRight, MoreVert } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useAlert } from '../../hooks'
import ability from '../../config/ability'
import { Forbidden } from '../index'

const PlansHome = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [plans, setPlans] = useState([])
  const [lastKey, setLastKey] = React.useState(null)
  const [currentPage, setCurrentPage] = React.useState(-1)

  const [planListWasRequested] = useCorvusEventList([{
    eventName: 'Plans List Was Requested',
    onSuccess: (result) => {
      setPlans(current => current.concat([result.plans]))
      setLastKey(result.lastKey)
      setCurrentPage(current => current + 1)
    }
  }])

  useEventsOnViewLoad(() => {
    planListWasRequested.trigger()
  }, [planListWasRequested])

  const handleDeleteSuccess = () => {
    planListWasRequested.trigger({ from: null })
  }

  const getNextPage = () => {
    if (plans.length <= currentPage + 1) {
      planListWasRequested.trigger({ from: lastKey })
    } else {
      setCurrentPage(current => current + 1)
    }
  }
  const getPreviousPage = () => {
    setCurrentPage(current => current - 1)
  }

  const isSuperAdmin = ability.can('view', 'superAdminView')

  return (
    !isSuperAdmin ? <Forbidden />
      : (
        <div className={classes.root}>
          <div className={classes.header}>
            <Typography variant='h3'>Plans</Typography>
            <ReButton name='New Plan' rounded action={() => navigate('new')} dataTestId='new_plan_btn' icon={<Add />} />
          </div>
          <CustomOverlay text='' active={planListWasRequested.isWorking} spinner={false}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size='small' aria-label='plans' className={classes.tableWrapper}>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell>Plan</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPage !== -1 && plans[currentPage].map((plan) => (
                    <TableRow key={plan.pk}>
                      <TableCell scope='row'>{plan.name}</TableCell>
                      <TableCell scope='row'>{plan.description}</TableCell>
                      <MoreOptionsCell row={plan} onDeleteSuccess={handleDeleteSuccess} />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {currentPage !== -1 && plans[currentPage].length === 0 && <Empty message='There are no plans to display' />}
              <Box display='flex' alignItems='center' justifyContent='center'>
                <IconButton disabled={currentPage === 0 || planListWasRequested.isWorking} onClick={getPreviousPage}><ArrowLeft /></IconButton>
                {planListWasRequested.isWorking ? <CircularProgress size={15} color='secondary' /> : <Typography>{currentPage + 1}</Typography>}
                <IconButton disabled={(!lastKey && currentPage + 1 === plans.length) || planListWasRequested.isWorking} onClick={getNextPage}><ArrowRight /></IconButton>
              </Box>
            </TableContainer>
          </CustomOverlay>
        </div>
      )
  )
}

const MoreOptionsCell = ({ row, onDeleteSuccess }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { showSuccessAlert, showErrorAlert } = useAlert()

  const [deletePlan] = useCorvusEventList([{
    eventName: 'Plan Was Deleted',
    onSuccess: () => {
      showSuccessAlert('Sucess!', 'Plan was deleted successfully!')
      onDeleteSuccess()
      handleCloseDeleteModal()
    },
    onError: (error) => {
      showErrorAlert('Error!', 'An unexpected error occurred')
      console.log('Error deleting plan', error)
    }
  }])

  const navigate = useNavigate()
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleEdit = () => {
    navigate(`edit/${row.pk}`)
  }

  const handleDelete = () => {
    deletePlan.trigger({ planId: row.pk })
  }

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    handleClose()
    setDeleteModalOpen(false)
  }

  return (
    <>
      <TableCell>
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleOpenDeleteModal}>Delete</MenuItem>
        </Menu>
      </TableCell>
      <Dialog
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Delete Plan?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Do you really want to delete this plan? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={deletePlan.isWorking} onClick={handleCloseDeleteModal}>Cancel</Button>
          <LoadingButton loading={deletePlan.isWorking} loadingIndicator='Deleting...' onClick={handleDelete} autoFocus>
            Delete it
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default PlansHome
