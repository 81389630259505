import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  selectorWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  selectorItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
    padding: theme.spacing(1.5)

  },
  selectorItemRight: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  formButton: {
    marginTop: theme.spacing(2),
    width: '250px'
  }
}))
