import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  stepsHeader: {
    margin: theme.spacing(2, 0, 4, 0),
    fontWeight: 900
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    paddingBottom: '35px'
  },
  fieldsWrapper: {
    display: 'flex',
    gap: theme.spacing(1.5)
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: theme.spacing(1.5)
  },
  previousButton: {
    color: theme.palette.button.default,
    border: '2px solid #465270',
    textTransform: 'none',
    fontWeight: 700,
    fontSize: 14,
    minWidth: '145px',
    padding: '8px 28px',
    '&:hover': {
      border: '2px solid #465270'
    }
  },
  buttons: {
    width: '380px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    justifyItems: 'center'

  }
}))
