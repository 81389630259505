import React from 'react'
import { Avatar } from '@mui/material'
import { useStyles } from './style'

const ImageButton = ({ image, size = 'large', alt = 'Image Icon' }) => {
  const classes = useStyles()
  return (
    <Avatar
      src={image}
      alt={alt}
      classes={{ root: classes.root }}
      className={classes[size]}
    />
  )
}

export default ImageButton
