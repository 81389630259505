import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    '& p': {
      margin: '10px 0'
    }
  },
  customList: {
    margin: 0,
    padding: '0 0 0 5px',
    listStyleType: 'none',
    '& li': {
      marginBottom: 10
    }
  },
  scoreLabel: {
    fontSize: 20,
    color: theme.palette.primary.main
  }
}))
