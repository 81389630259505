import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  sliderRoot: {
    width: '98%'
  },
  checkbox: {
    marginTop: '48px'
  },
  fullWidth: {
    width: '100%'
  },
  sliderTitle: {
    margin: '10px 0',
    fontSize: '1.5rem'
  },
  disabled: {
    '& .MuiSlider-thumb': {
      width: '28px',
      height: '28px'
    }
  },
  sliderRail1: {
    height: '10px',
    background: 'linear-gradient(270.01deg, #C3C1E7 2.99%, #E5D4BA 100%)',
    borderRadius: '71px'
  },
  sliderRail2: {
    height: '10px',
    background: 'linear-gradient(269.75deg, #C3C1E7 3.19%, #DDCFC4 100%)',
    borderRadius: '71px'
  },
  sliderRail3: {
    height: '10px',
    background: 'linear-gradient(269.75deg, #C3C1E7 3.19%, #D7CCCD 100%)',
    borderRadius: '71px'
  },
  sliderRail4: {
    height: '10px',
    background: 'linear-gradient(269.75deg, #C3C1E7 3.19%, #D1C9D5 100%)',
    borderRadius: '71px'
  },
  sliderRail5: {
    height: '10px',
    background: 'linear-gradient(269.75deg, #C3C1E7 3.19%, #CAC5DE 100%)',
    borderRadius: '71px'
  },
  sliderRail6: {
    height: '10px',
    borderRadius: '71px'
  },
  sliderTrack1: {
    height: '10px',
    background: 'linear-gradient(269.75deg, #C4824B 3.19%, #E9961A 97.36%)',
    borderRadius: '71px'
  },
  sliderTrack2: {
    height: '10px',
    background: 'linear-gradient(269.75deg, #C4824B 3.19%, #E9961A 97.36%)',
    borderRadius: '71px'
  },
  sliderTrack3: {
    height: '10px',
    background: 'linear-gradient(269.95deg, #A47075 3.03%, #E9961A 99.95%)',
    borderRadius: '71px'
  },
  sliderTrack4: {
    height: '10px',
    background: 'linear-gradient(270.01deg, #845DA0 2.99%, #E9961A 100%)',
    borderRadius: '71px'
  },
  sliderTrack5: {
    height: '10px',
    background: 'linear-gradient(270.01deg, #644CC9 2.99%, #E9961A 100%)',
    borderRadius: '71px'
  },
  sliderTrack6: {
    height: '10px',
    background: 'linear-gradient(270.01deg, #453AF1 2.99%, #E9961A 100%)',
    borderRadius: '71px'
  },
  sliderThumb1: {
    color: '#E9961A',
    width: '28px',
    height: '28px'
  },
  sliderThumb2: {
    color: '#C4824B',
    width: '28px',
    height: '28px'
  },
  sliderThumb3: {
    color: '#A47075',
    width: '28px',
    height: '28px'
  },
  sliderThumb4: {
    color: '#845DA0',
    width: '28px',
    height: '28px'
  },
  sliderThumb5: {
    color: '#644CC9',
    width: '28px',
    height: '28px'
  },
  sliderThumb6: {
    color: '#453AF1',
    width: '28px',
    height: '28px'
  },
  sliderMark: {
    width: '3px',
    height: '9px',
    color: '#CACCCF',
    backgroundColor: '#CACCCF',
    top: '48px'
  },
  sliderMarkLabel: {
    top: '64px',
    fontSize: '20px',
    fontWeight: 700,
    color: '#707585'
  }
}))
