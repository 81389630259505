import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: '40vw'
    },
    padding: '5%'
  },
  box: {
    paddingBottom: 68,
    '& h1': {
      paddingBottom: 8
    }
  },
  subtitle: {
    marginTop: '46px'
  }
}))
