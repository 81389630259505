import React from 'react'
import { Typography, IconButton } from '@mui/material'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import { useStyles } from './style.js'

const ReFooter = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography className={classes.leftText} variant='body2'>© 2021 Resilience Engine.</Typography>
      <span className={classes.links}>
        <Typography
          className={classes.rightText}
          variant='body2'
        >
          Privacy Policy
        </Typography> -
        <Typography className={classes.rightText} variant='body2'>Terms and Conditions of Use</Typography> |
        <Typography className={classes.rightText} variant='body2'>
          Follow us:
          <IconButton
            aria-label='Linkedin Page'
            onClick={() => window.open('https://www.linkedin.com/company/the-resilience-engine/')}
            size="large">
            <LinkedInIcon fontSize='small' />
          </IconButton> |
          <IconButton
            aria-label='Twitter Page'
            onClick={() => window.open('https://twitter.com/resiliencengine')}
            size="large">
            <TwitterIcon fontSize='small' />
          </IconButton>
        </Typography>
      </span>
    </div>
  );
}

export default ReFooter
