import React, { useCallback, useMemo } from 'react'
import { Box, ButtonBase, CircularProgress, Grid, Typography } from '@mui/material'
import { userSlice, trackerSlice } from '../../reducers'
import { useStyles } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { useEventContext } from '../../contexts/event'
import { useNavigate } from 'react-router-dom'

const TrackTodayButton = () => {
  const classes = useStyles()
  const dispatcher = useDispatch()
  const navigate = useNavigate()
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const user = useSelector(userSlice.selectors.selectUser)
  const features = useMemo(() => user?.features
    ? user.features.reduce((acc, feat) => ({ ...acc, [feat.featureId]: true }), {})
    : {},
    [user.features])

  const events = useEventContext()

  const openTracker = useCallback(() => {
    dispatcher(trackerSlice.actions.setShowTracker(true))
  }, [dispatcher])
  const isIndividualView = (selectedView && selectedView.type === 'individual') || !selectedView

  if (!features.RESILIENCE_TRACKER) return null
  if (!isIndividualView) return null
  return (
    <Box
      variant='outlined'
      className={`${classes.button} onboarding-tracker`}
      disabled={events.getTrackerSteps.isWorking}
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Grid container direction='row' wrap='nowrap' className={classes.container}>
        <Grid xs={6} container item direction='column' justifyContent='center' className={classes.left}>
          <ButtonBase onClick={() => navigate('tracker')} sx={{ width: '100%', height: '100%' }}>
            <Typography component='span' variant='body2' className={classes.trackerTitle}>Resilience Tracker</Typography>
            {user && !!user.lastUpdateDaysDiff && (
              <Typography component='span' variant='body2' className={classes.buttonCaption}>last update: {user.lastUpdateDaysDiff} day{user.lastUpdateDaysDiff > 1 ? 's' : ''} ago</Typography>
            )}
          </ButtonBase>
        </Grid>
        <Grid xs={6} item container alignContent='center' justifyContent='center' className={classes.right}>
          <ButtonBase sx={{ width: '100%', height: '100%' }} onClick={openTracker}>
            {events.getTrackerSteps.isWorking || events.updateUserSettings.isWorking ? <CircularProgress size={30} color='info' />
              : <Typography component='span' variant='button'>TRACK NOW</Typography>}
          </ButtonBase>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TrackTodayButton
