import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { Auth } from 'aws-amplify'
import CircularProgress from '@mui/material/CircularProgress'
import useNavigate from '../../helpers/useNavigation'
import { useLocation, useParams } from 'react-router-dom'
import { useStyles } from './style'
import { Alert, Button, Link } from '@mui/material'
import useInput from '../../utils/useInput'
import { useSelector } from 'react-redux'
import clientConfig from '../../reducers/clientConfig'
import { useConnectionContext } from '@emerald-works/react-event-bus-client'

const SignIn = () => {
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const { state } = useLocation()
  const classes = useStyles()
  const navigate = useNavigate()
  const { value: email, bind: bindEmail } = useInput('', { email: true })
  const { value: password, bind: bindPassword } = useInput('')
  const [info, setInfo] = useState({ error: false, message: '' })
  const { identityProviders } = useSelector(clientConfig.selectors.selectClientConfig)
  const context = useConnectionContext()

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      await Auth.signIn(email, password)
      context.reloadConnection()
      if (state?.redirectTo) {
        navigate(state.redirectTo)
      } else {
        navigate('/dashboard')
      }
    } catch (error) {
      if (error.name === 'UserNotConfirmedException') {
        navigate('/confirmation', { state: { ...state, email } })
        return
      }
      setInfo({ error: true, message: error.message })
    }
    setLoading(false)
  }
  const resetPassword = (e) => {
    e.preventDefault()
    const re = /^(?!.*(?:\.-|-\.))[^@]+@[^\W_](?:[\w-]*[^\W_])?(?:\.[^\W_](?:[\w-]*[^\W_])?)+$/
    if (!re.test(email)) {
      setInfo({ error: true, message: 'Please enter a valid email address' })
    } else {
      navigate('/reset-password', { state: { email } })
    }
  }

  const externalProviders = identityProviders?.filter(provider => provider.toLowerCase() !== 'cognito')
  const isCognito = identityProviders?.find(provider => provider.toLowerCase() === 'cognito') && identityProviders.length === 1
  const singleProvider = externalProviders[0]

  useEffect(() => {
    if (externalProviders.length === 1) {
      Auth.federatedSignIn({
        provider: singleProvider,
        customState: JSON.stringify({ redirectTo: state?.redirectTo ? `/${params.tenantKey}/${state?.redirectTo}` : `/${params.tenantKey}/dashboard`, type: 'from-sign-in' })
      })
    }
  }, [externalProviders.length, params.tenantKey, singleProvider, state?.redirectTo])

  return (
    <div className={classes.container}>
      <img className={classes.logo} src='/img/logo-v2.svg' alt='Resilience Engine Logo' />
      {externalProviders.length > 1 &&
        <>
          <h1 style={{ fontSize: '22px', fontWeight: 600 }}>Sign in with bellow providers:</h1>
          {externalProviders.map(provider => (
            <Button
              key={provider}
              className={classes.buttonProvider} variant='contained' onClick={() => {
                Auth.federatedSignIn({
                  provider,
                  customState: JSON.stringify({ redirectTo: state.redirectTo ? `/${params.tenantKey}/${state.redirectTo}` : `/${params.tenantKey}/dashboard` })
                })
              }}
            >
              {provider}
            </Button>
          ))}
        </>}
      {isCognito &&
        <form
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <h1 style={{ fontSize: '22px', fontWeight: 600 }}>Sign in with your email</h1>
          <TextField className={classes.field} label='Email' {...bindEmail} type='email' />
          <TextField className={classes.field} label='Password' type='password' {...bindPassword} />
          <Link className={classes.link} href='' onClick={resetPassword}>Forgot your password?</Link>
          {info.message.length > 0 && <Alert severity={info.error ? 'error' : 'success'}>{info.message}</Alert>}
          <Button
            className={classes.button}
            variant='contained'
            size='large'
            type='submit'
            disabled={loading}
          >
            {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
            Login to Your Account
          </Button>
          <Button onClick={() => { navigate('/signup', { state }) }}>Create a new account</Button>
        </form>}
    </div>
  )
}

export default SignIn
