import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    borderRadius: '10px',
    height: '100%',
    maxHeight: '330px',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    maxWidth: '250px'
  },
  image: {
    width: '205px',
    height: '135px',
    borderRadius: '10px',
    marginBottom: '10px'
  },
  mostPopularTitle: {
    color: theme.palette.text.greyDark,
    fontWeight: 'bold',
    marginBottom: '5px'
  },
  contentTitle: {
    textAlign: 'center',
    color: theme.palette.button.default,
    fontSize: '14px'
  },
  typeTypo: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textDecoration: 'underline'
  },
  additionalInfo: {
    color: theme.palette.text.grey,
    gap: '3px'
  },
  carouselWrapper: {
    display: 'flex',
    gap: '30px',
    flexWrap: 'nowrap'
  },
  dotContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dotWrapper: {
    height: '100%',
    width: '20px'
  },
  dot: {
    backgroundColor: '#C4C4C4',
    height: '6px',
    width: '6px',
    borderRadius: '100%'
  },
  selected: {
    backgroundColor: '#465270'
  }
}))
