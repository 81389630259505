import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

export const clientConfig = createSlice({
  name: reducerName,
  initialState: {},
  reducers: {
    setClientConfig: (state, { payload }) => {
      state.clientConfig = payload
    }
  }
})

export const {
  setClientConfig
} = clientConfig.actions

// Reducer
export default clientConfig.reducer
