import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  tableWrapper: {
    padding: theme.spacing(2)
  },
  tableHeader: {
    backgroundColor: '#F6F8FB'
  },
  chipSuccess: {
    backgroundColor: `${theme.palette.success.main}90`,
    color: '#0b6345'
  },
  chipWarning: {
    backgroundColor: `${theme.palette.warning.main}90`,
    color: '#755b19'
  }
}))
