import moment from 'moment'
import { CloseOutlined, DescriptionOutlined } from '@mui/icons-material'
import { Box, ButtonBase, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { useStyles } from './style'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackerSlice } from '../../../reducers'

export const TrackerHeader = ({ step, steps, hasDoneDaily, hasDoneWeekly, onDailyClick, onWeeklyClick, onNoteClick }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isTooltipOpen, setIsToolTipOpen] = useState(false)
  const [isNoteTooltipOpen, setIsNoteToolTipOpen] = useState(false)

  const shouldShowDaily = useMemo(() => !!steps.filter(s => s.type === 'Daily').length, [steps])
  const shouldShowWeekly = useMemo(() => !!steps.filter(s => s.type === 'Weekly').length, [steps])

  const setShowTracker = useCallback(value => {
    dispatch(trackerSlice.actions.setShowTracker(value))
  }, [dispatch])

  const getStyles = (type) => {
    const style = type === 'Notes' ? [classes.noteSection] : [classes.questionSection]
    const isSelected = step?.type === type
    if (!isSelected) {
      if ((type === 'Daily' && hasDoneDaily) || (type === 'Weekly' && hasDoneWeekly)) {
        style.push(classes.questionSectionDone)
      }
    } else {
      style.push(classes.questionSectionSelected)
    }
    return style.join(' ')
  }

  const warnUserAboutDailies = useCallback((type) => {
    if (type === 'Weekly') {
      setIsToolTipOpen(true)
      setTimeout(() => setIsToolTipOpen(false), 3000)
    }
    if (type === 'Notes') {
      setIsNoteToolTipOpen(true)
      setTimeout(() => setIsNoteToolTipOpen(false), 3000)
    }
  }, [])

  return (
    <Box mb={2}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex' gap={1}>
          <Typography>Tracking for </Typography>
          <Typography color='primary'>{moment().format('dddd, DD MMMM YYYY')}</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => setShowTracker(false)}>
            <CloseOutlined color='primary' />
          </IconButton>
        </Box>
      </Box>
      <Box display='flex' justifyContent='flex-end' mr={8}>
        {shouldShowDaily &&
          <ButtonBase className={getStyles('Daily')} onClick={onDailyClick}>
            <Typography variant='h5'>Daily questions</Typography>
            {hasDoneDaily ? <TaskAltIcon fontSize='small' /> : <RadioButtonUncheckedIcon fontSize='small' />}
          </ButtonBase>
        }
        {shouldShowWeekly &&
          <Tooltip title="You need to answer your Daily questions first" placement='top' open={isTooltipOpen}>
            <ButtonBase className={getStyles('Weekly')} onClick={hasDoneDaily ? onWeeklyClick : () => warnUserAboutDailies('Weekly')} disabled={isTooltipOpen || isNoteTooltipOpen}>
              <Typography variant='h5'>Weekly questions</Typography>
              {hasDoneWeekly ? <TaskAltIcon fontSize='small' /> : <RadioButtonUncheckedIcon fontSize='small' />}
            </ButtonBase>
          </Tooltip>
        }
        <Tooltip title="You need to answer your Daily and Weekly questions first" placement='top' open={isNoteTooltipOpen}>
          <ButtonBase className={getStyles('Notes')} onClick={hasDoneDaily && hasDoneWeekly ? onNoteClick : () => warnUserAboutDailies('Notes')} disabled={isTooltipOpen || isNoteTooltipOpen}>
            <DescriptionOutlined />
          </ButtonBase>
        </Tooltip>
      </Box>
      <Divider />
    </Box>
  )
}
