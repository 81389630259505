import React from 'react'
import { useStyles } from './style'
import { FormControl, TextField, Typography, Slider, SliderThumb } from '@mui/material'

const marks = [
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: '2'
  },
  {
    value: 3,
    label: '3'
  },
  {
    value: 4,
    label: '4'
  },
  {
    value: 5,
    label: '5'
  },
  {
    value: 6,
    label: '6'
  }
]

const TrackerQuestion = ({ question, type, pushAnswer, defaultValue }) => {
  const classes = useStyles()
  const [sliderValue, setValue] = React.useState(defaultValue.answer || 1)
  const [note, setNoteValue] = React.useState(defaultValue.note || '')
  const { text } = question

  const handleChange = (e, newValue) => {
    if (type !== 'Notes') {
      const currentValue = newValue
      setValue(currentValue)
      pushAnswer({ question, answer: currentValue })
    } else {
      setNoteValue(e.target.value)
      pushAnswer({ question, answer: 0, note: e.target.value })
    }
  }

  let railtStyle = classes.sliderRail1
  let trackStyle = classes.sliderTrack3
  let thumbStyle = classes.sliderThumb3

  if (Number.isInteger(parseInt(sliderValue))) {
    railtStyle = classes[`sliderRail${sliderValue}`]
    trackStyle = classes[`sliderTrack${sliderValue}`]
    thumbStyle = classes[`sliderThumb${sliderValue}`]
  }

  if (type && type !== 'Notes') {
    return (
      <div className={classes.sliderRoot}>
        <Typography variant='h4' className={classes.sliderTitle}>{text}</Typography>
        <Slider
          value={sliderValue}
          marks={marks}
          onChangeCommitted={handleChange}
          components={{
            Thumb: ({ children, ...other }) => (
              <SliderThumb {...other} className={thumbStyle}>
                {children}
                <span style={{ width: '10px', height: '10px', backgroundColor: 'white', borderRadius: '50%' }} />
              </SliderThumb>
            ),
          }}
          classes={{
            rail: railtStyle,
            track: trackStyle,
            thumb: thumbStyle,
            mark: classes.sliderMark,
            markActive: classes.sliderMark,
            markLabel: classes.sliderMarkLabel,
            markLabelActive: classes.sliderMarkLabel,
            disabled: classes.disabled
          }}
          min={1}
          max={6}
          step={1}
        />
      </div>
    )
  }
  if (type && type === 'Notes') {
    return (
      <div className={classes.fullWidth}>
        <FormControl fullWidth>
          <TextField
            onChange={(e) => handleChange(e, e.target.value)}
            variant='outlined'
            placeholder='Write here...'
            name='note'
            value={note}
            color='secondary'
            rows={16}
            multiline
            fullWidth
          />
        </FormControl>
      </div>
    )
  }
  return null
}

export default TrackerQuestion
