import { Paper } from '@mui/material'
import { useState } from 'react'
import { RenderDrivers } from './charts'
import { useStyles } from './styles'

export const initialOptions = [
  { key: 'psychological_safety', name: 'PSYCHOLOGICAL SAFETY', color: '#5EC3CD' },
  { key: 'workload_stress', name: 'WORKLOAD & STRESS', color: '#E56161' },
  { key: 'trust', name: 'TRUST', color: '#868B92' },
  { key: 'engagement', name: 'ENGAGEMENT', color: '#5B4A99' }
]

const OrganisationalDriversCard = () => {
  const classes = useStyles()
  const [chartOptions, setChartOptions] = useState(initialOptions)

  return (
    <Paper elevation={2} className={classes.paper}>
      <RenderDrivers chartOptions={chartOptions} setChartOptions={setChartOptions} />
    </Paper>

  )
}

export default OrganisationalDriversCard
