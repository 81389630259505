import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    maxWidth: '600px'
  },
  header: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(3)
  },
  description: {
    marginBottom: theme.spacing(1.5)
  },
  input: {
    maxWidth: '600px',
    marginBottom: theme.spacing(5)
  },
  nextButton: {
    marginTop: theme.spacing(4)
  },
  licenseLoader: {
    position: 'absolute',
    top: '15%',
    right: '8%'
  }
}))
