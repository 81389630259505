import moment from 'moment'

export const getWeeklyDates = (date) => {
  const currentDate = moment(date)
  const weekStart = currentDate.clone().startOf('week').add(1, 'day')

  const days = []
  for (let i = 0; i < 5; i++) {
    days.push(moment(weekStart).add(i, 'days'))
  }

  return days
}
