import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    color: '#465270',
    fontSize: 14,
    fontWeight: 500
  },
  highlightText: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 700
  }
}))
