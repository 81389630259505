import React from 'react'
import { Grid, Paper, Dialog } from '@mui/material'
import { useStyles } from './style'

const ImageModal = ({ open, handleClose, image, alt = 'Content' }) => {
  const classes = useStyles()
  // image = 'https://toolkit.resilienceengine.dev/wp-content/uploads/2021/01/30-Times-of-Change-Lobster-Story-Resilience-Engine-Bundle-Main-Content-1024x693.png'

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg'>
      <Paper elevation={2} className={classes.container}>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
        >
          <Grid item xs={12} className={classes.imageContainer}>
            <img src={image} alt={alt} className={classes.image} />
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  )
}

export default ImageModal
