import { useStyles } from './style'
const Forbidden = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <img src='/img/logo-v2.svg' alt='Resilience Engine Logo' />
      <div>
        <img src='/img/403-v2.svg' alt='403 Forbidden' />
        <h3>403 Error - Forbidden</h3>
        <p>We're sorry, but you do not have access to this page.</p>
      </div>
    </div >
  );
}

export default Forbidden