import React, { useCallback, useEffect, useMemo } from 'react'
import { ButtonBase, Grid, Typography } from '@mui/material'
import { useStyles } from './style'
import classNames from 'classnames'

const ReSwitchButtonV2 = ({ onClick, options, disabledOptions = [], state = null, height, bgColor, itemWidth, dataTestPrefix = '' }) => {
  const filteredOptions = useMemo(() => options.filter(option => !disabledOptions.some(i => i === option.value)), [disabledOptions, options])
  const selectedPosition = useMemo(() => filteredOptions.findIndex(o => o.value === state), [state, filteredOptions])
  const styles = useStyles({ size: filteredOptions.length, selectedPosition, height, bgColor, itemWidth })

  const overrideOnClick = useCallback((newState) => {
    if (onClick) onClick(filteredOptions[newState])
  }, [filteredOptions, onClick])

  const getTypoClasses = useCallback((selected) => {
    if (!selected) return styles.optionTypo
    return classNames(styles.optionTypo, styles.optionTypoSelected)
  }, [styles])

  useEffect(() => {
    if (selectedPosition === -1) overrideOnClick(0)
  }, [selectedPosition, overrideOnClick])

  return (
    <Grid className={styles.container} container wrap='nowrap' justifyContent='center' alignItems='center'>
      <div className={styles.toggle} />
      {
        filteredOptions.map((option, index) => (
          <ButtonBase disabled={false} data-test={`${dataTestPrefix}-${index}`} key={index} onClick={() => overrideOnClick(index)} className={styles.buttonBase}>
            <Grid className={styles.optionContainer} item xs={6}>
              <Typography className={getTypoClasses(selectedPosition === index)}>{option.label}</Typography>
            </Grid>
          </ButtonBase>
        ))
      }
    </Grid>

  )
}

export default ReSwitchButtonV2
