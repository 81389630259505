import { ClickAwayListener, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import React from "react"
import SaveIcon from '@mui/icons-material/Save'
import EditIcon from '@mui/icons-material/Edit'
import { useStyles } from "./style"

export const ProfileInfoField = ({ field, value, canEdit, handleChange, handleSave }) => {
  const classes = useStyles()
  const [isEdit, setIsEdit] = React.useState(false)
  return (
    <div className={classes.field}>
      <Typography className={classes.fieldName}>{field.fieldName}</Typography>
      {!isEdit ? (
        <RenderInputValue field={field} value={value} />
      ) : (
        <ClickAwayListener>
          <RenderInput
            field={field}
            value={value}
            disabled={!canEdit}
            handleChange={handleChange}
          />
        </ClickAwayListener>
      )}
      <ProfileInfoFieldButton canEdit={canEdit} isEdit={isEdit} setIsEdit={setIsEdit} handleSave={handleSave} />
    </div>
  )
}

const ProfileInfoFieldButton = ({ canEdit, isEdit, setIsEdit, handleSave }) => {
  const classes = useStyles()
  if (!canEdit) return null
  if (!isEdit && canEdit) return (
    <IconButton onClick={() => setIsEdit(!isEdit)} className={classes.fieldBtn} aria-label='edit' size='small'>
      <EditIcon fontSize='small' />
    </IconButton>
  )
  return (
    <IconButton
      onClick={() => { handleSave(); setIsEdit(!isEdit) }}
      className={classes.fieldBtn}
      aria-label='edit' size='small'>
      <SaveIcon fontSize='small' />
    </IconButton>
  )
}

const RenderInputValue = ({ field, value }) => {
  const classes = useStyles()
  switch (field.fieldType) {
    case 'input': {
      return <Typography className={classes.fieldValue}>{value}</Typography>
    }
    case 'select': {
      const finalValue = field.fieldOptions.find(f => f.key === value)?.name
      return <Typography className={classes.fieldValue}>{finalValue}</Typography>
    }
    default:
      return null
  }
}

const RenderInput = ({ field, handleChange, value, disabled, handleSend, shouldSendOnSelect }) => {
  switch (field.fieldType) {
    case 'select': {
      return (
        <FormControl fullWidth>
          <InputLabel>{field.fieldName}</InputLabel>
          <Select
            sx={{ height: '50px' }}
            disabled={disabled}
            name={field.fieldKey}
            variant='outlined'
            label={field.fieldName}
            value={value}
            onChange={e => handleChange(e, shouldSendOnSelect)}
          >
            {
              field.fieldOptions.map((value) => (
                <MenuItem key={value.key} value={value.key}>{value.name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      )
    }
    case 'input': {
      return (
        <FormControl fullWidth>
          <TextField
            disabled={disabled}
            name={field.fieldKey}
            variant='outlined'
            label={field.fieldName}
            value={value}
            onChange={handleChange}
            onBlur={handleSend}
          />
        </FormControl>
      )
    }
    default:
      return null
  }
}