import moment from 'moment'

export const getNextWeekDay = (day) => {
  const dayAsMoment = moment(day)
  if (dayAsMoment.weekday() === 5) {
    return dayAsMoment.add(3, 'days')

  }
  if (dayAsMoment.weekday() === 6) {
    return dayAsMoment.add(2, 'days')
  }
  return dayAsMoment.add(1, 'days')
}