import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    paddingTop: '140px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '360px',
    textAlign: 'center'
  },
  logo: {
    position: 'absolute',
    width: '200px',
    top: '45px'
  },
  link: {
    color: theme.palette.secondary.dark,
    textDecorationColor: theme.palette.secondary.dark
  },
  button: {
    marginTop: '10px',
    color: theme.palette.button.color
  },
  field: {
    marginTop: '20px'
  },
  buttonProvider: {
    margin: '5px',
    backgroundColor: theme.palette.secondary.main
  }
}))
