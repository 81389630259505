import * as actions from './slice'

export const getCycles = {
  eventName: 'getCycles',
  onSuccess: [{ redux: true, action: actions.setCycles }]
}

export const saveCycle = {
  eventName: 'saveCycle',
  onSuccess: [{ redux: true, action: actions.setCycles }]
}
