import { Button, Paper, Typography, TextField, Box, Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import React, { useState } from 'react'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useStyles } from './styles'
import { ArrowLeft } from '@mui/icons-material'
import { Controller, useForm } from 'react-hook-form'
import { CustomOverlay, ReButton } from '../../../../components'

const PlanForm = ({ defaultValues, onSubmit, onCancel, title, buttonText, loading, loadingMessage }) => {
  // const { planId } = useParams()
  const classes = useStyles()
  const [availableFeatures, setAvailableFeatures] = useState([])
  // const navigate = useNavigation()
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues
  })

  const features = watch('features')

  const [getAllFeatures] = useCorvusEventList([
    {
      eventName: 'Feature List Was Requested',
      onSuccess: (payload) => {
        console.log(payload)
        setAvailableFeatures(payload)
      }
    }
  ])

  useEventsOnViewLoad(() => {
    getAllFeatures.trigger()
  }, [getAllFeatures])

  const handleCheckBoxChange = (feature) => {
    const isFeatureSelected = features.includes(feature.featureName)
    if (isFeatureSelected) {
      setValue('features', features.filter(f => f !== feature.featureName))
    } else {
      setValue('features', [...features, feature.featureName])
    }
  }

  return (
    <CustomOverlay
      active={getAllFeatures.isWorking || loading}
      text={loadingMessage || 'loading...'}
    >
      <div>
        <Paper className={classes.container}>
          <div>
            <Button color='secondary' size='small' startIcon={<ArrowLeft />} onClick={onCancel}>Back to list</Button>
            <Typography variant='h4' className={classes.header}>
              {title}
            </Typography>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.formContainer}>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <TextField
                  className={classes.input}
                  {...field}
                  placeholder='Name'
                />
              )}
            />
            <Controller
              name='description'
              control={control}
              render={({ field }) => (
                <TextField
                  className={classes.input}
                  {...field}
                  placeholder='Description'
                />
              )}
            />
            <Typography mt={2} mb={2} fontWeight='bold'>Features</Typography>
            <Box display='flex' flexDirection='row'>
              <FormGroup className={classes.checkboxGroupContainer}>
                {
                  availableFeatures.map((feature, index) => (
                    <FormControlLabel
                      key={index}
                      className={classes.checkboxContainer}
                      checked={features.includes(feature.featureName)}
                      label={feature.featureName}
                      control={<Checkbox onClick={() => handleCheckBoxChange(feature)} />}
                    />
                  ))
                }
              </FormGroup>
            </Box>

            <Box display='flex' flexDirection='row' mt={2}>
              <ReButton
                name='Cancel'
                type='button'
                bgColor='#FFF'
                color='#000'
                action={onCancel}
              />
              <ReButton
                name={buttonText}
                type='submit'
              />
            </Box>
          </form>
        </Paper>
      </div>
    </CustomOverlay>
  )
}

export default PlanForm
