import name from './name'

const selectSliceData = state => state[name]

const selectLicenseList = state => selectSliceData(state).licenseList
const selectFeatureList = state => selectSliceData(state).featureList

export {
  selectLicenseList,
  selectFeatureList
}
