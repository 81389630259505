import { Paper, Typography } from '@mui/material'
import { FilterBar } from '..'
import { useStyles } from './styles'

const AggregationHeader = ({ title }) => {
  const classes = useStyles()
  return (
    <>
      {title && <Typography variant='h4' fontWeight='bold' marginBottom={3}>{title}</Typography>}
      <Paper className={classes.paper} display='flex'>
        <FilterBar />
      </Paper>
    </>
  )
}

export default AggregationHeader
