import React, { useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Grid, Typography, Button, Link, Breadcrumbs } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import GetAppIcon from '@mui/icons-material/GetApp'
import { TipsCard } from '../../components'
import useNavigate from '../../helpers/useNavigation'
import 'video-react/dist/video-react.css'
import { useStyles } from './style'
import { tips } from '../../helpers/mocks'
import { useSelector } from 'react-redux'
import { featureSelector } from '../../reducers/user/selectors'
import { useEventContext } from '../../contexts/event'

const TipsPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const params = useParams()
  const { state } = useLocation()
  const features = useSelector(featureSelector)
  const { getUser } = useEventContext()
  const [selectedTip, setSelectedTip] = React.useState(state || null)
  const handleTipClick = tip => {
    navigate(`/dashboard/help/${tip.id}`)
  }

  const handleBackClick = () => {
    navigate('/dashboard/help')
    setSelectedTip(null)
  }

  useEffect(() => {
    if (params?.id) {
      setSelectedTip(tips.find(tip => tip.id === params.id))
    }
  }, [params])

  const parsedTips = useMemo(() => {
    const result = tips.filter(tip => {
      if (((tip.feature === 'RESILIENCE_TRACKER') && !features.RESILIENCE_TRACKER)) return false
      if ((tip.feature === 'RESILIENCE_TOOLKIT' && !features.RESILIENCE_TOOLKIT)) return false
      if ((tip.feature === 'RESILIENCE_EVALUATION' && !features.RESILIENCE_EVALUATION)) return false
      return true
    })
    return result
  }, [features])

  return (
    <div className={classes.root}>
      {selectedTip === null ? (
        <Grid container gap={3} fullWidth justifyContent='flex-start'>
          {!getUser.isWorking
            ? (
              parsedTips.map((tip, idx) => (
                <Grid key={idx} item>
                  <TipsCard tip={tip} onClick={handleTipClick} />
                </Grid>)
              ))
            : 'Loading...'}
        </Grid>
      ) : (
        <Grid container spacing={2} fullWidth>
          <Grid item xs={12}>
            <Breadcrumbs separator={'>'}>
              <Link className={classes.breadcrumb} onClick={() => navigate('/dashboard')}>DASHBOARD</Link>
              <Link className={classes.breadcrumb} onClick={handleBackClick}>HELP</Link>
              <Link className={classes.breadcrumb}>{selectedTip.title.toUpperCase()}</Link>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.header}>{selectedTip.title}</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                {selectedTip.type === 'video' && (
                  <video width='100%' height='100%' controls>
                    <source src={selectedTip.url} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                )}
                {selectedTip.type === 'download' && (
                  <Grid container direction='column' alignItems='center' justifyContent='center' spacing={3}>
                    <Grid item className={classes.tipImage}>
                      <img src={selectedTip.image} width='100%' height='250px' alt={`${selectedTip.title} tip`} />
                    </Grid>
                    <Grid item>
                      <Button
                        title='Download File'
                        aria-label='Download File'
                        className={classes.backButton}
                        onClick={() => window.open(selectedTip.url)}
                        variant='contained'
                      >
                        <GetAppIcon fontSize='small' /> Download
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography className={classes.title}>{selectedTip.content.title}</Typography>
                {selectedTip.content.text.map((text, idx) => (
                  <Typography key={idx} className={classes.content}>{text}</Typography>
                ))}
                <Button className={classes.backButton} startIcon={<ArrowBackIcon />} onClick={handleBackClick}>Return to help page</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      )}
    </div>
  )
}

export default TipsPage
