import React from 'react'
import useNavigate from '../../helpers/useNavigation'
import { Box, Container, Typography } from '@mui/material'
import { ReButton } from '../../components'
import { useStyles } from './style'
import { Auth } from 'aws-amplify'

const LoginBody = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const texts = {
    title: 'Welcome to the Resilience Dynamic Dashboard®',
    subtitle: 'Understand and support your own resilience and wellbeing using your own dashboard. You will feel better, and perform better as a result.'
  }

  const getStarted = async () => {
    try {
      await Auth.currentAuthenticatedUser()
      navigate('dashboard')
    } catch (err) {
      navigate('signin')
    }
  }

  return (
    <Container className={classes.container}>
      <Box className={classes.box}>
        <Typography variant='h1' component='h1'>
          {texts.title}
        </Typography>
        <Typography className={classes.subtitle} variant='h6' component='h6'>
          {texts.subtitle}
        </Typography>
      </Box>
      <ReButton action={getStarted} dataTestId='welcome_getstarted_btn' name='Get started' />
    </Container>
  )
}

export default LoginBody
