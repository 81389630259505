import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import useNavigation from '../../../helpers/useNavigation'
import PlanForm from '../components/form'
import { CustomOverlay } from '../../../components'
import { useAlert } from '../../../hooks'
import Forbidden from '../../403'
import ability from '../../../config/ability'

const PlanEditPage = () => {
  const { planId } = useParams()
  const [plan, setPlan] = useState(null)
  const { showSuccessAlert, showErrorAlert } = useAlert()
  const navigate = useNavigation()

  const [editPlan] = useCorvusEventList([{
    eventName: 'Plan Was Edited',
    onSuccess: () => {
      showSuccessAlert('Success!', 'Plan was edited')
      navigate('/dashboard/plans')
    },
    onError: (error) => {
      showErrorAlert('Error!', 'An unexpected error has occurred')
      console.log(error)
    }
  }])

  const [planInfoWasRequested] = useCorvusEventList([{
    eventName: 'Plan Info Was Requested',
    onSuccess: (plan) => {
      console.log(plan)
      setPlan(plan)
    }
  }])

  useEventsOnViewLoad(() => {
    if (planId) {
      planInfoWasRequested.trigger({ planId })
    }
  }, [planId, planInfoWasRequested])

  const handleCancelButton = () => {
    navigate('/dashboard/plans')
  }

  const onSubmit = (data) => {
    console.log(data)
    editPlan.trigger({
      plan: data
    })
  }

  if (!plan || planInfoWasRequested.isWorking) {
    return <CustomOverlay active={planInfoWasRequested.isWorking} text='Loading plan information' />
  }

  const isSuperAdmin = ability.can('view', 'superAdminView')

  return (
    !isSuperAdmin ? <Forbidden />
      : <PlanForm
        title='Edit Plan'
        loading={editPlan.isWorking}
        loadingMessage={editPlan.isWorking && 'Editing Plan...'}
        onSubmit={onSubmit}
        onCancel={handleCancelButton}
        defaultValues={plan}
        buttonText='Edit'
      />
  )
}

export default PlanEditPage
