import React, { useEffect } from 'react'
import { Skeleton, TableContainer, Paper, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { InformationalCard, TrackerConfiguration } from '..'
import { useStyles } from './style'
import { settingsSlice } from '../../reducers'
import ProfileNotificationsSwitch from '../profile-notifications'
import useFeatures from '../../hooks/useFeatures'
import { useEventContext } from '../../contexts/event'
import OneSignal from 'react-onesignal'

const parseStatus = ({ on, repeat }) => {
  return !on ? 1 : repeat === 'daily'
    ? 2 : 3
}

const defaultTrackerMandatoryValues = {
  sleep: false,
  stress: false,
  main: true,
  wellbeing: false,
  contentedness: false
}

const TrackerInfoCard = ({ accState, ...props }) => {
  const entries = useFeatures()
  const classes = useStyles()
  const [nativeNotificationStatus, setNativeNotificationStatus] = React.useState(null)
  const userSettings = useSelector(settingsSlice.selectors.selectUserSettings)
  const { updateUserSettings } = useEventContext()
  const userTracker = userSettings ? userSettings.tracker : null
  const isLoading = userTracker === null
  const userNotifications = userSettings ? userSettings.notifications : null
  const [trackerSettings, setTrackerSettings] = React.useState(() => {
    if (userSettings) {
      const newTrackerSettings = Object.keys(userSettings.tracker).reduce((acc, key) => {
        acc[key] = parseStatus(userSettings.tracker[key])
        return acc
      }, {})
      return newTrackerSettings
    }
  })

  useEffect(() => {
    OneSignal.getNotificationPermission().then((permission) => {
      setNativeNotificationStatus(permission)
    })
  }, [])

  useEffect(() => {
    if (userSettings) {
      const newTrackerSettings = Object.keys(userSettings.tracker).reduce((acc, key) => {
        acc[key] = parseStatus(userSettings.tracker[key])
        return acc
      }, {})
      setTrackerSettings(newTrackerSettings)
    }
  }, [userSettings])

  const handleChanges = (name, value) => {
    updateUserSettings.trigger({ type: 'tracker', name, value })
    setTrackerSettings({ ...trackerSettings, [name]: value })
  }

  return (
    <div className={classes.root}>
      <InformationalCard title='Resilience Tracker Configuration' accState={accState}>
        <Typography>
          Track your resilience demand, capacity, energy and wellbeing using the Resilience Tracker. Set your Tracker settings to daily or weekly, based on guidance from your organisation.
        </Typography>
        <Typography>
          Please note that the Resilience Tracker is only available if the feature is included in your organisation’s Dashboard plan.
        </Typography>
        <Typography>
          Depending on the option you have chosen to pace yourself through using the Dashboard, you may not have access to the Tracker immediately. Once you are able to access the Tracker you will see it at the top of the menu, as well as on your dashboard.
        </Typography>
        <div style={{ marginBottom: '10px' }}>
          {nativeNotificationStatus === null
            ? (<span>
              <Skeleton variant='rectangular' height='80px' width='100%' />
            </span>)
            : (
              <>
                <span className={classes.cardsContainer}>
                  <ProfileNotificationsSwitch
                    name='browser'
                    label='Receive notifications(via browser) for Resilience Tracker'
                    value={nativeNotificationStatus !== 'granted' ? false : userNotifications.browser}
                    disabled={nativeNotificationStatus === 'denied'}
                    nativeNotificationStatus={nativeNotificationStatus}
                    updateUserSettings={updateUserSettings}
                    setNativeNotificationStatus={setNativeNotificationStatus}
                  />
                </span>
                {nativeNotificationStatus === 'denied' &&
                  <Typography color='#e74c3c'>You've disabled the notifications natively. You'll need to enable then again via browser.</Typography>
                }
              </>
            )}
        </div>
        <div className={classes.cardsContainer}>
          {isLoading
            ? (
              <span>
                <Skeleton style={{ marginBottom: '-20px', marginTop: '-20px' }} variant='text' animation='wave' height='80px' width='100%' />
                <Skeleton style={{ marginBottom: '-20px' }} variant='text' animation='wave' height='80px' width='100%' />
                <Skeleton style={{ marginBottom: '-20px' }} variant='text' animation='wave' height='80px' width='100%' />
                <Skeleton style={{ marginBottom: '-20px' }} variant='text' animation='wave' height='80px' width='100%' />
                <Skeleton variant='text' animation='wave' height='80px' width='100%' />
              </span>
            )
            : (
              <TableContainer component={Paper}>
                <TrackerConfiguration
                  values={trackerSettings}
                  handleChanges={handleChanges}
                  isWorking={updateUserSettings.isWorking}
                  isMandatoryValues={entries.RESILIENCE_TRACKER.isTrackerMandatory || defaultTrackerMandatoryValues}
                />
              </TableContainer>
            )}
        </div>
      </InformationalCard>
    </div>
  )
}

export default TrackerInfoCard
