import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  container: {
    padding: '26px 0',
    borderRadius: '10px',
    height: '100%',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    display: 'flex',
    flexDirection: 'row',
    minWidth: '965px',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.secondary.dark
  },
  leftContent: {
    height: '100%',
    textAlign: 'center',
    width: '50%',
    maxWidth: '370px',
    margin: '30px'
  },
  rightContent: {
    maxWidth: '360px',
    margin: '30px'
  },
  rightContentTitle: {
    color: theme.palette.text.greyDark,
    fontSize: '16px',
    marginBottom: theme.spacing(2)
  },
  informationIcon: {
    cursor: 'pointer',
    width: 16,
    height: 16,
    fill: '#465270'
  },
  toolpathwayTitle: {
    color: theme.palette.text.primary,
    lineHeight: 1.2,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  toolpathwayDescription: {
    textAlign: 'left'
  },
  readMore: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    fontSize: '10px'
  },
  flagContainer: {
    gap: '5px'
  }
}))
