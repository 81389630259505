import { TextField } from '@mui/material'
import React, { useContext } from 'react'
import { useStyles } from './style'
import { ViewTeamContext } from '..'

export default function StepOne () {
  const classes = useStyles()
  const { teamName, setTeamName } = useContext(ViewTeamContext)

  return (
    <div className={classes.root}>
      <TextField label='Group name' disabled value={teamName} onChange={e => setTeamName(e.target.value)} />
    </div>
  )
}
