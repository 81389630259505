import React, { useEffect, useState } from 'react'
import { ProfileInfoCard, TrackerInfoCard, OccupationInforCard } from '../../components'
import { Grid, Typography } from '@mui/material'
import 'video-react/dist/video-react.css'
import { useStyles } from './style'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { settingsSlice, userSlice } from '../../reducers'
import { useSelector } from 'react-redux'
import { useEventContext } from '../../contexts/event'
import { featureSelector } from '../../reducers/user/selectors'
import DataUsageCard from '../../components/profile-data'
import { useLocation } from 'react-router-dom'

const ProfilePage = () => {
  const classes = useStyles()
  const [getUserSettings] = useCorvusEventList([settingsSlice.eventBus.getUserSettings])
  const userSettings = useSelector(settingsSlice.selectors.selectUserSettings)
  const features = useSelector(featureSelector)
  const user = useSelector(userSlice.selectors.selectUser)
  const userInfo = userSettings ? userSettings.info : null
  const events = useEventContext()
  const { state } = useLocation()
  const [currentPersonalSettings, setCurrentPersonalSettings] = useState(user?.personalSettings)

  useEventsOnViewLoad(() => {
    if (!userSettings) {
      getUserSettings.trigger()
    }
  }, [userSettings, getUserSettings])
  useEffect(() => {
    if (user?.personalSettings) {
      setCurrentPersonalSettings(user.personalSettings)
    }
  }, [user?.personalSettings])

  // TODO DYNAMICFIELDS
  return (
    <div className={classes.root}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12} lg={8}>
          <Grid marginBottom={2}><Typography className={classes.header} variant='h5'>Profile Settings</Typography></Grid>
          {!events.getUser.isWorking ? (
            <Grid>
              <Grid marginBottom={2}><ProfileInfoCard currentPersonalSettings={currentPersonalSettings} setCurrentPersonalSettings={setCurrentPersonalSettings} user={user} data={userInfo} accState={false} /></Grid>
              <Grid marginBottom={2}><OccupationInforCard currentPersonalSettings={currentPersonalSettings} setCurrentPersonalSettings={setCurrentPersonalSettings} accState={false} /></Grid>
              <Grid marginBottom={2}><DataUsageCard accState={false} /></Grid>
              {features.RESILIENCE_TRACKER && <TrackerInfoCard accState={state?.open.includes('tracker')} />}
            </Grid>
          )
            : 'Loading...'}
        </Grid>
      </Grid>
    </div>
  )
}

export default ProfilePage
