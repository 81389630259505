import { useCallback, useMemo, useRef } from 'react'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'

function usePromiseEvent (eventName) {
  const promiseRef = useRef(null)
  const [eventTrigger] = useCorvusEventList([{
    eventName,
    onSuccess: (payload) => {
      if (promiseRef.current) promiseRef.current.resolve(payload)
    },
    onError: (err) => {
      if (promiseRef.current) promiseRef.current.reject(err)
    }
  }])
  const trigger = useCallback((payload) => {
    return new Promise((resolve, reject) => {
      promiseRef.current = {
        resolve,
        reject
      }
      eventTrigger.trigger(payload)
    })
  }, [eventTrigger])
  return useMemo(() => ({ promise: trigger, event: eventTrigger }), [trigger, eventTrigger])
}

export default usePromiseEvent
