import React, { useMemo } from 'react'
import moment from 'moment'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { roundNumber } from '../../helpers/utils'
import { resilienceNames, wellbeingNames } from './tracker-chart-utils'

const sortDates = (d1, d2) => {
  return moment(d1, 'DD/MM/YYYY').valueOf() - moment(d2, 'DD/MM/YYYY').valueOf()
}

const MultiLineChart = ({ data, type, chartOptions }) => {
  const dataToRender = useMemo(() => {
    const result = data.reduce((acc, curr) => {
      const key = moment(curr.createdAt).format('DD/MM/YYYY')
      acc[key] = { ...acc[key], [curr.question.name]: roundNumber(curr.answer) }
      return acc
    }, {})
    return Object.entries(result).sort(([d1], [d2]) => sortDates(d1, d2)).map(([k, v]) => ({
      name: k,
      ...v
    }))
  }, [data])
  const namesToRender = useMemo(() => {
    let result = type === 'resilience' ? resilienceNames : wellbeingNames
    const possibleFilters = chartOptions.filter(i => i.isSelected)
    if (possibleFilters.length) {
      result = result.filter(i => possibleFilters.find(j => j.name === i.name))
    }
    return result
  }, [type, chartOptions])

  return (
    <div>
      <ResponsiveContainer width='100%' height={500}>
        <LineChart
          width={500}
          height={300}
          data={dataToRender}
          margin={{
            top: 10,
            right: 10,
            left: 10,
            bottom: 10
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey='name' tickFormatter={timeStr => moment(timeStr, 'DD/MM/YYYY').format('DD/MM')} />
          <YAxis ticks={[1, 2, 3, 4, 5, 6]} tickCount={0} />
          <Tooltip />
          {
            namesToRender.map(({ name, color, isSelected }) => (
              <Line key={name} type='monotone' strokeWidth={3} connectNulls dataKey={name} stroke={color} activeDot={{ r: 8 }} dot={false} />
            ))
          }
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default MultiLineChart
