import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Typography } from '@mui/material'
import React, { createContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const InvitationContext = createContext({})
const Invitation = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [invite, setInvite] = React.useState(null)

  const [inviteWasRequested] = useCorvusEventList([{
    eventName: 'Invite Was Requested',
    onSuccess: (inviteResult) => {
      console.log(inviteResult)
      setInvite(inviteResult)
      try {
        const [tenantKey] = inviteResult.pk.split('___')
        if (inviteResult && tenantKey === params.tenantKey) {
          navigate(`/${tenantKey}/signin`)
        }
      } catch (err) {
        setInvite(null)
      }
    }
  }])

  useEventsOnViewLoad(() => {
    inviteWasRequested.trigger({ inviteId: params.id })
  }, [inviteWasRequested, params.id])

  if (inviteWasRequested.isWorking) {
    return <Typography variant='caption'>Checking your invitation...</Typography>
  }

  if (!invite) {
    return <Typography variant='caption'>Invalid invitation</Typography>
  }

  return (
    <Typography variant='caption'>Redirecting...</Typography>
  )
}

export default Invitation
