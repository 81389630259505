import React, { useState, useEffect, useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import { InformationalCard, QuestionnaireIndicatorCard, AggregationHeader } from '../../components'
import { aggregationConfigs, userSlice } from '../../reducers'
import { useDispatch, useSelector } from 'react-redux'
import { lowDescriptions, highDescriptions, levelDescriptions } from '../../helpers/copy'
import { useStyles } from './style'
import useDashboardContext from '../../hooks/useDashboardContext'
import { useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { featureSelector } from '../../reducers/user/selectors'
import { Forbidden } from '../index'
import { useEventContext } from '../../contexts/event'

const ResilienceEvaluation = ({ showResults, type }) => {
  const classes = useStyles()
  const { isOrgProfile, changeDashboardFilter, currentData } = useDashboardContext()
  const [results, setResults] = useState(false)
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const dispatch = useDispatch()
  const title = useMemo(() => type === 'resilience' ? 'Resilience Dynamic® Questionnaire Results' : 'Resilience Dynamic® Indicator', [type])
  const features = useSelector(featureSelector)
  const { getUser } = useEventContext()

  useEffect(() => {
    setResults(currentData)
  }, [currentData])

  useEventsOnViewLoad(() => {
    if (type !== null) {
      dispatch(aggregationConfigs.actions.setFilterType(type))
    }
  }, [dispatch, selectedView, type])

  const renderIfCanShowResult = useMemo(() => {
    if (showResults === false) return null
    if (isOrgProfile) return <QuestionnaireIndicatorCard forceQuestionnaireOrIndicator={type} />
    if (['breakdown', 'fragmentation'].includes(currentData?.levelKey)) {
      return (
        <InformationalCard title={title} accState>
          <QuestionnaireOrIndicatorText type={type} />
        </InformationalCard>
      )
    } else {
      return <QuestionnaireIndicatorCard forceQuestionnaireOrIndicator={type} />
    }
  }, [isOrgProfile, title, currentData, type, showResults])
  if (getUser.isWorking) return null

  return (
    (title === 'Resilience Dynamic® Questionnaire Results' && !features.RESILIENCE_EVALUATION)
      ? <Forbidden />
      : (title === 'Resilience Dynamic® Indicator' && !features.RESILIENCE_INDICATOR)
        ? <Forbidden />
        : !getUser.isWorking
          ? (
            <div className={classes.root}>
              {isOrgProfile && <AggregationHeader />}
              {renderIfCanShowResult}
              <Grid container spacing={2} marginTop={2}>
                {results && (
                  <>
                    <Grid item xs={12} display='flex' flexDirection='column' gap={2}>
                      <InformationalCard
                        loading={changeDashboardFilter.isWorking}
                        title='Average High Score' description=''
                        subHeader={<div className={classes.scoreLabel}> {results && results.averageHighScore} </div>}
                      >
                        {results && highDescriptions[results.averageHighScore]}
                      </InformationalCard>
                      <InformationalCard
                        loading={changeDashboardFilter.isWorking}
                        title='Average Low Score' description=''
                        subHeader={<div className={classes.scoreLabel}> {results && results.averageLowScore} </div>}
                      >
                        {results && lowDescriptions[results.averageLowScore]}
                      </InformationalCard>
                      {selectedView && selectedView.key === 'individual' &&
                        <InformationalCard title='Insights' description='' loading={changeDashboardFilter.isWorking}>
                          <p style={{
                            borderBottom: '1px solid #E8E8E8',
                            marginBottom: 20,
                            paddingBottom: 10,
                            width: '100%'
                          }}
                          >
                            {results &&
                              levelDescriptions[results.level].map((text, i) => <p key={i}> {text} </p>)}
                          </p>
                        </InformationalCard>}
                      <InformationalCard loading={changeDashboardFilter.isWorking} title='Secure Areas' description='These are the drivers where your resilience is most secure. Consider how to ensure you benefit from these in all of your life.'>
                        <ul className={classes.customList}>
                          {results && results.questionAreas.secure.map(secure => (
                            <li key={secure}> - {secure} </li>
                          ))}
                        </ul>
                      </InformationalCard>
                      <InformationalCard loading={changeDashboardFilter.isWorking} title='Neutral Areas' description='These are the drivers that neither contribute nor drain your resilience. You may consider these as your easiest opportunity for making a shift in your resilience. '>
                        <ul className={classes.customList}>
                          {results &&
                            results.questionAreas.neutral.map(neutral => (
                              <li key={neutral}> - {neutral} </li>
                            ))}
                        </ul>
                      </InformationalCard>
                      <InformationalCard loading={changeDashboardFilter.isWorking} title='Watch Areas' description='These are the drivers of your resilience which are most draining. Consider these and how to shift them, only once you have explored your Neutral areas.'>
                        <ul className={classes.customList}>
                          {results && results.questionAreas.watch.map(watch => (
                            <li key={watch}> - {watch} </li>
                          ))}
                        </ul>
                      </InformationalCard>
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          ) : <>Loading...</>
  )
}

export const QuestionnaireOrIndicatorText = ({ type }) => {
  return (
    <>
      {type === 'resilience'
        ? (
          <>
            <Typography>Your Resilience Dynamic® Questionnaire report has identified that your resilience may be quite low at the moment.</Typography>
            <Typography>
              We know that it is normal for human resilience to go up and down and any of us can find ourselves at lower states of resilience. This can be for a
              number of reasons including the current context that we are experiencing at work and/or in our wider life.
            </Typography>
            <Typography>
              In your upcoming debrief session, your coach will help you to explore this more fully. They will check in with you to discuss whether or not you feel
              the report reflects a true picture or otherwise of what you are currently experiencing. And will focus on the resources that you do have and some simple things
              to take away that can help support your resilience.
            </Typography>
            <Typography>
              The debrief with your coach should already be scheduled within the next 2 - 3 days. In the meantime we suggest that you focus as best you can on taking care of
              yourself and getting any support you might need.
            </Typography>
            <Typography>
              If for any reason your debrief session is not already organised within the next 2 - 3 days please contact
              <a href='mailto:support@resilienceengine.com'>support@resilienceengine.com</a> so we can be sure to arrange contact soon.
            </Typography>
          </>
        ) : (
          <>
            <Typography>Your Resilience Dynamic® Indicator report has identified that your resilience may be quite low at the moment.</Typography>
            <Typography>
              We know that it is normal for human resilience to go up and down and any of us can find ourselves at lower states of resilience. This can be for a
              number of reasons including the current context that we are experiencing at work and/or in our wider life.
            </Typography>
            <Typography>
              We've not shared this information with anyone within your organisation, nor is it our plan to. We recommend you contact your employee support to
              find out more about the support options available to you within the organisation. And please, if you feel comfortable doing so, do let a manager know. You can
              also get in touch with the Resilience Engine at <a href='mailto:support@resilienceengine.com'>support@resilienceengine.com</a>.
            </Typography>
          </>
        )}
    </>
  )
}

export default ResilienceEvaluation
