import React, { useEffect } from 'react'
import { Popper as MuiPopper } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useBackdropContext } from '../../../contexts/backdrop'

const Popper = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== 'arrow'
})(({ theme, arrow }) => ({
  zIndex: 1,
  '& > div': {
    position: 'relative'
  },
  '&[data-popper-placement*="bottom"]': {
    '& > div': {
      marginTop: arrow ? 2 : 0
    },
    '& .MuiPopper-arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '2.2em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    }
  },
  '&[data-popper-placement*="top"]': {
    '& > div': {
      marginBottom: arrow ? 2 : 0
    },
    '& .MuiPopper-arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '2.2em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    }
  },
  '&[data-popper-placement*="right"]': {
    '& > div': {
      marginLeft: arrow ? 2 : 0
    },
    '& .MuiPopper-arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '2.2em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    }
  },
  '&[data-popper-placement*="left"]': {
    '& > div': {
      marginRight: arrow ? 2 : 0
    },
    '& .MuiPopper-arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '2.2em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    }
  }
}))

const Arrow = styled('div')({
  position: 'absolute',
  fontSize: 24,
  color: 'red',
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid'
  }
})

export default function PopperWrapper({ children, element, placement }) {
  const [arrowRef, setArrowRef] = React.useState(null)
  const backdrop = useBackdropContext()

  useEffect(() => {
    backdrop.setOpen(true)

    return () => backdrop.setOpen(false)
  }, [backdrop])

  return (
    <>
      <Popper
        style={{ zIndex: 1202 }}
        open
        anchorEl={element}
        placement={placement}
        disablePortal
        modifiers={[
          { name: 'arrow', options: { element: arrowRef } },
          { name: 'preventOverflow', options: { padding: 10, tether: true, altBoundary: true, rootBoundary: 'viewport' } },
          { name: 'offset', options: { offset: [0, 10] } }
        ]}
      >
        <Arrow ref={setArrowRef} className='MuiPopper-arrow' />
        {children}
      </Popper>
    </>
  )
}
