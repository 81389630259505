import React from 'react'
import { Paper, Typography, Button, Divider } from '@mui/material'
import MicIcon from '@mui/icons-material/Mic'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import DescriptionIcon from '@mui/icons-material/Description'
import { useStyles } from './style'

const TipsCard = ({ tip, onClick }) => {
  const classes = useStyles()
  const { title, type, image } = tip

  return (
    <Paper elevation={0} className={classes.container} onClick={() => onClick(tip)}>
      <div className={classes.cover}>
        <img src={image} height='100%' width='100%' style={{ objectFit: 'fill' }} alt={`${title} tip`} />
      </div>
      <div className={classes.content}>
        <div className={classes.titleWrapper}>
          <Typography variant='subtitle1' className={classes.title}>{title}</Typography>
        </div>
        <Divider />
        <div className={classes.footer}>
          <Button
            classes={{ startIcon: classes.buttonIcon }}
            onClick={() => onClick(tip)}
            className={classes.button}
            startIcon={
              type === 'video'
                ? <VideoLibraryIcon />
                : type === 'audio'
                  ? <MicIcon />
                  : <DescriptionIcon />
            }
          >
            {type}
          </Button>
          <Typography onClick={() => onClick(tip)} className={classes.text}>Read more</Typography>
        </div>
      </div>
    </Paper>
  )
}

export default TipsCard
