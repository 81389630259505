import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  undercaseButton: {
    textTransform: 'none',
    height: '55px'
  },
  inviteListWrapper: {
    maxHeight: '200px',
    overflow: 'auto'
  },
  inviteList: {
    margin: theme.spacing(2, 0, 2, 0)
  },
  inviteListItem: {
    borderTop: '1px solid #e8e8e8',
    borderLeft: '1px solid #e8e8e8',
    borderRight: '1px solid #e8e8e8',
    '&:first-child': {
      borderRadius: '10px 10px 0 0'
    },
    '&:last-child': {
      borderBottom: '1px solid #e8e8e8',
      borderRadius: '0 0 10px 10px'
    }
  },
  closeButton: {
    position: 'absolute', right: 10, top: 10
  },
  participantContainer: {
    width: '100%',
    alignItems: 'center'
  },
  participantTextField: {
    width: '86%'
  },
  circularProgress: {
    marginLeft: '5%'
  }
}))
