import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex-root',
    '& H3': {
      maxWidth: '80%'
    },
    '& p': {
      margin: '12px 0'
    }
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  }
}))
