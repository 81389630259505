import React from 'react'
import { useSelector } from 'react-redux'
import { surveySlice, userSlice } from '../../reducers'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { Typography, Radio, FormControlLabel, RadioGroup } from '@mui/material'
import { useStyles } from './style'

const DriverScore = ({ handleSend, initialValue }) => {
  const { variabilityRoot, radioLabel, radioRoot, radioRootLabel } = useStyles()
  const [value, setValue] = React.useState(initialValue || null)

  const handleChange = (event) => {
    const value = parseInt(event.target.value)
    setValue(value)
    handleSend(value)
  }

  return (
    <div className={variabilityRoot}>
      <RadioGroup name='variability' value={value} onChange={handleChange} row>
        <FormControlLabel
          className={radioLabel}
          classes={{ label: radioRootLabel }}
          value={1}
          label='Completely disagree'
          labelPlacement='bottom'
          control={<Radio classes={{ checked: initialValue ? radioRoot : null }} />}
        />
        <FormControlLabel
          className={radioLabel}
          classes={{ label: radioRootLabel }}
          value={2}
          label='Strongly disagree'
          labelPlacement='bottom'
          control={<Radio classes={{ checked: initialValue ? radioRoot : null }} />}
        />
        <FormControlLabel
          className={radioLabel}
          classes={{ label: radioRootLabel }}
          value={3}
          label='Disagree a bit'
          labelPlacement='bottom'
          control={<Radio classes={{ checked: initialValue ? radioRoot : null }} />}
        />
        <FormControlLabel
          className={radioLabel}
          classes={{ label: radioRootLabel }}
          value={4}
          label='Agree a bit'
          labelPlacement='bottom'
          control={<Radio classes={{ checked: initialValue ? radioRoot : null }} />}
        />
        <FormControlLabel
          className={radioLabel}
          classes={{ label: radioRootLabel }}
          value={5}
          label='Strongly agree'
          labelPlacement='bottom'
          control={<Radio classes={{ checked: initialValue ? radioRoot : null }} />}
        />
        <FormControlLabel
          className={radioLabel}
          classes={{ label: radioRootLabel }}
          value={6}
          label='Completely agree'
          labelPlacement='bottom'
          control={<Radio classes={{ checked: initialValue ? radioRoot : null }} />}
        />
      </RadioGroup>
    </div>
  )
}

const DriverQuestion = ({ question, answer }) => {
  const { root, textStyle } = useStyles()

  const [saveAnswerBurst] = useCorvusEventList([surveySlice.eventBus.saveAnswer])
  const user = useSelector(userSlice.selectors.selectUser)
  const initialScore = answer !== undefined ? answer.driverScore : null

  const [value, setValue] = React.useState(initialScore)

  const handleSave = (newValue) => {
    setValue(newValue)

    const answer = {
      question: question,
      cycle: `${(user.currentCycle || 1)}`,
      variability: 0,
      driverScore: newValue
    }
    saveAnswerBurst.trigger({ answer, ssoId: user.ssoId, questionnaireType: 'resilience' })
  }

  return (
    <div className={root}>
      <Typography className={textStyle} variant='body2'>{question.id}. {question.text}*</Typography>
      <DriverScore handleSend={handleSave} initialValue={value} />
    </div>
  )
}

export default DriverQuestion
