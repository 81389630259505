import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0.83)',
      height: '100%'
    }
  }
}))
