import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

export const client = createSlice({
  name: reducerName,
  initialState: {
    clientList: [],
    info: {}
  },
  reducers: {
    setClientList: (state, { payload }) => {
      state.clientList = payload
    },
    addLoadingToList: (state) => {
      state.clientList.push({ loading: true })
    },
    addClientToList: (state, { payload }) => {
      const newClientList = state.clientList.filter(i => !i.loading)
      newClientList.push(payload)
      state.clientList = newClientList
    },
    setClientInfo: (state, { payload }) => {
      if (payload) {
        state.info = payload
      }
    }
  }
})

export const {
  setClientList,
  addClientToList,
  addLoadingToList,
  setClientInfo
} = client.actions

// Reducer
export default client.reducer
