import React from 'react'
import { Grid } from '@mui/material'
import { useStyles } from './style'

const ScoreQuestionsHeader = ({ average, range }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container alignItems='center' spacing={4}>
        <Grid item>Average: <span className={classes.highlightText}>{average}</span></Grid>
        <Grid item>Range: <span className={classes.highlightText}>{range}</span></Grid>
      </Grid>
    </div>

  )
}

export default ScoreQuestionsHeader
