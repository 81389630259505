import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    color: '#fff'
  },
  nav: {
    flex: 'auto'
  },
  appBar: {
    backgroundColor: palette.background.default,
    alignItems: 'center',
    paddingTop: 10
    // borderBottom: `1px solid ${theme.palette.divider}`
  },
  box: {
    alignItems: 'center'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '90vw'
  },
  link: {
    display: 'inline-block',
    cursor: 'pointer',
    margin: spacing(2, 2.5),
    textTransform: 'none',
    color: palette.button.link,
    borderBottom: `4px solid ${palette.button.color}`,
    '&:hover': {
      color: 'rgba(37, 40, 43, 1)',
      textDecoration: 'none',
      borderBottom: `4px solid ${palette.primary.main}`
    }
  }
}))
