import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    width: '100%'
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    color: theme.palette.secondary.dark
  },
  title: {
    fontSize: '15px'
  }
}))
