import React, { useEffect } from 'react'
import { Button, Dialog, Grid, IconButton, Typography } from '@mui/material'
import { useStyles } from './style'
import { ReButton } from '../..'
import { useBackdropContext } from '../../../contexts/backdrop'
import { Close } from '@mui/icons-material'

export default function AfterSelfAssessmentModal({ next, cancelOnBoarding }) {
  const classes = useStyles()
  const backdrop = useBackdropContext()

  useEffect(() => {
    backdrop.setOpen(true)
    return () => backdrop.setOpen(false)
  }, [backdrop])
  return (
    <Dialog
      open
      classes={{ paperWidthSm: classes.modal }}
      spacing={3}
      hideBackdrop
    >
      <Grid
        container
        justifyContent='center'
        direction='column'
        flexWrap='nowrap'
        className={classes.root}
      >
        <Grid style={{ position: 'absolute', top: 20, right: 20 }}>
          <IconButton onClick={cancelOnBoarding} color='primary'><Close style={{ fontSize: '30px' }} /></IconButton>
        </Grid>
        <Grid item xs={12}>
          <img
            src='https://static.resilienceengine.live/img/onboarding-after-self-assessment-v2.svg'
            alt='Additional User Settings Congratulations'
          />
          <Typography variant='h3' className={classes.title}>
            Fantastic! You have taken your first step
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom={3}>
          <Typography component='p' variant='body3'>
            By completing your self-assessment, you are able to see your current level of resilience, and you are ready for the next steps. Continue with this guide to fully understand the dashboard or simply explore each feature by yourself. You can access these instructions at any time by clicking in the help icon located at the top menu.
          </Typography>
        </Grid>
        <Grid item display='flex' justifyContent='space-around'>
          <ReButton name='Guide me through' dataTestId='close_congratulations_modal' action={next} />
          <Button className={classes.laterButton} color='secondary' variant='text' dataTestId='close_congratulations_modal' onClick={cancelOnBoarding}>
            I'll explore by my self
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}
