import name from './name'

const selectSliceData = state => state[name]

const selectTrackerResponses = state => selectSliceData(state).responses
const selectTrackerSteps = state => selectSliceData(state).steps
const selectShowTracker = state => selectSliceData(state).showTracker

export {
  selectTrackerResponses,
  selectTrackerSteps,
  selectShowTracker
}
