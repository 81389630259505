import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '940px'
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  button: {
    color: theme.palette.secondary.dark,
    fill: theme.palette.secondary.dark
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '54px'
  },
  previousButton: {
    color: theme.palette.button.default,
    border: '2px solid #465270',
    textTransform: 'none',
    fontWeight: 700,
    fontSize: 14,
    minWidth: '145px',
    padding: '8px 28px',
    '&:hover': {
      border: '2px solid #465270'
    }
  },
  footerLink: {
    marginRight: '16px'
  },
  footerButton: {
    marginLeft: '16px'
  },
  title: {
    margin: '32px 0 18px'
  },
  description: {
    marginBottom: '16px',
    fontSize: '16px',
    fontWeight: 400
  },
  empDescription: {
    fontSize: '16px',
    fontWeight: 700
  }
}))
