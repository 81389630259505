import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    color: '#fff'
  },
  appToolbar: {
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'none'
  },
  fullAppToolbar: {
    width: '100%',
    backgroundColor: '#fff',
    minHeight: '88px',
    display: 'flex',
    justifyContent: 'center'
  },
  toolbarOpts: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(2)
    }
  },
  header: {
    color: theme.palette.secondary.dark
  },
  backButton: {
    color: theme.palette.secondary.dark,
    marginRight: '70px',
    padding: '10px'
  },
  invisible: {
    color: '#FFF'
  },
  noHover: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  marginBorderRight: {
    paddingRight: theme.spacing(2),
    borderRight: '2px solid #DBDDE0'
  }
}))
