import { CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Controller, useFormContext } from 'react-hook-form'
import { useStyles } from './style'
import { ReButton } from '../../../../components'
import { NewCohortContext } from '..'
import { cohortSlice, userSlice } from '../../../../reducers'

export default function StepOne () {
  const classes = useStyles()
  const { cohortWasCreated, featureListWasRequested, licenseListWasRequested, cohortInfoWasRequested, setStep } = useContext(NewCohortContext)
  const { control, setValue, getValues, formState: { errors, touchedFields }, trigger } = useFormContext()
  const licenseList = useSelector(cohortSlice.selectors.selectLicenseList)
  const { id: tenantKey } = useSelector(userSlice.selectors.selectedView)

  const hasErrors = () => {
    let invalid = false
    invalid = !touchedFields.cohortName ||
      !touchedFields.licenseId ||
      Object.keys(errors).some(key => ['cohortName', 'licenseId'].includes(key))
    if (invalid) {
      trigger()
      alert('Cohort name and license are required')
    }
    return invalid
  }

  const getSelectedLicenseDates = () => {
    const licenseId = getValues('licenseId')
    if (!licenseId.length) { return null }
    const license = licenseList.find(l => l.sk === licenseId)
    return <Typography variant='caption'>Starts: {moment(license.startDate).format('DD/MM/YYYY')} - Ends: {moment(license.endDate).format('DD/MM/YYYY')}</Typography>
  }

  const onSubmit = async () => {
    try {
      if (!hasErrors()) {
        featureListWasRequested.trigger({ planId: getValues('licenseId') })
        const result = await cohortWasCreated.promise({ licenseId: getValues('licenseId'), cohortName: getValues('cohortName'), tenantKey })
        const cohortId = result.sk
        setValue('cohortId', cohortId)
        setStep(1)
      }
    } catch (err) {
      alert(err.message)
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant='caption' className={classes.description}>Name the cohort and select a license</Typography>
      <Controller
        name='cohortName'
        control={control}
        rules={{ required: true }}
        render={({ field }) =>
          <TextField
            inputProps={{ 'data-test': 'new-cohort-basic-input-name' }}
            disabled={!!getValues('cohortId')}
            error={!!errors.cohortName}
            {...field}
            className={classes.input}
            variant='outlined'
            label='Cohort name'
          />}
      />
      <Controller
        name='licenseId'
        control={control}
        rules={{ required: true }}
        render={({ field }) =>
          <FormControl>
            <InputLabel>Select a license</InputLabel>
            <Select
              inputProps={{ 'data-test': 'new-cohort-basic-select-license' }}
              error={!!errors.licenseId} disabled={licenseListWasRequested.isWorking || !!getValues('cohortId')} {...field}
              variant='outlined' label='Select a license'
            >
              <MenuItem value=''>None</MenuItem>
              {
                licenseList.map((license) => (
                  <MenuItem key={license.sk} value={license.sk}>
                    {license.name} - {license.seats} seats
                  </MenuItem>
                ))
              }
            </Select>
            {licenseListWasRequested.isWorking && <CircularProgress className={classes.licenseLoader} />}
            {!licenseListWasRequested.isWorking && !cohortInfoWasRequested.event.isWorking && getSelectedLicenseDates()}
          </FormControl>}
      />

      <ReButton
        dataTestId='new-cohort-basic-button-next'
        className={classes.nextButton}
        name='Next' size='small'
        loading={cohortWasCreated.event.isWorking}
        disabled={!!getValues('cohortId')}
        action={onSubmit}
      />
    </div>
  )
}
