import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Settings } from '@mui/icons-material'
import { ButtonBase, Fade, Typography } from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import TrackerQuestion from '../../tracker-question'
import ReButton from '../../re-button'
import { trackerSlice, userSlice } from '../../../reducers'

export const TrackerStep = ({ step, handleNextButton }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLastStep = useMemo(() => step.type === 'Notes', [step.type])
  const trackerAnswers = useSelector(userSlice.selectors.selectTrackerAnswers)
  const [answers, setAnswers] = useState(() => {
    const result = []
    step.questions.forEach(q => {
      const hasAnswer = trackerAnswers.find(a => a.question.id === q.id)
      if (hasAnswer) {
        result.push({ question: hasAnswer.question, answer: hasAnswer.answer, note: hasAnswer.note })
      }
    })
    return result
  })
  const pushAnswer = useCallback(newAnswer => {
    setAnswers(answers => {
      const result = [...answers]
      const index = result.findIndex(a => a.question.id === newAnswer.question.id)
      if (index === -1) {
        result.push(newAnswer)
      } else {
        result[index] = newAnswer
      }
      return result
    })
  }, [])

  const setShowTracker = useCallback(value => {
    dispatch(trackerSlice.actions.setShowTracker(value))
  }, [dispatch])

  const [saveTrackerAnswer] = useCorvusEventList([trackerSlice.eventBus.saveTrackerAnswer((info) => {
    if (info?.trackerAnswers?.length && info.trackerAnswers[0].note) {
      dispatch(userSlice.actions.pushUserNotes(info.trackerAnswers[0]))
    }
  })])

  const onNext = useCallback(() => {
    saveTrackerAnswer.trigger({ answers, type: step.type, utcOffset: moment().utcOffset() })
    if (isLastStep) {
      setShowTracker(false)
    }
    else {
      handleNextButton()
    }
  }, [saveTrackerAnswer, answers, handleNextButton, isLastStep, setShowTracker, step])

  return (
    <Fade in>
      <Box mb={3} mt={3} display='flex' flexDirection='column' justifyContent='space-between' flex={1}>
        <Box id='mid' display='flex'>
          <Box display='flex' alignItems='center'>
            <img src={step.cover} width='90%' height='90%' alt='Track resilience' />
          </Box>
          <Box display='flex' flexDirection='column' gap={5} flex={1} px={7} justifyContent='center'>
            {step.questions.map(question => (
              <Box key={question.name} mb={3}>
                <TrackerQuestion
                  defaultValue={answers.find(a => a.question.id === question.id) ?? {}}
                  pushAnswer={pushAnswer}
                  question={question}
                  type={step.type}
                  optional={step.optional}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box id='bottom' display='flex' justifyContent='space-between' alignItems='center'>
          <ButtonBase sx={{ borderRadius: '20px' }}>
            <Box display='flex' gap={1} p={1} onClick={() => { setShowTracker(false); navigate('profile', { state: { open: ['tracker'] } }) }}>
              <Settings color='secondary' />
              <Typography color='secondary'>Change my preferences</Typography>
            </Box>
          </ButtonBase>
          <Box display='flex' gap={5} justifyContent='space-between' alignItems='center'>
            <ButtonBase sx={{ borderRadius: '20px' }} onClick={() => setShowTracker(false)}>
              <Box display='flex' gap={0.5} p={1}>
                <Typography color='#2F80ED'>Skip for now</Typography>
                <Typography> and do it later</Typography>
              </Box>
            </ButtonBase>
            <ReButton name={isLastStep ? 'Finish' : 'Next'} action={onNext} disabled={step.type !== 'Notes' && answers.length !== step.questions.length} />
          </Box>
        </Box>
      </Box>
    </Fade>
  )
}