import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
  },
  toggleButtonGroup: {
    '& .MuiToggleButton-root': {
      color: theme.palette.secondary.main,
      height: '32px'
    },
    '& .Mui-selected, & .Mui-selected:hover': {
      backgroundColor: theme.palette.secondary.dark,
      color: 'white'
    }
  }
}))
