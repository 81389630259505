import { useCallback, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import { Auth } from 'aws-amplify'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import useNavigate from '../../helpers/useNavigation'
import { useStyles } from './style'
import { Alert } from '@mui/material'
import { useLocation } from 'react-router-dom'
import useInput from '../../utils/useInput'
import { useSelector } from 'react-redux'
import clientConfig from '../../reducers/clientConfig'
import { Forbidden } from '../index'

const ResetPassword = () => {
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState({ error: false, message: '' })
  const navigate = useNavigate()
  const classes = useStyles()
  const { state } = useLocation()
  const email = state?.email
  const { value: code, bind: bindCode } = useInput('', { code: true })
  const { value: password, bind: bindPassword } = useInput('')
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput('')
  const { identityProviders } = useSelector(clientConfig.selectors.selectClientConfig)

  const handleResetPassword = async e => {
    e.preventDefault()
    setLoading(true)

    if (password !== confirmPassword) {
      setInfo({ error: true, message: 'Passwords do not match', severity: 'error' })
      setLoading(false)
      return
    }
    try {
      await Auth.forgotPasswordSubmit(email, code, password)
      setInfo({ error: false, message: 'The password was changed successfully.' })
      alert('The password was changed successfully.')
      navigate('/signin', { state })
    } catch (error) {
      setInfo({ error: true, message: error.message })
    }
    setLoading(false)
  }
  const sendCode = useCallback(async () => {
    try {
      await Auth.forgotPassword(email)
    } catch (error) {
    }
  }, [email])

  const isEmptyEmail = !state?.email || state?.email.length === 0
  const isCognitoProvider = identityProviders?.find(provider => provider.toLowerCase() === 'cognito') && identityProviders.length === 1
  const invalidPage = isEmptyEmail || !isCognitoProvider

  useEffect(() => {
    if (!invalidPage) {
      sendCode()
    }
  }, [invalidPage, sendCode])

  return (
    invalidPage ? <Forbidden /> : (
      <div className={classes.container}>
        <img className={classes.logo} src='/img/logo-v2.svg' alt='Resilience Engine Logo' />
        <form
          className={classes.form}
          onSubmit={handleResetPassword}
        >
          <h1 style={{ fontSize: '22px', fontWeight: 800 }}>
            {' '}
            Password recovering
          </h1>
          <div>
            <p>If you have an account with {email?.replace(/(.).+@(.).+/, '$1***@$2***')} email, check your inbox, we have sent a password reset code. Enter it below to reset your password.</p>
          </div>
          <TextField className={classes.field} label='Verification Code' {...bindCode} />
          <TextField className={classes.field} label='Password' type='password' {...bindPassword} />
          <TextField
            className={classes.field}
            label='Confirm Password'
            type='password'
            {...bindConfirmPassword}
          />
          {info.message.length > 0 && <Alert severity={info.error ? 'error' : 'success'}>{info.message}</Alert>}
          <Button
            className={classes.button}
            variant='contained'
            size='large'
            type='submit'
            disabled={loading}
          >
            {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
            Change Password
          </Button>
        </form>
      </div>
    )
  )
}
export default ResetPassword
