import * as React from 'react'
import Grid from '@mui/material/Grid'
import { ViewTeamContext } from '..'
import { Avatar, Box, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { clientSlice } from '../../../../reducers'
import { AccountCircle, Add, Delete, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { useStyles } from '../style'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'

export default () => {
  const { members, fetchNext, fetchPrevious, addToTeam, genericLoading, keyList, previousKeyList } = React.useContext(ViewTeamContext)
  const classes = useStyles()
  const [email, setEmail] = React.useState('')

  return (
    <Grid display='flex' flexDirection='column' marginTop={3}>
      <Box container justifyContent='space-between' alignItems='flex-start' width={600}>
        <Box mb={4}>
          <TextField onChange={e => setEmail(e.target.value)} label='Add Member (Email)' fullWidth InputProps={{
            endAdornment: <IconButton disabled={genericLoading} onClick={() => addToTeam(email)} ><Add /></IconButton>
          }} />
        </Box>
        <Box>
          <Typography variant='h5'>Member List</Typography>
        </Box>
        <Box display='flex' gap={4}>
          <Box display='flex' alignItems='center' gap={1} >
            <div className={classes.yellowCircle} />
            <Typography>Pending</Typography></Box>
          <Box display='flex' alignItems='center' gap={1} >
            <div className={classes.greenCircle} />
            <Typography>Account created</Typography></Box>
        </Box>
        <Box>
          <List>
            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} divider>
            </ListItem>
            {members.map((m, idx) => (
              <MemberItem {...m} key={idx} />
            ))}
          </List>
        </Box>
        <Box display='flex' gap={2} alignItems='center' justifyContent='center'>
          <IconButton disabled={!previousKeyList || genericLoading} onClick={fetchPrevious}><KeyboardArrowLeft /></IconButton>
          {genericLoading ? <CircularProgress size={16} /> : <Typography>{keyList.length - 1}</Typography>}
          <IconButton disabled={genericLoading} onClick={fetchNext} ><KeyboardArrowRight /></IconButton>
        </Box>
      </Box>
    </Grid >
  )
}

const MemberItem = ({ user, teamInfo }) => {
  const { removeFromTeam, teamId, genericLoading } = React.useContext(ViewTeamContext)
  const classes = useStyles()
  const { name, personalSettings } = user || {}
  const email = React.useMemo(() => user?.email ?? teamInfo.email, [user, teamInfo])
  const { isPending } = teamInfo
  const { hierarchyField } = useSelector(clientSlice.selectors.selectClientInfo)
  const role = hierarchyField?.fieldOptions?.find(f => f.key === personalSettings?.hierarchyField)?.name ?? null

  const [localProfilePic, setLocalProfilePic] = React.useState(null)
  const [getProfilePic] = useCorvusEventList([{
    eventName: 'Profile Picture Url For User Was Requested',
    onSuccess: res => {
      if (res?.url) setLocalProfilePic(res.url)
    }
  }])

  React.useEffect(() => {
    setLocalProfilePic(null)
    if (user) getProfilePic.trigger({ tenantKey: user.clientId, ssoId: user.ssoId })
  }, [getProfilePic, user])

  return (
    <ListItem
      divider
      secondaryAction={
        <Box display='flex' alignItems='center' gap={2}>
          <div className={isPending ? classes.yellowCircle : classes.greenCircle} />
          <IconButton disabled={genericLoading} edge="end" aria-label="delete">
            <Delete onClick={() => removeFromTeam.trigger({ teamId, userToDelete: { user, teamInfo } })} />
          </IconButton>
        </Box>
      }
    >
      <ListItemAvatar>
        <Avatar>
          {localProfilePic ? <img className={classes.avatar} src={localProfilePic} alt='user profile pic' /> : <AccountCircle />}
        </Avatar>
      </ListItemAvatar>
      <Box dispaly='flex' flexDirection='column' alignItems='flex-start'>
        <ListItemText>{email} {role ? ` - ${role}` : ''}</ListItemText>
        {name?.length && <ListItemText><b>Name:</b> {name}</ListItemText>}
      </Box>
    </ListItem>
  )
}