import { useState } from "react"

const useInput = (initialValue, options = { email: false, code: false }) => {
  const [value, setValue] = useState(initialValue)

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: (event) => {
        if (options.email) {
          setValue(event.target.value.toLowerCase())
        } else if (options.code) {
          if (!isNaN(Number(event.target.value)) && event.target.value.length <= 6) {
            setValue(event.target.value)
          }
        } else {
          setValue(event.target.value)
        }
      },
      required: true
    }
  }
}
export default useInput
