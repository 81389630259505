import React from 'react'
import ReLoading from '../re-loading'

const ReLoadingAuth = () => {
  return (
    <ReLoading message='Session expired. Returning to login page' />
  )
}

export default ReLoadingAuth
