import { defineAbility, Ability, AbilityBuilder } from '@casl/ability'

// todo add feature consts
export default defineAbility((can, cannot) => {
  can('view', 'profile')
  cannot('view', 'OrgView')
  cannot('view', 'Cycle')
})

export const updateAbility = (ability, userRole) => {
  const { can, rules } = new AbilityBuilder(Ability)
  if (userRole?.role?.includes('orgadmin')) {
    can('view', 'OrgView')
  }
  if (userRole?.role?.includes('superadmin')) {
    can('view', 'superAdminView')
  }
  if (userRole.features?.length > 0) {
    userRole.features.forEach(feat => {
      can('view', feat)
    })
  }

  ability.update(rules)
}
