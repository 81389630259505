import React from 'react'
import { AppToolbar, LeftDrawer } from '../../components'
import { Outlet, useLocation } from 'react-router-dom'
import { useStyles } from './style'
import InitialOnboarding from '../../components/initial-onboarding'
import { DashboardContextProvider } from '../../hooks/useDashboardContext'

const PrimaryTemplate = () => {
  const { state } = useLocation()
  const classes = useStyles()
  return (
    <DashboardContextProvider>
      <div className={classes.root}>
        <AppToolbar />
        <LeftDrawer />
        <main className={classes.main}>
          <Outlet />
        </main>
        <InitialOnboarding state={state} />
      </div>
    </DashboardContextProvider>
  )
}

export default PrimaryTemplate
