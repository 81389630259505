import React from 'react'
import { List } from '@mui/material'
import MenuItem from './menu-item'
import { useStyles } from './style'

const ReMenu = ({ menu }) => {
  const classes = useStyles()

  return (
    <List component='nav' className={classes.appMenu}>
      {menu.map((item, index) => (
        <MenuItem {...item} key={index} />
      ))}
    </List>
  )
}

export default ReMenu
