import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Add, ArrowLeft, ArrowRight, MoreVert } from '@mui/icons-material'
import { Menu, MenuItem, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Chip } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { CustomOverlay, ReButton, Empty } from '../../components'
import { useStyles } from './style'
import moment from 'moment'
import { Box } from '@mui/system'
import InviteModal from './invite-modal'
import { useSelector } from 'react-redux'
import { userSlice } from '../../reducers'

const renderAdminRow = (row) => {
  if (row.admin?.email) {
    return row.admin.email
  }
  if (row.admin?.name) {
    return row.admin.name
  }
  return row.adminUserId
}

const CohortHome = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [localCohorts, setLocalCohorts] = React.useState([])
  const [lastKey, setLastKey] = React.useState(null)
  const [currentPage, setCurrentPage] = React.useState(-1)
  const { id: tenantKey } = useSelector(userSlice.selectors.selectedView)
  const [cohortListWasRequested] = useCorvusEventList([{
    eventName: 'Cohort List Was Requested',
    onSuccess: (result) => {
      setLocalCohorts(current => current.concat([result.cohorts]))
      setLastKey(result.lastKey)
      setCurrentPage(current => current + 1)
    }
  }])

  useEventsOnViewLoad(() => {
    if (localCohorts.length === 0) {
      cohortListWasRequested.trigger({ tenantKey })
    }
  }, [cohortListWasRequested])

  const getNextPage = () => {
    if (localCohorts.length <= currentPage + 1) {
      cohortListWasRequested.trigger({ from: lastKey, tenantKey })
    } else {
      setCurrentPage(current => current + 1)
    }
  }
  const getPreviousPage = () => {
    setCurrentPage(current => current - 1)
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant='h3'>Cohorts</Typography>
        <ReButton name='New cohort' rounded action={() => navigate('new')} dataTestId='new_cohort_btn' icon={<Add />} />
      </div>
      <CustomOverlay text='' active={cohortListWasRequested.isWorking} spinner={false}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table' className={classes.tableWrapper}>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>NAME</TableCell>
                <TableCell>ADMIN</TableCell>
                <TableCell>LICENSE</TableCell>
                <TableCell>CREATED</TableCell>
                <TableCell>STATUS</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPage !== -1 && localCohorts[currentPage].map((row) => (
                <TableRow key={row.name}>
                  <TableCell scope='row'>{row.name}</TableCell>
                  <TableCell scope='row'>{renderAdminRow(row)}</TableCell>
                  <TableCell>{row.licenseId}</TableCell>
                  <TableCell>{moment(row.createdAt).format('DD/MM/YYYY - HH:mm')}</TableCell>
                  <TableCell>
                    <Chip
                      label={row.status}
                      size='small'
                      className={row.status === 'active' ? classes.chipSuccess : classes.chipWarning}
                    />
                  </TableCell>
                  <MoreOptionsCell row={row} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {currentPage !== -1 && localCohorts[currentPage].length === 0 && <Empty message='There are no cohorts to display' />}
          <Box display='flex' alignItems='center' justifyContent='center'>
            <IconButton disabled={currentPage === 0 || cohortListWasRequested.isWorking} onClick={getPreviousPage}><ArrowLeft /></IconButton>
            {cohortListWasRequested.isWorking ? <CircularProgress size={15} color='secondary' /> : <Typography>{currentPage + 1}</Typography>}
            <IconButton disabled={(!lastKey && currentPage + 1 === localCohorts.length) || cohortListWasRequested.isWorking} onClick={getNextPage}><ArrowRight /></IconButton>
          </Box>
        </TableContainer>
      </CustomOverlay>
    </div>
  )
}

const MoreOptionsCell = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate()
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuList = useMemo(() => {
    if (row.status === 'active') {
      return [
        <MenuItem key={0} onClick={() => { handleClose(); navigate(`edit/${encodeURIComponent(row.sk)}`) }}>Edit</MenuItem>,
        <MenuItem key={1} onClick={() => { handleClose(); setModalOpen(true) }}>Invite participants</MenuItem>
        // <MenuItem key={2} onClick={() => { handleClose() }}>Disable</MenuItem>
      ]
    }
    if (row.status === 'pending') {
      return [
        <MenuItem key={0} onClick={() => { handleClose(); navigate(`new/${encodeURIComponent(row.sk)}`) }}>Continue</MenuItem>
        // <MenuItem key={1} onClick={() => { handleClose() }}>Delete</MenuItem>
      ]
    }
    return []
  }, [row.status, row.sk, navigate])

  return (
    <TableCell>
      <InviteModal open={modalOpen} handleClose={() => setModalOpen(false)} cohortId={row.sk} cohort={row.name} invites={row.invites} licenseId={row.licenseId} />
      <IconButton>
        <MoreVert onClick={handleClick} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuList}
      </Menu>
    </TableCell>
  )
}

export default CohortHome
