import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  container: {
    padding: '23px',
    height: '100%',
    boxShadow: '0px 0px 0px rgba(16, 30, 115, 0.06)'
  },
  toolpathwayTitle: {
    color: theme.palette.text.greyDark,
    fontWeight: 'bold',
    marginBottom: '5px'
  },
  popover: {
    backgroundColor: 'grey',
    padding: '16px',
    color: 'white'
  },
  popoverIcon: {
    width: 19,
    height: 19,
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(1)
  },
  infoIconWording: {
    fontSize: '11px'
  }
}))
