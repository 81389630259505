import { useStyles } from './style'
const NotFound = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <img src='/img/logo-v2.svg' alt='Resilience Engine Logo' className={classes.logo} />
      <div>
        <img src='/img/404-v2.svg' alt='404 Not Found' />
        <h3>404 Error - Not Found</h3>
        <p>The requested URL was not found on this server. Make sure you have the correct URL.</p>
      </div>
    </div >
  );
}

export default NotFound