import React, { useState } from 'react'
import { Grid, Paper, TableContainer, Table, TableRow, TableHead, TableCell, TableBody, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Empty, Can } from '../../components'
import CycleModal from './modal'
import { userSlice, adminSlice } from '../../reducers'
import { useCorvusEventList, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useSelector } from 'react-redux'
import ability from '../../config/ability'
import { useStyles } from './style'
import moment from 'moment'
import Forbidden from '../403'

const Cycle = () => {
  const classes = useStyles()

  const [modalOpen, setModalOpen] = useState(false)
  const user = useSelector(userSlice.selectors.selectUser)
  const cycles = useSelector(adminSlice.selectors.selectCycles)
  const isSuperAdminOrOrgView = ability.can('view', 'superAdminView') || ability.can('view', 'OrgView')
  const [getCyclesBurst] = useCorvusEventList([
    adminSlice.eventBus.getCycles
  ])

  useEventsOnViewLoad(() => {
    if (isSuperAdminOrOrgView) {
      getCyclesBurst.trigger({ ssoId: user.ssoId })
    }
  }, [isSuperAdminOrOrgView, getCyclesBurst])

  return (
    !isSuperAdminOrOrgView ? <Forbidden />
      : <div className={classes.root}>
        <CycleModal open={modalOpen} handleClose={() => setModalOpen(false)} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
              >
                <Can I='view' a={Cycle}>
                  <Grid item xs={12}>
                    <Empty message="You don't have permission to access this menu" />
                  </Grid>
                </Can>
                {isSuperAdminOrOrgView && !getCyclesBurst.isWorking && !!cycles.length
                  ? (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label='simple table'>
                            <TableHead>
                              <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell align='right'>Client</TableCell>
                                <TableCell align='right'>Start From</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {cycles.map((cycle, idx) => (
                                <TableRow key={idx}>
                                  <TableCell component='th' scope='row'> {cycle.id}
                                  </TableCell>
                                  <TableCell align='right'>{cycle.clientId}</TableCell>
                                  <TableCell align='right'>{moment(cycle.startDate).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant='contained'
                          color='secondary'
                          className={classes.add}
                          endIcon={<AddIcon />}
                          onClick={() => setModalOpen(true)}
                        >
                          Add new cycle
                        </Button>
                      </Grid>
                    </Grid>
                  ) : <Empty />}
              </Grid>
            </Paper>

          </Grid>
        </Grid>
      </div>
  )
}

export default Cycle
