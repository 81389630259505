import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ImageButton } from '../../components'
import {
  ListItem,
  ListItemText,
  Collapse,
  Divider,
  List,
  ListItemAvatar,
  Avatar,
  Typography
} from '@mui/material'
import {
  AccountCircle as ImageIcon,
  ExpandLess,
  ExpandMore
} from '@mui/icons-material'
import { useNavigate, useMatch, useParams } from 'react-router-dom'
import { userSlice } from '../../reducers'
import { useStyles } from './style'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { toolkitSlice } from '../../reducers'

const MenuItem = ({ name, onboardingClass, items = [], ...props }) => {
  const classes = useStyles()
  const { tenantKey } = useParams()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = React.useState(false)
  const match = useMatch(`:tenantKey/dashboard/${props.path}`)
  const subFoldersMatch = useMatch(`:tenantKey/dashboard/${props.path}/:subFolder`)
  const selected = useMemo(() => {
    if (subFoldersMatch && subFoldersMatch.params.subFolder.length) {
      return true
    }
    return !!match
  }, [match, subFoldersMatch])
  const isExpandable = items && items.length > 0
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const dispatcher = useDispatch()
  const [open, setOpen] = React.useState(false)
  const user = useSelector(userSlice.selectors.selectUser)
  const [toolkitWasClicked] = useCorvusEventList([toolkitSlice.eventBus.toolkitWasClicked])

  const handleClick = () => {
    if (props.externalurl) {
      if (props.externalurl?.indexOf('toolkit') >= 0) {
        toolkitWasClicked.trigger()
      }
      window.open(props.externalurl, '_self')
    }
    navigate(props.path || '')
    setOpen(!open)
  }

  const handleItemClick = view => {
    setIsOpen(false)
    dispatcher(userSlice.actions.selectView(view))
    navigate(`/${tenantKey}/dashboard`)
  }

  const orgClick = () => {
    setIsOpen(!isOpen)
  }
  const MenuItemRoot = (
    <ListItem
      button
      classes={{
        selected: classes.menuItemSelected,
        disabled: classes.menuItemDisabled
      }}
      className={`${classes.menuItem} ${onboardingClass || ''}`}
      onClick={!isExpandable ? handleClick : orgClick}
      {...props}
      selected={selected}
    >
      {isExpandable && (
        <>
          <ListItemAvatar>
            <Avatar>
              {selectedView.type === 'individual' ?
                <ImageButton image={user?.profilePic?.url} size='small' /> :
                selectedView.icon ? (
                  <ImageButton image={selectedView.icon} size='small' />
                ) : (
                  <ImageIcon />
                )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography className={classes.childNamePrimary}>
                {selectedView.name}
              </Typography>
            }
            secondary={
              <Typography className={classes.childNameSecondary}>
                {selectedView.secondary}
              </Typography>
            }
          />
        </>
      )}
      {!isExpandable && (
        <ListItemText className={classes.menuItem} primary={name} />
      )}
      {isExpandable && isOpen && <ExpandLess />}
      {isExpandable && !isOpen && <ExpandMore />}
    </ListItem>
  )

  const MenuItemChildren = isOpen ? (
    <Collapse
      in={isOpen}
      timeout='auto'
      unmountOnExit
      className={classes.firstChildMenu}
    >
      {items.map((item, idx) => (
        <span key={idx}>
          {idx === 0 || idx === items.length - 1 ? (
            <>
              <Typography className={classes.childLabel}>
                {item.label}
              </Typography>
              <Divider />
            </>
          ) : (
            <></>
          )}
          <List component='div'>
            <ListItem
              data-test={item['data-test']}
              button
              onClick={() => handleItemClick(item)}
              className={classes.child}
            >
              <ListItemAvatar>
                <Avatar>
                  {item.icon ? (
                    <ImageButton image={item.icon} size='small' />
                  ) : (
                    <ImageIcon />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography className={classes.childNamePrimary}>
                    {item.name}
                  </Typography>
                }
                secondary={
                  <Typography className={classes.childNameSecondary}>
                    {item.secondary}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </span>
      ))}
    </Collapse>
  ) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}

export default MenuItem
