import React from 'react'
import { Grid, Typography, Link, Divider, Card } from '@mui/material'
import { ReButton } from '../../components'
import { useStyles } from './style'
import { TextField } from '@mui/material'
import { validateEmail } from '../../helpers/utils'

const ModalQuestionnaire = ({ title, subtitle, text, nextAction, nextLabel, skipAction, setEmail, email, enableSkipAction = true, customForm, customValidation }) => {
  const classes = useStyles()
  return (
    <Card
      container
      justifyContent='center'
      alignItems='center'
      direction='column'
      rowSpacing={3}
      className={classes.root}
    >
      <Typography variant='h2' className={classes.title}>
        {title}
      </Typography>
      <Grid item xs={12}>
        <Typography variant='h4' className={classes.text}>
          {subtitle}
        </Typography>
        <Typography variant='h4' className={classes.text}>
          {text}
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        direction='column'
        rowSpacing={3}
        className={classes.grid}
      >
        <Grid item xs={12}>
          {setEmail && <TextField
            className={classes.emailTextField}
            value={email}
            onChange={e => setEmail(e.target.value)}
            label='Email'
          />}
          {customForm}
        </Grid>
      </Grid >
      <Divider variant='middle' />
      <Grid item xs={12} className={classes.rowSpacing}>
        <ReButton name={nextLabel} dataTestId='next_step_modal_btn' action={nextAction} disabled={(setEmail && !validateEmail(email)) || customValidation} />
      </Grid>
      {enableSkipAction && <Grid item xs={12}>
        <Typography className={classes.footerLink} variant='subtitle2' color='textPrimary'>
          <Link onClick={skipAction}>Skip for now</Link> and remind me later
        </Typography>
      </Grid>}
    </Card>
  )
}

export default ModalQuestionnaire
