import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useStyles } from './style'

const ReLoading = ({ message }) => {
  const classes = useStyles()

  if (!message) {
    message = 'Loading...'
  }

  return (
    <Grid
      className={classes.root}
      direction='row'
      container
      justifyContent='center'
      alignItems='center'
    >
      <Grid item>
        <img alt='Loading' className={classes.image} src='/img/cog.gif' />
      </Grid>
      <Grid item>
        <Grid
          container
          direction='column'
        >
          <Grid item> <Typography className={classes.text}> {message} </Typography> </Grid>
          <Grid item> <Typography className={classes.text}>If the page does not load after 10 seconds, please refresh</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReLoading
