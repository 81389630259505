import { useTheme } from '@mui/material'
import LoadingOverlay from 'react-loading-overlay'

const CustomOverlay = ({ children, active, text, spinner = true }) => {
  const theme = useTheme()
  return (
    <LoadingOverlay
      active={active}
      spinner={spinner}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(50, 50, 50, 0.15)'
        }),
        spinner: (base) => ({
          ...base,
          '& svg circle': {
            stroke: theme.palette.secondary.main
          }
        }),
        content: (base) => ({
          ...base,
          color: theme.palette.secondary.main
        })
      }}
      fadeSpeed={300}
      text={text !== undefined ? text : 'Updating...'}
    >
      {children}
    </LoadingOverlay>
  )
}

export default CustomOverlay
