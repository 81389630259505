import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    color: '#fff'
  },
  dateRangePicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    border: '1px solid #ECE9F1',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1)
  },
  dateRangeTextField: {
    '& .MuiInputBase-root': {
      height: theme.spacing(4),
      width: '120px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  toolbarOpts: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& > *': {
      marginRight: theme.spacing(2)
    }
  },
  textField: {
    border: '1px solid #E8E8E8',
    borderRadius: 8,
    fontSize: '12px',
    minWidth: '250px',
    color: '#000000',
    '& > input': {
      padding: '10px'
    }
  },
  button: {
    marginLeft: theme.spacing(2),
    border: '2px solid #E8E8E8',
    borderRadius: 8,
    cursor: 'not-allowed;'
  }
}))
