import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    gap: '16px',
    flexWrap: 'nowrap',
    paddingBottom: '8px'
  },
  typeTypo: {
  },
  additionalInfo: {
    color: theme.palette.text.grey,
    gap: '3px'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '37px',
    height: '37px',
    border: '2px solid #E2E2E2',
    borderRadius: '100%',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%'
  },
  imageContainerSelected: {
    border: `2px solid ${theme.palette.primary.dark}`
  },
  titleContainer: {
    gap: '8px'
  },
  title: {
    lineHeight: 1.25,
    textAlign: 'left'
  },
  youAreReadingThis: {
    color: theme.palette.primary.dark,
    fontSize: '12px',
    textDecoration: 'underline'
  },
  bottomTrail: {
    position: 'absolute',
    top: '37px',
    left: '18px',
    width: '2px',
    height: '100%',
    background: '#DDDBE0'
  }
}))
