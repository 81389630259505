import React, { createContext, useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { clientConfigSlice, userSlice } from '../reducers'
import { useEventContext } from './event'
import { useNavigate, useParams } from 'react-router-dom'
import { useSession } from '@emerald-works/react-auth'

export const UserManagementContext = createContext(null)

export const useUserManagementContext = () => {
  return useContext(UserManagementContext)
}

export const UserManagementProvider = ({ children }) => {
  const navigate = useNavigate()
  const session = useSession()
  const hasTriggered = useRef(false)
  const params = useParams()
  const user = useSelector(userSlice.selectors.selectUser)
  const clientConfig = useSelector(clientConfigSlice.selectors.selectClientConfig)
  const events = useEventContext()
  useEffect(() => {
    if (!clientConfig) return
    if (!session?.isAuthenticated) return
    if (hasTriggered.current) return
    if (events.getUser.isWorking || !events.getUser.hasBeenTriggered) return
    if (!user) return
    hasTriggered.current = true

    const hasMissingField = clientConfig.demographicFields ? clientConfig.demographicFields.find(f => !user.personalSettings[f.fieldKey]) : false
    if (hasMissingField) {
      navigate(`/${params.tenantKey}/user-settings`, { replace: true })
    }
  }, [clientConfig, user, session, events.getUser, events.getUser.isWorking, navigate, params])

  return (
    <UserManagementContext.Provider value={{}}>
      {children}
    </UserManagementContext.Provider>
  )
}
