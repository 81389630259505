import React, { useEffect } from 'react'
import { Grid, Typography, Dialog, IconButton } from '@mui/material'
import { useBackdropContext } from '../../../contexts/backdrop'
import { useStyles } from './style'
import { ReButton } from '../..'
import { Close } from '@mui/icons-material'

export default function WelcomeModal({ next, cancelOnBoarding }) {
  const classes = useStyles()
  const backdrop = useBackdropContext()

  useEffect(() => {
    backdrop.setOpen(true)
    return () => backdrop.setOpen(false)
  }, [backdrop])
  return (
    <Dialog
      open
      classes={{ paperWidthSm: classes.modal }}
      spacing={3}
      hideBackdrop
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        direction='column'
        flexWrap='nowrap'
        rowSpacing={3}
        className={classes.root}
      >
        <Grid style={{ position: 'absolute', top: 20, right: 20 }}>
          <IconButton onClick={cancelOnBoarding} color='primary'><Close style={{fontSize: '30px'}} /></IconButton>
        </Grid>
        <Grid item xs={12}>
          <img
            src='https://static.resilienceengine.live/img/onboarding-welcome.svg'
            alt='Additional User Settings Congratulations'
          />
          <Typography variant='h3' className={classes.title}>
            Welcome to the Resilience Dynamic Dashboard®
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom={3}>
          <Typography component='p' variant='body3'>
            Hello, and welcome to the Resilience Dynamic Dashboard®! We are delighted to welcome you on your journey to better resilience and wellbeing. Within this dashboard you will have everything that you need to improve your resilience.
          </Typography>
          <br />
          <Typography component='p' variant='body3'>
            As there are many features within the dashboard, please use these support screens as a place to start, or if you ever feel lost. They will guide you through each feature and your next steps.
          </Typography>
          <br />
          <Typography component='p' variant='body3'>
            If you close the support screens, you can access them again at any point by clicking on the help icon at the top of the screen.
          </Typography>
          <Grid>
            <img src='/img/help-icon.svg' alt='Help icon' />
          </Grid>
          <Typography component='p' variant='body3'>
            Continue to explore your journey through the Resilience Dynamic Dashboard®.
          </Typography>
        </Grid>
        <ReButton name='Start' dataTestId='close_congratulations_modal' action={next} />
      </Grid>
    </Dialog>
  )
}
