import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  leftText: {
    color: '#a0a4a8',
    width: '100%'
  },
  rightText: {
    color: '#a0a4a8',
    margin: '0 4px'
  },
  links: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    color: '#a0a4a8',
    alignItems: 'center'
  }
}))
