import React, { useEffect, useState } from 'react'
import { Dialog, Fade, Grid, IconButton, Typography } from '@mui/material'
import { useStyles } from './style'
import { ReButton } from '../..'
import PopperWrapper from '../popper-wrapper'
import { Close } from '@mui/icons-material'

export default function ResilienceFundamentalsModal({ next, previous, cancelOnBoarding, isOnFeature }) {
  const classes = useStyles()
  const [ref, setRef] = useState(null)
  const [loaded, setLoaded] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      setRef(document.querySelector('.onboarding-fundamentals'))
      setLoaded(true)
    }, 0)
  }, [])

  const Wrapper = ({ children }) => isOnFeature
    ? <Dialog open classes={{ paperWidthSm: classes.modal }} spacing={3}>{children}</Dialog>
    : <PopperWrapper element={ref} placement='right'>{children}</PopperWrapper>

  return (
    <Wrapper>
      <Fade in={loaded} timeout={400}>
        <Grid
          container
          justifyContent='center'
          direction='column'
          flexWrap='nowrap'
          className={classes.root}
        >
          <Grid style={{ position: 'absolute', top: 20, right: 20 }}>
            <IconButton onClick={cancelOnBoarding} color='primary'><Close style={{ fontSize: '30px' }} /></IconButton>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: '280px' }}>
              <img
                src='https://static.resilienceengine.live/img/onboarding-resilience-fundamentals-v2.svg'
                alt='Additional User Settings Congratulations'
              />
            </div>
            <Typography variant='h3' className={classes.title}>
              Resilience Fundamentals
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom={3}>
            <Typography component='p' variant='body3'>
              Now that you have seen your own resilience level through your self-assessment, explore the Resilience Fundamentals. This is a short e-learning journey that will introduce you to the basics of resilience.
            </Typography>
          </Grid>
          {isOnFeature
            ? <Grid item display='flex' justifyContent='center' gap={5}><ReButton fullWidth name='Understood' dataTestId='close_congratulations_modal' action={cancelOnBoarding} /></Grid>
            : <Grid item display='flex' justifyContent='flex-end' gap={5}>
              <ReButton fullWidth name='Previous' dataTestId='close_congratulations_modal' action={previous} />
              <ReButton fullWidth name='Next' dataTestId='close_congratulations_modal' action={next} />
            </Grid>
          }
        </Grid>
      </Fade>
    </Wrapper>
  )
}
