export const userPathwayMapping = {
  General: {
    title: 'General Pathway',
    img: 'General-v2.svg',
    shortDescription: 'This is the general tool pathway, for when your tracking data has been consistent both in amount of tracking, and the actual data that you are entering. This is an opportunity to explore the Resilience Toolkit in more depth, discovering some of the theory behind resilience and being inspired by some of the stories from within the toolkit.'
  },
  'No Tracking': {
    title: 'No Tracking Pathway',
    img: 'No-Tracking-v2.svg',
    shortDescription: 'Hey, we have noticed you haven’t been tracking recently! Not sure how orwhy it’s worth it?'
  },
  'Demand > Capacity': {
    title: 'Demand > Capacity Pathway',
    img: 'Demand-Capacity-v2.svg',
    shortDescription: 'Are you aware that you are consistently trading on your wellbeing to meetyour resilience demand?  If you keep doing this, your resilience levels will become under threat.'
  },
  'Wellbeing Long Term': {
    title: 'Wellbeing Long Term Pathway',
    img: 'Wellbeing-Long-Term-v2.svg',
    shortDescription: 'Watch out, your wellbeing is in danger. The warning bells are ringing, so please act.'
  },
  'Wellbeing Short Term': {
    title: 'Wellbeing Short Term Pathway',
    img: 'Wellbeing-Short-Term-v2.svg',
    shortDescription: 'Woah, what happened to your self-care this week? It went out the window at some point!'
  },
  'Intermittent Tracking': {
    title: 'Intermittent Tracking Pathway',
    img: 'Intermittent-Tracking-v2.svg',
    shortDescription: 'Within the past 4 weeks you have missed at least 1 full week of tracking your resilience & wellbeing using the Resilience Tracker. If the causeof not tracking for that week was down to a holiday or time off from work, remember you can fill in your tracking data retrospectively, using the datefeature at the top left of the Resilience Tracker. Your tool pathway shows a collection of tools that will help you discover why tracking your resilience is important, and what you can help do to boost your resilience day-to-day.'
  },
  'Not Clicking Through to Tools': {
    title: 'Not Clicking Through to Tools Pathway',
    img: 'Not-Clicking-Through-to-Tools-v2.svg',
    shortDescription: 'Over the past two weeks you have not been clicking through to any of the tools in your tool pathways. Maybe you’ve been too busy in work, did you know that busy, busy is one of the barriers to resilience? Your tool pathway shows a collection of tools that will help you discover what the Resilience Toolkit holds, and how you can help boost your resilience day-to-day.'
  },
  Energy: {
    title: 'Energy Pathway',
    img: 'Energy-v2.svg',
    shortDescription: 'Within the past week of tracking your resilience & wellbeing using the Resilience Tracker, your energy has been equal to, or less than 3 consistently. Many things can have an impact on your energy, not just from the workplace. Energy & resilience tend to follow the same trends. Try to identify what lowers your energy, as well as discovering what gives you energy can be so impactful in helping to boost your energy and resilience daily. This tool pathway focuses on tools that will help you to discover more about energy and how you can boost yours.'
  },
  REQuestionnaire: {
    title: 'General Pathway',
    img: 'General.svg',
    shortDescription: 'This is the general tool pathway, for when your tracking data has been consistent both in amount of tracking, and the actual data that you are entering. This is an opportunity to explore the Resilience Toolkit in more depth, discovering some of the theory behind resilience and being inspired by some of the stories from within the toolkit.'
  }
}
