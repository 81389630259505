import { useCallback, useMemo, useState } from 'react'
import { ButtonBase, Grid, Paper, Step, Stepper, Typography } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { MostPopularCard, TipsCard, PathwayCard } from '../../../components'
import { userSlice } from '../../../reducers'
import { useStyles } from './combinationStyles'
import { tips } from '../../../helpers/mocks'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { featureSelector } from '../../../reducers/user/selectors'

const Toolpathway = () => {
  const selectedView = useSelector(userSlice.selectors.selectedView)
  const isIndividualView = (selectedView && selectedView.type === 'individual') || !selectedView
  const classes = useStyles()
  if (!isIndividualView) return null
  return (
    <Grid className={`${classes.toolPathwayGrid} onboarding-toolpathways`} marginY={1}>
      <Grid item xs={8}>
        <PathwayCard />
      </Grid>
      <Grid item xs={4}>
        <MostPopularCard />
      </Grid>
    </Grid>

  )
}

const ToolPathwayExtended = (noFrontFeature) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const navigate = useNavigate()
  const features = useSelector(featureSelector)

  const parsedTips = useMemo(() => {
    const result = tips.filter(tip => {
      if (((tip.feature === 'RESILIENCE_TRACKER') && !features.RESILIENCE_TRACKER)) return false
      if ((tip.feature === 'RESILIENCE_TOOLKIT' && !features.RESILIENCE_TOOLKIT)) return false
      if ((tip.feature === 'RESILIENCE_EVALUATION' && !features.RESILIENCE_EVALUATION)) return false
      return true
    })
    return result
  }, [features])

  const pages = _.chunk(parsedTips, 3)

  const onArrowClick = useCallback((direction) => {
    setActiveStep((activeStep) => {
      const nextActive = activeStep + (direction === 'left' ? -1 : 1)
      if (nextActive < 0) return 0
      if (nextActive >= pages.length) return pages.length - 1
      return nextActive
    })
  }, [pages.length])

  return (
    <Paper elevation={2} className={classes.homePaper}>
      <Grid container flexDirection='column' alignItems='center' padding={2.5} flexWrap='nowrap'>
        <Grid item><Typography>{noFrontFeature ? 'Tips' : 'Help'}</Typography></Grid>
        <div style={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {pages[activeStep].map((tip, idx) => (
              <Step key={idx}>
                <Grid container marginTop={3} justifyContent='space-evenly' flexWrap='nowrap'>
                  <TipsCard tip={tip} onClick={() => navigate(`help/${tip.id}`)} />
                </Grid>
              </Step>
            ))}
          </Stepper>
        </div>
        <Grid item className={classes.dotContainer}>
          <ButtonBase onClick={() => onArrowClick('left')}><KeyboardArrowLeftIcon /></ButtonBase>
          {[...Array(pages.length).keys()].map((_, idx) => (
            <ButtonBase onClick={() => setActiveStep(idx)} key={idx} className={classes.dotWrapper}>
              <div className={activeStep === idx ? classNames(classes.dot, classes.selected) : classes.dot} />
            </ButtonBase>
          ))}
          <ButtonBase onClick={() => onArrowClick('right')}><KeyboardArrowRightIcon /></ButtonBase>
        </Grid>
      </Grid>
    </Paper>
  )
}

export { ToolPathwayExtended }
export default Toolpathway
