import React, { useState } from 'react'
import { Grid, Typography, Link, FormControlLabel, Checkbox } from '@mui/material'
import { ReButton } from '../..'
import { useStyles } from './style'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import { settingsSlice } from '../../../reducers'
import { useSelector } from 'react-redux'

const ModalIntro = ({ nextAction, skipAction }) => {
  const classes = useStyles()
  const [updateUserSettings] = useCorvusEventList([settingsSlice.eventBus.updateUserSettings])
  const userSettings = useSelector(settingsSlice.selectors.selectUserSettings)

  const [hideWelcome, setHideWelcome] = useState(false)

  const handleNext = () => {
    if (hideWelcome) {
      updateUserSettings.trigger({ type: 'trackerOnBoarding', name: 'trackerWelcomeDisabled', value: true })
    }
    if (userSettings?.trackerOnBoarding?.trackerInitialSettingsDisabled) {
      nextAction(1)
      return
    }
    nextAction()
  }

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      spacing={3}
      className={classes.root}
    >
      <Grid>
        <div className={classes.image}>
          <img src='/img/tracker-modal-v2.svg' width='80%' height='80%' alt='Track resilience modal' />
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h3' className={classes.title}>
          Finding how to support and extend your resilience with
        </Typography>
        <Typography className={classes.title} variant='h3' color='primary'>Resilience Tracker</Typography>
        <Typography variant='h3' className={classes.text}>
          That all means you can count on impact. And since that means a higher wellbeing and higher performance, everyone wins.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ReButton name='Next' dataTestId='next_step_modal_btn' action={handleNext} />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <FormControlLabel
              label={<Typography className={classes.footerLink} variant='subtitle2' color='textPrimary'>Don't show this anymore</Typography>}
              control={<Checkbox onChange={() => setHideWelcome(s => !s)} checked={hideWelcome} />}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.footerLink} variant='subtitle2' color='textPrimary'>
              <Link className={classes.trackLaterLink} onClick={skipAction}>Skip for now</Link> and remind me later
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ModalIntro
