import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    borderRadius: '4px',
    flexDirection: 'row',
    height: '120px',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '12px 0'
  },
  scoreRoot: {
    flex: 2,
    height: '100%',
    backgroundColor: '#F6F8FB',
    display: 'flex',
    alignItems: 'center',
    padding: '0 60px'
  },
  variabilityRoot: {
    flex: 0.75,
    justifyContent: 'space-around',
    display: 'flex',
    backgroundColor: 'rgba(70, 82, 112, 0.1)',
    height: '100%',
    alignItems: 'center',
    minWidth: '265px'
  },
  textStyle: {
    flex: 0.75,
    backgroundColor: '#F6F8FB',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 20
  },
  radioLabel: {
    width: '55px',
    textAlign: 'center'
  },
  radioRootLabel: {
    fontSize: 12,
    fontWeight: 400
  },
  radioRoot: {
    color: '#5EC3CD !important'
  },
  markLabel: {
    textAlign: 'center',
    whiteSpace: 'normal',
    width: '75px'
  },
  thumb: {
    backgroundPosition: 'center',
    backgroundImage: 'url(/img/knob-v2.svg)',
    height: 20,
    width: 20,
    backgroundRepeat: 'no-repeat',
    border: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:before': {
      boxShadow: 'none'
    }
  },
  rail: {
    backgroundColor: '#A0A4A8'
  },
  answered: {
    backgroundColor: '#5EC3CD'
  },
  sliderRoot: {
    color: 'rgba(70, 82, 112, 0.1)'
  }
}))
