import { Add, Close } from '@mui/icons-material'
import { Button, CircularProgress, Grid, IconButton, List, ListItem, Modal, TextField, Typography } from '@mui/material'
import { ReButton } from '../../../components'
import { useSelector } from 'react-redux'
import { Box } from '@mui/system'
import { cohortSlice, userSlice } from '../../../reducers'
import { useStyles } from './style'
import React, { useMemo } from 'react'
import { validateEmail } from '../../../helpers/utils'
import { usePromiseEvent } from '../../../hooks'
import AlreadyInvitedUserModal from '../already-invited-user-modal'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
  p: 3
}

const InviteModal = ({ open, handleClose, licenseId, cohortId, invites, setValue, cohort }) => {
  const checkedIfCanInvite = usePromiseEvent('Checked If Can Invite')
  const cohortCoworkerWasBatched = usePromiseEvent('Cohort Coworker Was Batched')
  const cohortWasFinished = usePromiseEvent('Cohort Was Finished')

  const classes = useStyles()
  const licenseList = useSelector(cohortSlice.selectors.selectLicenseList)
  const [chosenLicense] = licenseList.filter(license => license.sk === licenseId)
  const [localInput, setLocalInput] = React.useState('')
  const [localMessage, setLocalMessage] = React.useState('')
  const [localInvites, setLocalInvites] = React.useState([])
  const { id: tenantKey } = useSelector(userSlice.selectors.selectedView)
  const [inviteResult, setInviteResult] = React.useState({})
  const [modal, setModal] = React.useState('invite')

  const moveUser = () => {
    // Send a request to back to move user
    setModal('invite')
    setLocalInvites([...localInvites, localInput])
    setLocalInput('')
  }
  const cancelChange = () => {
    setModal('invite')
    setLocalInput('')
  }

  const addToInviteList = async (e) => {
    if (e.charCode === 13 || e.type === 'click') {
      e.preventDefault()
      if (localInvites.some(item => item === localInput)) {
        return alert('This email is already in the list')
      }
      if (!validateEmail(localInput)) {
        return alert('Please enter a valid email')
      }

      const result = await checkedIfCanInvite.promise({ emails: [localInput], tenantKey })
      setInviteResult(result[0])
      if (result[0].cohort === cohort) return alert(`The ${localInput} user already belongs/was invited to ${cohort}.`)
      if (result[0].error || result[0].exists) return setModal('already_invited')
      setLocalInvites([...localInvites, localInput])
      setLocalInput('')
    }
  }

  const removeFromInviteList = (index) => {
    const newInvites = [...localInvites]
    newInvites.splice(index, 1)
    setLocalInvites(newInvites)
  }

  const send = async () => {
    const { invites: incomingInvites, invalids } = await cohortCoworkerWasBatched.promise({ cohortId, emails: localInvites, message: localMessage, tenantKey })
    if (invalids.length) return alert('Error: ' + invalids[0].reason)
    cohortWasFinished.event.trigger({ cohortId, tenantKey })
    if (setValue) setValue('invites', [...invites, ...incomingInvites.map(i => ({ ...i, hasBeenSent: true }))])
    setLocalInvites([])
    setLocalMessage('')
    setLocalInput('')
    handleClose()
  }

  const onClose = () => {
    setLocalInput('')
    handleClose()
  }

  const onBatchUpload = async (event) => {
    if (!event.target?.files) return
    const result = await (
      new Promise((resolve, reject) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.addEventListener('load', (event) => {
          const result = event.target.result
          resolve(result)
        })
        reader.readAsText(file)
      })
    )
    event.target.value = ''
    const parsedInvites = [...new Set(result.split(',').map(email => email.trim()).filter(email => validateEmail(email)))]
    try {
      const checks = await checkedIfCanInvite.promise({ emails: parsedInvites, tenantKey })
      const { valids, invalids } = checks.reduce((acc, item) => {
        if (item.exists || item.error) acc.invalids.push(item)
        else acc.valids.push(item)
        return acc
      }, { valids: [], invalids: [] })
      if (invalids.length) {
        const message = invalids.map(invite => `${invite.email}: ${invite.reason}`).join('\n')
        alert(`Error: \n${message}`)
      }
      setLocalInvites([...localInvites, ...valids.map(item => item.email)])
    } catch (err) {
      console.log(err)
    }
  }

  const isWorking = useMemo(() => cohortCoworkerWasBatched.event.isWorking || cohortWasFinished.event.isWorking,
    [cohortCoworkerWasBatched.event.isWorking, cohortWasFinished.event.isWorking])

  return (
    <Modal
      open={open}
      onClose={isWorking ? () => null : onClose}
    >
      <Box sx={style}>
        <Grid className={classes.closeButton}>
          <IconButton disabled={isWorking} onClick={onClose}><Close /></IconButton>
        </Grid>
        <Typography variant='h6' color='text.primary'>
          Invite participants
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Invited participants will receive an invitation email to join in.
        </Typography>
        <Grid container margin={1} padding={1} gap={3} flexDirection='column' width='100%'>
          <Grid display='flex' justifyContent='space-between' alignItems='center'>
            <div>
              <input onChange={onBatchUpload} id='email-batch-upload' type='file' style={{ display: 'none' }} />
              <label htmlFor='email-batch-upload'>
                <Button component='span' style={{ textTransform: 'none' }} startIcon={<Add />} variant='text' color='secondary' size='small'>Upload a batch of emails (comma separated file)</Button>
              </label>
            </div>
            <Typography variant='caption' color='secondary'>{chosenLicense?.seats - (invites?.length || 0) - localInvites.length} seats available</Typography>
          </Grid>
          <Grid className={classes.inviteListWrapper}>
            {!!localInvites.length &&
              <List className={classes.inviteList} data-test='cohort-invite-list'>
                {localInvites.map((invite, index) => (
                  <ListItem
                    className={classes.inviteListItem}
                    secondaryAction={
                      <IconButton onClick={() => removeFromInviteList(index)} edge='end'>
                        <img src='/icons/delete.svg' alt='delete' />
                      </IconButton>
                    }
                    key={index}
                  >
                    {invite}
                  </ListItem>
                ))}
              </List>}
          </Grid>

          <Typography>Add Manually</Typography>
          <Grid container alignItems='flex-start' gap={1.5} className={classes.participantContainer}>
            <TextField
              disabled={isWorking}
              value={localInput}
              onChange={e => setLocalInput(e.target.value)}
              onKeyPress={(e) => addToInviteList(e)}
              placeholder='Participant email'
              className={classes.participantTextField}
            />
            {checkedIfCanInvite.event.isWorking
              ? <CircularProgress size={40} className={classes.circularProgress} />
              : (
                <Button
                  className={classes.undercaseButton}
                  startIcon={<img src='/icons/add.svg' alt='add' />}
                  variant='outlined'
                  color='secondary'
                  size='small'
                  onClick={addToInviteList}
                >
                  Add to list
                </Button>)}
          </Grid>
          <Grid>
            <TextField
              disabled={isWorking}
              value={localMessage}
              onChange={e => setLocalMessage(e.target.value)}
              inputProps={{ style: { minHeight: '80px' } }}
              fullWidth variant='outlined' label='Custom message for coworkers' multiline
            />
          </Grid>
          <Grid>
            {(localInvites.length === 0) && <p> <Typography variant='caption' color='secondary'>You must add at least one email to invite list.</Typography></p>}
            <ReButton disabled={localInvites.length === 0 || checkedIfCanInvite.event.isWorking} loading={isWorking} action={send} name='Invite' />
          </Grid>
        </Grid>
        <AlreadyInvitedUserModal
          result={inviteResult}
          open={open && modal === 'already_invited'}
          email={localInput}
          cohort={cohort}
          cancel={cancelChange}
          confirm={moveUser}
        />
      </Box>
    </Modal>
  )
}
export default InviteModal
