import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  questionSection: {
    display: 'flex',
    alignItems:'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5, 4),
    borderRadius: '10px 10px 0 0',
    border: '1px solid #ECE9F1',
    borderBottomWidth: 0,
  },
  noteSection: {
    display: 'flex',
    alignItems:'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5, 2.5),
    borderRadius: '10px 10px 0 0',
    border: '1px solid #ECE9F1',
    borderBottomWidth: 0,
  },
  questionSectionSelected: {
    backgroundColor: '#ECE9F1'
  },
  questionSectionDone: {
    opacity: 0.7,
    borderWidth: '2px',
    borderBottomWidth: '0px'
  }
}))