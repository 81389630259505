import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  homePaper: {
    borderRadius: '10px',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)'
  },
  toolPathwayGrid: {
    height: theme.spacing(33),
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2)
  },
  dotContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  dotWrapper: {
    height: '20px',
    width: '20px'
  },
  dot: {
    backgroundColor: '#C4C4C4',
    height: '6px',
    width: '6px',
    borderRadius: '100%'
  },
  selected: {
    backgroundColor: '#465270'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    paddingBottom: theme.spacing(4)
  },
  popoverIcon: {
    marginLeft: '10px',
    marginTop: '5px',
    height: 19,
    width: 19,
    fill: 'grey'
  },
  grid: {
    alignItems: 'center'
  },
  chartlegend: {
    width: '25px',
    height: '25px',
    borderRadius: '5px',
    marginRight: '5px'
  },
  legend: {
    width: '35px',
    height: '25px',
    borderRadius: '5px',
    marginRight: '10px',
    borderStyle: 'solid',
    borderWidth: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    width: '100%',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    padding: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  arealegend: {
    width: '15px',
    height: '15px',
    borderRadius: '3px',
    marginRight: '2px',
    borderStyle: 'solid',
    borderWidth: '1px',
    minWidth: '15px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
