import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(({ palette: { button, primary } }) => ({
  container: {
    position: 'relative',
    backgroundColor: '#F2F2F2',
    borderRadius: '100px',
    overflow: 'hidden',
    width: '100%'
  },
  toggle: {
    position: 'absolute',
    height: '100%',
    width: '50%',
    borderRadius: '100px',
    transition: 'all 0.3s ease-in-out',
    left: 0,
    top: 0,
    backgroundColor: primary.main
  },
  toggleToRight: {
    left: '50%'
  },
  optionContainer: {
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 8px',
    minWidth: '100px',
    minHeight: '25px'
  },
  optionTypo: {
    fontSize: '10px',
    color: 'black',
    transition: 'all 0.3s ease-in-out'
  },
  optionTypoSelected: {
    color: 'white'
  }
}))
