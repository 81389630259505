import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { FullScreenToolbar, Overlay, ToolPathwayItemBigger } from '../../components'
import { useStyles } from './style'
import { useSelector } from 'react-redux'
import { userSlice } from '../../reducers'
import { useNavigate } from 'react-router'
import { featureSelector } from '../../reducers/user/selectors'
import Forbidden from '../403'
import { useEventContext } from '../../contexts/event'

export default function ToolPathway () {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const user = useSelector(userSlice.selectors.selectUser)
  const userRead = useSelector(userSlice.selectors.selectUserRead)
  const features = useSelector(featureSelector)
  const { getUser } = useEventContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (user && user.toolpathway && user.toolpathway.length) {
      setLoading(false)
    }
  }, [user])

  const { toolpathway, questionnairePathway } = user
  const showing = useSelector(userSlice.selectors.selectShowToolpathOrQuestionnaire)
  const pathwayToShow = showing === 'toolpathway' ? toolpathway : questionnairePathway

  const getItemStatus = (id) => {
    if (userRead.find(i => i.sk === id.toString())) return 'read'
    else return 'pending'
  }

  return (
    !features.TOOL_PATHWAYS && !getUser.isWorking ? <Forbidden />
      : !getUser.isWorking
        ? <Overlay>
          <FullScreenToolbar />
          {!loading &&
            <Grid container justifyContent='center' className={classes.container}>
              <Grid container justifyContent='space-between' className={classes.toolpathwayTitleContainer}>
                <Typography variant='h6' className={classes.toolpathwayTitle}>Tool Pathway</Typography>
                <Typography variant='body2' className={classes.toolpathwayTitle}>{pathwayToShow.length} tool{pathwayToShow.length > 0 && 's'} recommended</Typography>
              </Grid>
              <Grid container direction='column'>
                {pathwayToShow.map((c, i) =>
                  <ToolPathwayItemBigger
                    key={c.id}
                    item={c}
                    selectContent={() => navigate('../tool-pathway-content', { state: { id: c.id } })}
                    currentStatus={c.status}
                    itemStatusForUser={getItemStatus(c.id)}
                    bottomTrail={i !== toolpathway.length - 1}
                  />
                )}
              </Grid>
            </Grid>}
        </Overlay> : <>Loading...</>
  )
}
