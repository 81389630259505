import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Paper, Typography, ButtonBase, Skeleton } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useStyles } from './style'
import { userSlice } from '../../reducers'
import { useNavigate } from 'react-router'
import { cacheImages } from '../../helpers/cacheImages'

const mostPopularLength = 6

const MostPopularCard = () => {
  const classes = useStyles()
  const [selected, setSelected] = useState(0)
  const navigate = useNavigate()

  const user = useSelector(userSlice.selectors.selectUser)

  const onArrowClick = (side) => {
    let nextSelected = selected
    if (side === 'left') nextSelected--
    if (side === 'right') nextSelected++
    if (nextSelected < 0) nextSelected = user.mostPopular.slice(0, 6).length - 1
    if (nextSelected >= user.mostPopular.slice(0, 6).length) nextSelected = 0
    setSelected(nextSelected)
  }

  const renderMostPopular = useCallback(() => {
    const item = user.mostPopular[selected]
    return (
      <ButtonBase className={classes.content} onClick={() => navigate('most-popular-content', { state: { id: item.id } })}>
        <Grid container item direction='column' alignItems='center'>
          <img className={classes.image} src={item.jetpack_featured_media_url} alt='content thumbnail' />
          <Typography variant='subtitle2' className={classes.contentTitle} dangerouslySetInnerHTML={{ __html: item.title.rendered }} />
        </Grid>
        <Grid container className={classes.additionalInfo} justifyContent='center' alignItems='center'>
          {item.type && <Typography variant='caption' className={classes.typeTypo}>{item?.acf?.type || 'Article'}</Typography>}
          {item.type && item.duration && <Typography variant='caption'> | </Typography>}
          {item.duration && <Typography variant='caption'>Estimated time • {item.duration} min</Typography>}
        </Grid>
      </ButtonBase>
    )
  }, [user.mostPopular, classes, selected, navigate])

  useEffect(() => {
    cacheImages(user?.mostPopular?.slice(0, mostPopularLength).map(item => item.jetpack_featured_media_url))
  }, [user?.mostPopular])

  if (!user?.mostPopular?.length) return <Skeleton height={320} variant='rectangular' />
  return (
    <Paper elevation={2} className={classes.container}>
      <Grid container item xs={12} className={classes.contentWrapper}>
        <Typography variant='h6' className={classes.mostPopularTitle}>Most Popular Tools</Typography>
        {renderMostPopular()}
        <Grid container item justifyContent='center' className={classes.carouselWrapper}>
          <ButtonBase onClick={() => onArrowClick('left')}><KeyboardArrowLeftIcon /></ButtonBase>
          <Grid item className={classes.dotContainer}>
            {[...Array(user.mostPopular.length).keys()].slice(0, mostPopularLength).map((_, idx) => (
              <ButtonBase onClick={() => setSelected(idx)} key={idx} className={classes.dotWrapper}>
                <div className={selected === idx ? classNames(classes.dot, classes.selected) : classes.dot} />
              </ButtonBase>
            ))}
          </Grid>
          <ButtonBase onClick={() => onArrowClick('right')}><KeyboardArrowRightIcon /></ButtonBase>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default MostPopularCard
