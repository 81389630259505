import React, { useCallback, useState } from 'react'
import { Grid, Paper, Typography, ButtonBase } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import classNames from 'classnames'
import { useStyles } from './style'
import { useNavigate } from 'react-router'
import { tips } from '../../helpers/mocks'

const TipsSmallCarousel = () => {
  const classes = useStyles()
  const [selected, setSelected] = useState(0)
  const navigate = useNavigate()

  const onArrowClick = (side) => {
    let nextSelected = selected
    if (side === 'left') nextSelected--
    if (side === 'right') nextSelected++
    if (nextSelected < 0) nextSelected = tips.slice(0, 3).length - 1
    if (nextSelected >= tips.slice(0, 3).length) nextSelected = 0
    setSelected(nextSelected)
  }

  const renderMostPopular = useCallback(() => {
    const item = tips[selected]
    return (
      <ButtonBase className={classes.content} onClick={() => navigate(`help/${item.id}`)}>
        <Grid container item direction='column' alignItems='center'>
          <img className={classes.image} src={item.image} alt='content thumbnail' />
          <Typography variant='subtitle2' className={classes.contentTitle}>{item.title}</Typography>
        </Grid>
        <Grid container className={classes.additionalInfo} justifyContent='center' alignItems='center'>
          <Typography variant='caption' className={classes.typeTypo}>Read more</Typography>
        </Grid>
      </ButtonBase>
    )
  }, [classes, selected, navigate])

  return (
    <Paper elevation={2} className={classes.container}>
      <Grid container item xs={12} className={classes.contentWrapper}>
        <Typography variant='h6' className={classes.mostPopularTitle}>Tips for you</Typography>
        {renderMostPopular()}
        <Grid container item justifyContent='center' className={classes.carouselWrapper}>
          <ButtonBase onClick={() => onArrowClick('left')}><KeyboardArrowLeftIcon /></ButtonBase>
          <Grid item className={classes.dotContainer}>
            {[...Array(tips.length).keys()].slice(0, 3).map((_, idx) => (
              <ButtonBase onClick={() => setSelected(idx)} key={idx} className={classes.dotWrapper}>
                <div className={selected === idx ? classNames(classes.dot, classes.selected) : classes.dot} />
              </ButtonBase>
            ))}
          </Grid>
          <ButtonBase onClick={() => onArrowClick('right')}><KeyboardArrowRightIcon /></ButtonBase>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default TipsSmallCarousel
