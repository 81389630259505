import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex-root',
    '& H3': {
      maxWidth: '80%'
    },
    '& p': {
      margin: '12px 0'
    }
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F6F8FB',
    padding: '0 16px',
    height: '50px'
  },
  fieldName: {
    display: 'block',
    width: '130px',
    alignSelf: 'center'
  },
  fieldValue: {
    flex: 1,
    fontWeight: 'bold',
    alignSelf: 'center'
  },
  fieldBtn: {
    width: '40px',
    height: '40px',
    alignSelf: 'center'
  }
}))
