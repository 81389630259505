import * as actions from './slice'
import * as trackerActions from '../tracker/slice'

export const getUserSettings = {
  eventName: 'getUserSettings',
  onSuccess: [{ redux: true, action: actions.setUserSettings }]
}

export const updateUserSettings = {
  eventName: 'updateUserSettings',
  onSuccess: [{ redux: true, action: actions.setUserSettings }, { redux: true, action: trackerActions.updateTrackerSteps }]
}
