import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  empty: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  lowScoreText: {
    color: '#A0a4a8',
    marginLeft: '16px',
    fontSize: '14px',
    fontWeight: 'normal'
  }
}))
