import * as actions from './slice'

export const getUser = {
  eventName: 'getUser',
  onSuccess: [{ redux: true, action: actions.setUser }]
}

export const saveUserPersonalSettings = {
  eventName: 'Personal Settings Was Sent',
  onSuccess: [{ redux: true, action: actions.setPersonalSettings }]
}

export const getUserNotes = {
  eventName: 'User Notes Were Requested',
  onSuccess: [{ redux: true, action: actions.setUserNotes }]
}

export const getUserProfilePic = {
  eventName: 'Profile Picture Url Was Requested',
  onSuccess: [{ redux: true, action: actions.setProfilePic }]
}

export const getUserTrackerAnswerBus = {
  eventName: 'getTrackerAnswersOfTheDay',
  onSuccess: [{ redux: true, action: actions.setUserTrackerAnswer }]
}

export const getUserToolpathway = {
  eventName: 'getUserToolPathwayBus',
  onSuccess: [{ redux: true, action: actions.setUserToolpathway }]
}

export const getUserQuestionnairePathway = {
  eventName: 'getUserQuestionnaireToolPathwayBus',
  onSuccess: [{ redux: true, action: actions.setUserQuestionnairePathway }]
}

export const getMostPopular = {
  eventName: 'getMostPopular',
  onSuccess: [{ redux: true, action: actions.setUserMostPopular }]
}

export const saveWeekSeeder = {
  eventName: 'saveWeekSeeder',
  onSuccess: [{ redux: true, action: actions.setUser }]
}

export const saveUserDevice = {
  eventName: 'saveUserDevice'
}

export const triggerUserNotification = {
  eventName: 'createTrackerNotification'
}

export const changeDashboardFilter = {
  eventName: 'dashboardFilterChanged',
  onSuccess: [{ redux: true, action: actions.dashboardFilterChanged }]
}

export const saveUserOptions = {
  eventName: 'saveUserOptions',
  onSuccess: [{ redux: true, action: actions.setUser }]
}

export const resetUser = {
  eventName: 'resetUser',
  onSuccess: [{ redux: true, action: actions.setUser }]
}
