import React, { useEffect, useState } from 'react'
import { Dialog, Fade, Grid, IconButton, Typography } from '@mui/material'
import { useStyles } from './style'
import { ReButton } from '../..'
import PopperWrapper from '../popper-wrapper'
import { Close } from '@mui/icons-material'

export default function OrganisationDriverModal ({ next, previous, cancelOnBoarding, isOnFeature }) {
  const classes = useStyles()
  const [ref, setRef] = useState(null)
  const [loaded, setLoaded] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      setRef(document.querySelector('.onboarding-organisation-drivers'))
      setLoaded(true)
    }, 0)
  }, [])

  const Wrapper = ({ children }) => isOnFeature
    ? <Dialog open classes={{ paperWidthSm: classes.modal }} spacing={3}>{children}</Dialog>
    : <PopperWrapper element={ref} placement='right'>{children}</PopperWrapper>

  return (
    <Wrapper>
      <Fade in={loaded} timeout={400}>
        <Grid
          container
          justifyContent='center'
          direction='column'
          flexWrap='nowrap'
          className={classes.root}
        >
          <Grid style={{ position: 'absolute', top: 20, right: 20 }}>
            <IconButton onClick={cancelOnBoarding} color='primary'><Close style={{ fontSize: '30px' }} /></IconButton>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: '285px' }}>
              <img
                src='https://static.resilienceengine.live/img/onboarding-organisation-drivers.svg'
                alt='Additional User Settings Congratulations'
              />
            </div>
            <Typography variant='h3' className={classes.title}>
              Organisation Drivers
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom={3}>
            <Typography component='p' variant='body3'>
              Here you can see your Organisation Drivers, which show the scores from the second part of your Resilience Dynamic® Questionnaire. These drivers reflect the impact of demand on you at work. They cover: Psychological Safety, Workload and Stress, Trust, and Engagement.
            </Typography>
          </Grid>
          {isOnFeature
            ? <Grid item display='flex' justifyContent='center' gap={5}><ReButton fullWidth name='Understood' dataTestId='close_congratulations_modal' action={cancelOnBoarding} /></Grid>
            : (
              <Grid item display='flex' justifyContent='flex-end' gap={5}>
                <ReButton fullWidth name='Previous' dataTestId='close_congratulations_modal' action={previous} />
                <ReButton fullWidth name='Next' dataTestId='close_congratulations_modal' action={next} />
              </Grid>)}
        </Grid>
      </Fade>
    </Wrapper>
  )
}
