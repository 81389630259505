import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1200,
    background: 'white',
    width: '100%',
    height: '100%',
    overflowY: 'auto'
  }
}))
