import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    gap: '16px',
    flexWrap: 'nowrap',
    paddingBottom: '56px',
    cursor: 'pointer'
  },
  typeTypo: {
    fontWeight: 'bold'
  },
  additionalInfo: {
    color: theme.palette.text.grey,
    gap: '3px'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '70px',
    height: '70px',
    border: '2px solid #E2E2E2',
    borderRadius: '100%',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%'
  },
  titleContainer: {
    gap: '8px'
  },
  title: {
    lineHeight: 1.25
  },
  youAreReadingThis: {
    color: theme.palette.primary.dark,
    fontSize: '12px',
    textDecoration: 'underline'
  },
  bottomTrail: {
    position: 'absolute',
    top: '70px',
    left: '34px',
    width: '2px',
    height: '100%',
    background: '#DDDBE0'
  },
  descriptionWrapper: {
  },
  description: {
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'start'
  },
  iconsContainer: {
    display: 'flex',
    gap: '26px'
  },
  iconButton: {
    display: 'flex',
    gap: '5px',
    flexWrap: 'nowrap',
    padding: '5px'
  }
}))
