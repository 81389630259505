import { Button, Chip, CircularProgress, Grid, IconButton, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import { Delete, Add } from '@mui/icons-material'
import React, { useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useStyles } from './style'
import { usePromiseEvent } from '../../../../hooks'
import { cohortSlice, userSlice } from '../../../../reducers'
import { useSelector } from 'react-redux'
import InviteModal from '../../invite-modal'

export default function StepTwo() {
  const { control, getValues, setValue } = useFormContext()
  const classes = useStyles()
  const licenseList = useSelector(cohortSlice.selectors.selectLicenseList)
  const [chosenLicense] = licenseList.filter(license => license.sk === getValues('licenseId'))
  const { id: tenantKey } = useSelector(userSlice.selectors.selectedView)

  const [open, setOpen] = React.useState(false)
  return (
    <Grid>
      <Grid container minHeight='400px' maxHeight='700px' maxWidth='900px' marginTop={3} overflow='auto'>
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>NAME</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Invite date</StyledTableCell>
                <StyledTableCell align='center'>STATUS</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Controller
                name='invites'
                control={control}
                render={({ field }) => field.value.map((row, index) => (
                  <StyledTableRow key={row.sk}>
                    <StyledTableCell>{row.name || ' - '}</StyledTableCell>
                    <StyledTableCell>{row.sk}</StyledTableCell>
                    <StyledTableCell>{moment(row.createdAt).format('DD/MM/YYYY - HH:mm')}</StyledTableCell>
                    <StyledTableCell><InviteStatus invite={row} index={index} tenantKey={tenantKey} /></StyledTableCell>
                  </StyledTableRow>
                ))}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container alignItems='center'>
        <Button onClick={() => setOpen(true)} size='small' color='secondary' startIcon={<Add />}>Invite more participants</Button>
        <Typography className={classes.seatsAvailable} color='secondary'> {chosenLicense?.seats - (getValues('invites')?.length || 0)} seats available</Typography>
      </Grid>
      <InviteModal 
        open={open}
        handleClose={() => setOpen(false)}
        setValue={setValue}
        cohort={getValues('cohortName')}
        cohortId={getValues('cohortId')}
        invites={getValues('invites')}
        licenseId={getValues('licenseId')}
      />
    </Grid>
  )
}

const InviteStatus = ({ invite, index, tenantKey }) => {
  const classes = useStyles()
  const inviteWasDeleted = usePromiseEvent('Invite Was Deleted')
  const inviteWasSent = usePromiseEvent('Invite Was Sent')
  const { getValues, setValue } = useFormContext()

  const removeFromList = async () => {
    const current = getValues('invites')
    const toDelete = current[index]
    await inviteWasDeleted.promise({ cohortId: getValues('cohortId'), email: toDelete.sk, tenantKey })
    setValue('invites', current.filter((_, i) => i !== index))
  }

  const resendInvite = async () => {
    const { inviteId } = invite
    await inviteWasSent.promise({ inviteId, tenantKey })
  }

  const isWorking = useMemo(() => inviteWasDeleted.event.isWorking || inviteWasSent.event.isWorking, [inviteWasDeleted.event.isWorking, inviteWasSent.event.isWorking])

  const status = useMemo(() => {
    let toReturn = 'inactive'
    if (invite.hasBeenSent) toReturn = 'pending'
    if (invite.hasBeenUsed) toReturn = 'active'
    return toReturn
  }, [invite.hasBeenSent, invite.hasBeenUsed])

  return (
    <Grid container alignItems='center' justifyContent='flex-end' gap={3} flexWrap='nowrap'>
      {status !== 'active' &&
        <Button disabled={isWorking} onClick={resendInvite} style={{ textTransform: 'none' }} size='small' color='secondary' startIcon={<img src='/icons/mail.svg' alt='mail' />}>
          Re-send invite
        </Button>}
      <Chip
        label={status}
        size='small'
        className={status === 'active' ? classes.chipSuccess : classes.chipWarning}
      />
      <IconButton edge='end' disabled={isWorking} onClick={removeFromList}>
        {inviteWasDeleted.event.isWorking ? <CircularProgress size={24} /> : <Delete />}
      </IconButton>
    </Grid>
  )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.neutral,
    color: theme.palette.text.greyDark,
    fontWeight: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({

}))
