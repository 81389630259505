import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#F6F8FB'
  },
  main: {
    width: 'calc(100% - 260px)',
    flexGrow: 1,
    marginTop: '88px',
    padding: theme.spacing(1, 3, 0, 3),
    minHeight: 'calc(100vh - 88px)'
  }
}))
