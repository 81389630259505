import { Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useCallback } from 'react'
import TrackerAnswer from '../tracker-answer'
import TrackerNote from '../tracker-note'

const TrackerRow = ({ rowQuestion, days, values, setAnswers, answers }) => {
  const updateAnswer = useCallback((answer, note, day) => {
    setAnswers(current => {
      const newAnswers = { ...current }
      newAnswers[rowQuestion.name][day.format('YYYY-MM-DD')] = { date: day, question: rowQuestion, answer, note, hasAnswered: false, needsUpdate: true }
      return newAnswers
    })
  }, [rowQuestion, setAnswers])

  return (
    <Box>
      <Box display='flex' alignItems='center'>
        <Box display='flex' width='300px'>
          <Typography>{rowQuestion.text}</Typography>
        </Box>
        <Box display='flex' flex={2} justifyContent='space-between'>
          {days.map((day, idx) => (
            <Box key={idx} marginY={2} width='100px' display='flex' justifyContent='center'>
              <TrackerQuestion {...{ rowQuestion, values, day, updateAnswer }} questionAnswer={answers?.[rowQuestion.name]?.[day.format('YYYY-MM-DD')]} />
            </Box>
          ))}
        </Box>
        <Box width='30px'></Box>
      </Box>
      <Divider />
    </Box>
  )
}

const TrackerQuestion = ({ rowQuestion, values, day, updateAnswer, questionAnswer }) => {
  if (rowQuestion.name === 'note')
    return <TrackerNote {...{ values, day, updateAnswer, questionAnswer }} />
  return <TrackerAnswer {...{ values, day, updateAnswer, questionAnswer }} />
}

export default TrackerRow