import React, { useEffect, useState } from 'react'
import { Fade, Grid, IconButton, Typography } from '@mui/material'
import { useStyles } from './style'
import { ReButton } from '../..'
import PopperWrapper from '../popper-wrapper'
import { Close } from '@mui/icons-material'

export default function ResilienceTrackerModal({ next, previous, cancelOnBoarding }) {
  const classes = useStyles()
  const [ref, setRef] = useState(null)
  const [loaded, setLoaded] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      setRef(document.querySelector('.onboarding-tracker'))
      setLoaded(true)
    }, 0)
  }, [])

  return (
    <PopperWrapper element={ref} placement='right'>
      <Fade in={loaded} timeout={400}>
        <Grid
          container
          justifyContent='center'
          direction='column'
          flexWrap='nowrap'
          className={classes.root}
        >
          <Grid style={{ position: 'absolute', top: 20, right: 20 }}>
            <IconButton onClick={cancelOnBoarding} color='primary'><Close style={{ fontSize: '30px' }} /></IconButton>
          </Grid>
          <Typography variant='h3' className={classes.title}>
            Resilience Tracker
          </Typography>
          <Grid display='flex'>
            <Grid item xs={12} marginBottom={3}>
              <Typography component='p' variant='body3'>
                To gain further insight into your resilience data and shine a light on your patterns, use the Resilience Tracker to see your current resilience drivers.
              </Typography>
              <br />
              <Typography component='p' variant='body3'>
                This is an ongoing daily or weekly record of the resilience demand, capacity, and energy you have. The tracker also includes optional questions on sleep, stress, wellbeing, and contentedness, and an optional notes section. You can refer back to your notes in My Reports and Notes in the dashboard menu.
              </Typography>
              <br />
              <Typography component='p' variant='body3'>
                You can see an overview of your Resilience Tracker data at any time using the Resilience and Wellbeing Drivers graphs on your dashboard. This is an opportunity to see and understand the variance in your resilience, day to day.
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.space}>
              <div style={{ width: '288px' }}>
                <img
                  src='https://static.resilienceengine.live/img/onboarding-resilience-tracker-v2.svg'
                  alt='Additional User Settings Congratulations'
                />
              </div>
            </Grid>
          </Grid>
          <Grid item display='flex' justifyContent='flex-end' gap={5}>
            <ReButton fullWidth name='Previous' dataTestId='close_congratulations_modal' action={previous} />
            <ReButton fullWidth name='Next' dataTestId='close_congratulations_modal' action={next} />
          </Grid>
        </Grid>
      </Fade>
    </PopperWrapper>
  )
}
