import React from 'react'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import useNavigation from '../../../helpers/useNavigation'
import PlanForm from '../components/form'
import { useAlert } from '../../../hooks'
import Forbidden from '../../403'
import ability from '../../../config/ability'

const PlanCreatePage = () => {
  // const { planId } = useParams()
  const navigate = useNavigation()
  const { showSuccessAlert, showErrorAlert } = useAlert()

  const [createPlan] = useCorvusEventList([{
    eventName: 'Plan Was Created',
    onSuccess: () => {
      showSuccessAlert('Success!', 'The Plan has been created')
      navigate('/dashboard/plans')
    },
    onError: (error) => {
      showErrorAlert('Error!', 'An unexpected error has occurred')
      console.log(error)
    }
  }])

  const handleCancelButton = () => {
    navigate('/dashboard/plans')
  }

  const onSubmit = (data) => {
    console.log(data)
    createPlan.trigger({
      plan: data
    })
  }

  const isSuperAdmin = ability.can('view', 'superAdminView')

  return (
    !isSuperAdmin ? <Forbidden />
      : <PlanForm
        title='Create Plan'
        onSubmit={onSubmit}
        onCancel={handleCancelButton}
        loading={createPlan.isWorking}
        loadingMessage={createPlan.isWorking && 'Creating Plan...'}
        defaultValues={{
          name: '',
          description: '',
          features: []
        }}
        buttonText='Create'
      />
  )
}

export default PlanCreatePage
