import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    maxWidth: '1200px'
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  },
  header: {
    fontWeight: 'bold'
  },
  groupRenderWrapper: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center'
  },
  timelineItem: {
    borderRadius: '6px',
    background: `${theme.palette.secondary.main} !important`,
    borderColor: 'transparent !important'
  },
  timelineItemSelected: {
    backgroundColor: `${theme.palette.primary.main} !important`
  },
  nextButton: {
    marginTop: theme.spacing(3)
  },
  sidebarHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sidebarHeaderText: {
    textTransform: 'uppercase',
    color: theme.palette.text.greyDark
  },
  groupRendererTitle: {
    margin: '0 0 0 10px'
  },
  modalWrapper: {
    padding: '20px'
  },
  datePickerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    gap: '10px'
  },
  modalCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  modalButtonWrapper: {
    display: 'flex',
    gap: theme.spacing(1)
  }
}))
