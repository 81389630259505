export const resilienceNames = [
  { name: 'demand', color: '#6f61a6' },
  { name: 'capacity', color: '#b168a1' },
  { name: 'energy', color: '#5EBED0' }
]

export const wellbeingNames = [
  { name: 'sleep', color: '#5B4A99' },
  { name: 'stress', color: '#BF347C' },
  { name: 'wellbeing', color: '#6CADC0' },
  { name: 'contentedness', color: '#868B92' }
]
