import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ palette: { secondary: { dark } }, ...theme }) => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(4),
    maxWidth: '750px'
  },
  text: {
    padding: `${theme.spacing(0)} ${theme.spacing(0)}  ${theme.spacing(2)}  ${theme.spacing(0)}`,
    fontSize: '24px',
    fontWeight: 'normal',
    color: dark
  },
  title: {
    fontWeight: '600',
    color: dark,
    paddingBottom: `${theme.spacing(2)}`,
    paddingTop: `${theme.spacing(2)}`
  },
  modal: {
    width: '100%',
    maxWidth: '700px',
    maxHeight: '95vh',
    alignItems: 'center',
    borderRadius: 16
  }
}
))
