import { useSelector } from 'react-redux'
import { Box, Fade, IconButton, TextField } from '@mui/material'
import moment from 'moment'
import { userSlice } from '../../../reducers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AddComment, ArrowDropDown } from '@mui/icons-material'
import { ReButton } from '../../../components'

const TrackerNote = ({ values, day, updateAnswer, questionAnswer }) => {
  const user = useSelector(userSlice.selectors.selectUser)
  const id = useMemo(() => `note-${moment(day).format('YYYY-MM-DD')}`, [day])
  const [open, setOpen] = useState(false)
  const [toSave, setToSave] = useState('')
  useEffect(() => {
    const handleClick = e => {
      const isClickInside = e.target.closest(`#${id}`)
      if (!isClickInside) {
        setOpen(v => {
          if (v) return false
          return v
        })
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [setOpen, id])

  useEffect(() => {
    if (toSave?.length) {
      updateAnswer(0, toSave, day)
    }
  }, [updateAnswer, day, toSave])

  const isDisabled = useMemo(() => values[moment(day).format('YYYY-MM-DD')]?.hasAnswered || moment().isSameOrBefore(day, 'day') || moment(user.createdAt).isAfter(day, 'day'), [values, day, user.createdAt])

  const iconColor = useMemo(() => {
    if (questionAnswer?.needsUpdate) return 'primary'
    if (isDisabled) return 'action'
    return 'secondary'
  }, [isDisabled, questionAnswer?.needsUpdate])

  return (
    <Box id={id} position='relative'>
      <IconButton onClick={() => setOpen(s => !s)} disabled={isDisabled}>
        <AddComment color={iconColor} />
        <ArrowDropDown />
      </IconButton>
      {<Box position='absolute' sx={{ zIndex: 1 }}>
        <Fade in={open} unmountOnExit>
          <Box display='flex' flexDirection='column' alignItems='flex-end' width='300px' sx={{ background: 'white' }}>
            <TrackerNoteInput {...{ setOpen, toSave, setToSave, questionAnswer }} initialValue={values[moment(day).format('YYYY-MM-DD')]?.note} />
          </Box>
        </Fade>
      </Box>}
    </Box>
  )
}

const TrackerNoteInput = ({ initialValue, setOpen, toSave, setToSave, questionAnswer }) => {
  const [localValue, setLocalValue] = useState('')
  const disableButtons = useMemo(() => !!initialValue && !questionAnswer.needsUpdate, [initialValue, questionAnswer])

  useEffect(() => {
    if (toSave?.length) {
      setLocalValue(toSave)
      return
    }
    if (initialValue?.length) {
      setLocalValue(initialValue)
    }
  }, [toSave, initialValue])

  const onSave = useCallback(() => {
    setToSave(localValue)
    setOpen(false)
  }, [localValue, setToSave, setOpen])

  return (
    <>
      <TextField multiline rows={4} fullWidth value={localValue} onChange={e => setLocalValue(e.target.value)} disabled={disableButtons} />
      <Box position='absolute' bottom={'-30px'}>
        <ReButton tiny name='Save note' action={onSave} disabled={disableButtons} />
      </Box>
    </>
  )
}

export default TrackerNote