import name from './name'

const selectSliceData = state => state[name]

const selectSteps = state => selectSliceData(state).steps
const selectContents = state => selectSliceData(state).contents
const selectInfo = state => selectSliceData(state).info

export {
  selectSteps,
  selectContents,
  selectInfo
}
