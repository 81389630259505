import React from 'react'
import { AppBar, Grid, Toolbar, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './style'

const LoginHeader = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const loginMenu = [
    // {
    //   name: 'Home',
    //   route: useResolvePathFromBaseURL('')
    // },
    // {
    //   name: 'Features',
    //   route: useResolvePathFromBaseURL('feature')
    // },
    // {
    //   name: 'About Us',
    //   route: useResolvePathFromBaseURL('about')
    // }
  ]
  return (
    <AppBar position='static' elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <div className={classes.box} xs={6} md={3}>
              <img src='/img/logo-v2.svg' alt='Resilience Engine Logo' className={classes.image} />
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <nav className={classes.nav}>
              {
                loginMenu.map((link, key) => (
                  <Link
                    key={key}
                    variant='button'
                    onClick={() => navigate(link.route)}
                    className={classes.link}
                  >
                    {link.name}
                  </Link>
                ))
              }
            </nav>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default LoginHeader
