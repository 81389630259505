import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { loginSlice } from '../../reducers'

const PrivateRoute = ({ children }) => {
  const userAuthenticated = useSelector(loginSlice.selectors.selectUserAuthenticated)

  return (
    userAuthenticated ? (
      children
    ) : (
      <Navigate to='/login' />
    )
  )
}

export default PrivateRoute
