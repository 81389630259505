import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

export const admin = createSlice({
  name: reducerName,
  initialState: {
    cycles: []
  },
  reducers: {
    setCycles: (state, { payload }) => {
      state.cycles = payload
    }
  }
})

export const clearCycles = () => async (dispatcher) => {
  dispatcher(setCycles([]))
}

export const {
  setCycles
} = admin.actions

// Reducer
export default admin.reducer
