import React, { useCallback, useState } from 'react'
import { ButtonBase, Grid, Typography } from '@mui/material'
import { useStyles } from './style'
import classNames from 'classnames'

const ReSwitchButton = ({ onClick, leftOption, rightOption, state = 'left' }) => {
  const styles = useStyles()
  const [current, setCurrent] = useState(state)

  const getToggleClasses = useCallback(() => {
    if (current === 'left') return styles.toggle
    return classNames(styles.toggle, styles.toggleToRight)
  }, [current, styles])

  const getTypoClasses = useCallback((selected) => {
    if (!selected) return styles.optionTypo
    return classNames(styles.optionTypo, styles.optionTypoSelected)
  }, [styles])

  const overrideOnClick = () => {
    current === 'left' ? setCurrent('right') : setCurrent('left')
    if (onClick) onClick()
  }

  return (
    <ButtonBase onClick={overrideOnClick} className={styles.container}>
      <Grid container wrap='nowrap' justifyContent='center' alignItems='center'>
        <div className={getToggleClasses()} />
        <Grid className={styles.optionContainer} item xs={6}>
          <Typography className={getTypoClasses(current === 'left')}>{leftOption}</Typography>
        </Grid>
        <Grid className={styles.optionContainer} item xs={6}>
          <Typography className={getTypoClasses(current === 'right')}>{rightOption}</Typography>
        </Grid>
      </Grid>

    </ButtonBase>
  )
}

export default ReSwitchButton
