import { CircularProgress, Grid, IconButton, Paper, Typography, Skeleton } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Empty, NotesPdf } from '../../../components'
import { useStyles } from '../style'
import { useSelector } from 'react-redux'
import { userSlice } from '../../../reducers'
import { useEventContext } from '../../../contexts/event'

const NotesCard = () => {
  const classes = useStyles()
  const events = useEventContext()
  const user = useSelector(userSlice.selectors.selectUser)

  if (events.getUserNotes.isWorking) {
    return (
      <Grid item xs={12}>
        <Skeleton height={70} variant='rectangular' />
      </Grid>
    )
  }

  if (!user?.notes || !user?.notes?.length) {
    return (
      <Grid item xs={12}>
        <Empty message='You have not added any notes to you Tracker.' />
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={9}>
            <Typography className={classes.title}>
              Resilience Tracker Notes
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Grid
              container
              justifyContent='flex-end'
              alignItems='center'
            >
              <Grid item xs={12}>
                <PDFDownloadLink
                  document={<NotesPdf data={user.notes} user={user} />}
                  fileName='notes.pdf'
                  style={{
                    textDecoration: 'none'
                  }}
                >
                  {({ loading }) =>
                    loading
                      ? (<CircularProgress />)
                      : (
                        <IconButton
                          title='Download All My Notes'
                          aria-label='Download All My Notes'
                          className={`${classes.result} ${classes.btnNotes}`}
                          size='large'
                        >
                          <GetAppIcon fontSize='small' />
                          <Typography variant='body2' className={classes.marginBorderRight}> Download All My Notes </Typography>
                        </IconButton>)}
                </PDFDownloadLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default NotesCard
