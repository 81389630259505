import { useContext } from 'react'
import { AlertContext, ALERT_TYPES } from '../contexts/alert'

function useAlert () {
  const { showAlert, closeAlert } = useContext(AlertContext)

  const showSuccessAlert = (title, message, durationMs) => {
    showAlert(title, message, ALERT_TYPES.success, durationMs)
  }

  const showErrorAlert = (title, message, durationMs) => {
    showAlert(title, message, ALERT_TYPES.error, durationMs)
  }

  const showInfoAlert = (title, message, durationMs) => {
    showAlert(title, message, ALERT_TYPES.info, durationMs)
  }

  const showWarningoAlert = (title, message, durationMs) => {
    showAlert(title, message, ALERT_TYPES.warning, durationMs)
  }
  return {
    showSuccessAlert,
    showErrorAlert,
    showInfoAlert,
    showWarningoAlert,
    closeAlert,
    alertTypes: ALERT_TYPES
  }
}

export default useAlert
