import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    maxWidth: '800px'
  },
  cicoStep: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
    cursor: 'pointer',
    borderRadius: theme.spacing(1)
  },
  cicoStepTitle: {
    height: '52px',
    overflow: 'hidden',
    color: theme.palette.text.lightBlack
  },
  cicoStepDescription: {
    height: '24px',
    overflow: 'hidden',
    color: theme.palette.secondary
  },
  cicoItem: {
    display: 'flex',
    height: '160px',
    justifyContent: 'space-between',
    borderBottom: ({ itemSource }) => `3px solid ${itemSource === 'fundamentals' ? theme.palette.primary.main : theme.palette.primary.main}`,
    width: '100%',
    gap: theme.spacing(1),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: 'pointer'
  },
  cicoItemNumber: {
    position: 'relative',
    marginRight: theme.spacing(1),
    border: '1px solid #CACCCF',
    borderRadius: '50%',
    padding: theme.spacing(2),
    '& > span': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: theme.palette.primary.main
    }
  },
  cicoItemProgress: {
    position: 'relative',
    width: '20px',
    height: '20px'
  },
  cicoItemProgressBorder: {
    width: '100%',
    height: '100%',
    border: `1.5px dashed ${theme.palette.text.grey}`,
    borderRadius: '50%',
    zIndex: 0
  },
  cicoItemCircularProgress: {
    position: 'absolute',
    left: '0px',
    top: '0px',
    zIndex: 1
  },
  circularProgressWrapper: {
    position: 'relative'
  },
  bottomCircularProgress: {
    position: 'absolute',
    color: '#CACCCF'
  },
  progressTypo: {
    position: 'absolute',
    left: '50%',
    top: '47%',
    transform: 'translate(-50%, -50%)',
    fontSize: '12px',
    lineHeight: '12px'
  }
}))
