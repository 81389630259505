import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex-root',
    '& H3': {
      maxWidth: '80%'
    },
    '& p': {
      margin: '12px 0'
    }
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 16px',
    height: '50px',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  },
  fieldName: {
    display: 'block',
    fontWeight: 'bold'
  },
  switchThumb: {
    backgroundColor: '#0070BD'
  },
  switchThumbDisabled: {
    backgroundColor: 'rgba(0, 112, 189, 0.5)'
  },
  switchTrack: {
    background: 'rgba(0, 112, 189, 0.38) !important'
  },
  subSection: {
    paddingStart: '16px',
    fontWeight: 'bold',
    margin: '6px 0'
  },
  control: {
    fontWeight: 'bold'
  },
  controlRoot: {
    paddingStart: '16px'
  },
  checkbox: {
    color: '#0070BD !important'
  }
}))
