import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    maxWidth: '700px'
  },
  header: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(3)
  },
  button: {
    textTransform: 'none'
  },
  description: {
    marginBottom: theme.spacing(1.5)
  },
  input: {
    marginBottom: theme.spacing(1)
  },
  inviteWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(5)
  },
  inviteList: {
    margin: theme.spacing(2, 0, 2, 0)
  },
  inviteListItem: {
    borderTop: '1px solid #e8e8e8',
    borderLeft: '1px solid #e8e8e8',
    borderRight: '1px solid #e8e8e8',
    '&:first-child': {
      borderRadius: '10px 10px 0 0'
    },
    '&:last-child': {
      borderBottom: '1px solid #e8e8e8',
      borderRadius: '0 0 10px 10px'
    }
  },
  addManuallyTypo: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  nextButton: {
    marginTop: theme.spacing(3)
  },
  participantTextField: {
    width: '82%'
  },
  undercaseButton: {
    textTransform: 'none',
    height: '55px'
  },
  circularProgress: {
    marginLeft: '4%'
  },
  participantContainer: {
    width: '100%',
    alignItems: 'center'
  },
  customMessage: {
    marginTop: '24px'
  }
}))
