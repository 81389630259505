import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 34,
    fontWeight: 600
  },
  formButton: {
    marginTop: theme.spacing(2),
    width: '200px'
  }
}))
