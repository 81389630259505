import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: '700px',
    margin: '50px auto'
  },
  content: {
    maxWidth: '600px'
  },
  contentTitle: {
    marginBottom: '18px',
    color: theme.palette.secondary.dark
  },
  contentCategory: {
    marginBottom: '40px'
  },
  toolpathwayTitle: {
    color: theme.palette.text.greyDark,
    fontWeight: 'bold',
    marginBottom: '5px'
  },
  toolpathwayTitleContainer: {
    borderBottom: '1px solid #DDDBE0',
    marginBottom: '48px'
  }
}))
