import moment from 'moment'

export const calcBusinessDays = (startDate, endDate) => {
  const day = moment(startDate)
  let businessDays = 0

  while (day.isBefore(endDate, 'day')) {
    if (day.day() !== 0 && day.day() !== 6) businessDays++
    day.add(1, 'd')
  }
  return businessDays
}