import { Button, IconButton, List, ListItem, TextField, Typography, CircularProgress, Grid } from '@mui/material'
import React, { useContext } from 'react'
import { useStyles } from './style'
import { ReButton, CustomOverlay } from '../../../../components'
import { NewCohortContext } from '..'
import { Controller, useFormContext } from 'react-hook-form'
import { Add, Delete } from '@mui/icons-material'
import { validateEmail } from '../../../../helpers/utils'
import { cohortSlice, userSlice } from '../../../../reducers'
import { useSelector } from 'react-redux'
import AlreadyInvitedUserModal from '../../already-invited-user-modal'

export default function StepTwo() {
  const classes = useStyles()
  const { cohortCoworkerWasBatched, inviteWasDeleted, inviteListWasRequested, checkedIfCanInvite, setStep } = useContext(NewCohortContext)
  const { control, getValues, setValue } = useFormContext()
  const licenseList = useSelector(cohortSlice.selectors.selectLicenseList)
  const [chosenLicense] = licenseList.filter(license => license.sk === getValues('licenseId'))
  const { id: tenantKey } = useSelector(userSlice.selectors.selectedView)
  const [localInput, setLocalInput] = React.useState('')
  const [inviteCheckModal, setInviteCheckModal] = React.useState(false)
  const [inviteResult, setInviteResult] = React.useState(null)

  const handleNext = async () => {
    setStep(2)
  }

  const isWorking = inviteWasDeleted.event.isWorking || cohortCoworkerWasBatched.event.isWorking

  const addToList = async (e) => {
    const current = getValues('invites')
    if (isWorking) return
    if (e.charCode === 13 || e.type === 'click' || e.type === 'blur') {
      e.preventDefault()
      if (current.some(item => item.sk === localInput)) {
        return alert('This email is already in the list')
      }
      if (!validateEmail(localInput)) {
        return alert('Please enter a valid email')
      }

      const result = await checkedIfCanInvite.promise({ emails: [localInput], tenantKey })
      setInviteResult(result)
      if (result[0].error || result[0].exists) return setInviteCheckModal('already_invited')

      const { invites, invalids } = await cohortCoworkerWasBatched.promise({ cohortId: getValues('cohortId'), emails: [localInput], message: getValues('message'), tenantKey })
      if (invalids.length) {
        alert(`Error: ${invalids[0].reason}`)
        return
      }
      const invite = invites[0]
      setValue('invites', [...current, invite])
      setLocalInput('')
    }
  }

  const onModalCancel = () => {
    setInviteCheckModal(false)
    setInviteResult(null)
  }

  const onModalAccept = async () => {
    setInviteCheckModal(false)
    setInviteResult(null)
    const current = getValues('invites')
    const { invites, invalids } = await cohortCoworkerWasBatched.promise({ cohortId: getValues('cohortId'), emails: [localInput], message: getValues('message'), tenantKey })
    if (invalids.length) {
      alert(`Error: ${invalids[0].reason}`)
      return
    }
    const invite = invites[0]
    setValue('invites', [...current, invite])
    setLocalInput('')
  }

  const removeFromList = async (index) => {
    const current = getValues('invites')
    const toDelete = current[index]
    await inviteWasDeleted.promise({ cohortId: getValues('cohortId'), email: toDelete.sk, tenantKey })
    setValue('invites', current.filter((_, i) => i !== index))
  }

  const onBatchUpload = async (event) => {
    if (!event.target?.files) return
    const result = await (
      new Promise((resolve, reject) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.addEventListener('load', (event) => {
          const result = event.target.result
          resolve(result)
        })
        reader.readAsText(file)
      })
    )
    event.target.value = ''
    const parsedInvites = [...new Set(result.split(',').map(email => email.trim()).filter(email => validateEmail(email)))]
    const current = getValues('invites')
    const listWithoutAlreadyAdded = parsedInvites.filter(email => !current.some(item => item.sk === email))
    if (!listWithoutAlreadyAdded.length) return alert('No new emails to add')
    const { invites, invalids } = await cohortCoworkerWasBatched.promise({ cohortId: getValues('cohortId'), emails: listWithoutAlreadyAdded, message: getValues('message'), tenantKey })
    if (invalids.length) {
      const message = invalids.map(invite => `${invite.email}: ${invite.reason}`).join('\n')
      alert(`Error: \n${message}`)
    }
    setValue('invites', [...current, ...invites])
  }

  return (
    <div className={classes.root}>
      <Typography variant='h4' className={classes.header}>Invite Coworkers</Typography>
      <Typography variant='caption' className={classes.description}>Invited users will receive an invitation email to join in.</Typography>
      <div>
        <Typography component='span' variant='caption' className={classes.description} style={{ fontWeight: 'bold' }}>Note: </Typography>
        <Typography component='span' variant='caption' className={classes.description}>At least one should be invited.</Typography>
      </div>
      <CustomOverlay active={inviteListWasRequested.event.isWorking}>
        <div className={classes.inviteWrapper}>
          <div>
            <input onChange={onBatchUpload} id='email-batch-upload' type='file' style={{ display: 'none' }} />
            <label htmlFor='email-batch-upload'>
              <Button component='span' startIcon={<Add />} className={classes.button} variant='text' color='secondary' size='small'>Upload a batch of emails (comma separated file)</Button>
            </label>
          </div>
          <Typography variant='caption' color='secondary'>{chosenLicense.seats - (getValues('invites')?.length || 0)} seats available</Typography>
        </div>
        <Controller
          name='invites'
          control={control}
          render={({ field }) => (
            <div className={classes.inviteListWrapper}>
              {field.value.length !== 0 &&
                <List className={classes.inviteList} data-test='cohort-invite-list'>
                  {field.value.map((invite, index) => (
                    <ListItem
                      className={classes.inviteListItem}
                      secondaryAction={<IconButton disabled={isWorking} onClick={() => removeFromList(index)} edge='end'><Delete /></IconButton>}
                      key={index}
                    >
                      {invite.sk}
                    </ListItem>
                  ))}
                </List>}
              <Typography variant='body2' className={classes.addManuallyTypo}>Add Manually</Typography>
              <Grid container alignItems='flex-start' gap={1.5} className={classes.participantContainer}>
                <TextField
                  disabled={isWorking}
                  value={localInput}
                  inputProps={{ 'data-test': 'new-cohort-invite-input-email' }}
                  onChange={e => setLocalInput(e.target.value)} onKeyPress={(e) => addToList(e)} className={classes.participantTextField} variant='outlined' label='User email' placeholder='User email'
                />
                {isWorking
                  ? <CircularProgress size={40} className={classes.circularProgress} />
                  : (
                    <Button
                      className={classes.undercaseButton}
                      startIcon={<img src='/icons/add.svg' alt='add' />}
                      variant='outlined'
                      color='secondary'
                      size='small'
                      onClick={addToList}
                    >
                      Add to list
                    </Button>)}
              </Grid>
            </div>
          )}
        />
        <Grid className={classes.customMessage}>
          <Controller
            name='message'
            control={control}
            render={({ field }) =>
              <TextField
                {...field}
                inputProps={{ 'data-test': 'new-cohort-invite-input-message', style: { minHeight: '80px' } }}
                fullWidth variant='outlined' label='Custom message for coworkers' multiline
              />}
          />
        </Grid>
      </CustomOverlay>
      <ReButton
        dataTestId='new-cohort-basic-button-next'
        className={classes.nextButton}
        name='Next'
        action={handleNext}
      />
      <AlreadyInvitedUserModal
        result={inviteResult}
        open={inviteCheckModal}
        email={localInput}
        cohort={getValues('cohortName')}
        cancel={onModalCancel}
        confirm={onModalAccept}
      />
    </div>
  )
}
