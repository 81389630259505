import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

export const content = createSlice({
  name: reducerName,
  initialState: {
    toolPathway: {
      list: [],
      currentContent: null
    }
  },
  reducers: {
    setToolPathwayContent: (state, { payload }) => {
      state.toolPathway.currentContent = payload
    }
  }
})

export const clearToolPathwayContent = () => async (dispatcher) => {
  dispatcher(setToolPathwayContent([]))
}

export const {
  setToolPathwayContent
} = content.actions

// Reducer
export default content.reducer
