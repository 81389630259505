import { ButtonBase, Grid, Typography } from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import { useStyles } from './style'

const ToolPathwayItem = ({ item, selectContent, itemStatusForUser, selected, bottomTrail, disabled }) => {
  const classes = useStyles()

  const title = item.title.rendered
  // const category = item.categories && item.categories.length ? item.categories[0].name : ''
  const { type, duration, jetpack_featured_media_url } = item
  return (
    <ButtonBase onClick={selectContent} disabled={disabled}>
      <Grid container justifyContent='flex-start' className={classes.container} style={!bottomTrail ? { paddingBottom: 0 } : {}}>
        {bottomTrail && <div className={classes.bottomTrail} />}
        <Grid
          item
          className={classNames(classes.imageContainer, (selected && classes.imageContainerSelected))}
          style={{ backgroundImage: `url(${itemStatusForUser === 'read' ? '/icons/pathway-done.svg' : jetpack_featured_media_url})` }}
        />
        <Grid container item xs alignItems='center'>
          <Grid container item direction='column' alignItems='flex-start' justifyContent='center'>
            <Grid container alignItems='flex-end' className={classes.titleContainer}>
              <Typography variant='subtitle1' className={classes.title} dangerouslySetInnerHTML={{ __html: title }} />
              {selected && <Typography className={classes.youAreReadingThis}>You are reading this</Typography>}
            </Grid>
            <Grid container className={classes.additionalInfo}>
              {type && <Typography variant='caption' className={classes.typeTypo}>{item?.acf?.type || 'Article'}</Typography>}
              {type && duration && <Typography variant='caption'> | </Typography>}
              {duration && <Typography variant='caption'>Estimated time • {item.duration} min</Typography>}
            </Grid>
            {type && <div style={{ height: '20px' }} />}
            {/* <Typography variant='body2'>{category}</Typography> */}
          </Grid>
        </Grid>
      </Grid>
    </ButtonBase>
  )
}

export default ToolPathwayItem
