import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(({ palette: { button, primary } }) => ({
  container: {
    backgroundColor: button.default,
    color: button.color,
    borderColor: button.border,
    borderRadius: '7px',
    width: '230px',
    height: '48px'
  },
  trackerTitle: {
    fontSize: '13px'
  },
  left: {
    borderRight: '2px solid #38435C',
    '&:hover': {
      borderRadius: '7px',
      backgroundColor: primary.main,
      color: '#FFF',
      borderRight: '2px solid rgba(175, 22, 101, 0.8)'
    }
  },
  right: {
    '&:hover': {
      borderRadius: '7px',
      backgroundColor: primary.main,
      color: '#FFF'
    }
  },
  buttonCaption: {
    fontSize: '8px'
  }
}))
