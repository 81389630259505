import React, { createContext, useContext, useState } from 'react'
import { Backdrop } from '@mui/material'

export const BackdropContext = createContext({ setOpen: () => { } })

export const useBackdropContext = () => {
  return useContext(BackdropContext)
}

export const BackdropContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false)

  return (
    <BackdropContext.Provider value={{ setOpen }}>
      <Backdrop open={open} style={{ zIndex: 1201 }} />
      {children}
    </BackdropContext.Provider>
  )
}
