import makeStyles from '@mui/styles/makeStyles'

const drawerWidth = 260
export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  drawer: {
    padding: 24,
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    // padding: '24px 0 0 24px'
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
    justifyContent: 'center'
  },
  features: {
    fontSize: '14px',
    padding: '16px'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    padding: '0 8px',
    high: '86px',
    ...theme.mixins.toolbar
  },
  paddingTop: {
    paddingTop: '30px'
  }
}))
