import name from './name'

const selectSliceData = state => state[name]

const selectResponses = state => selectSliceData(state).responses
const selectSteps = state => selectSliceData(state).steps
const selectResult = state => selectSliceData(state).result
const selectShowIntroModal = state => selectSliceData(state).showIntroModalOpen
const selectLoadingUserIndicatorAnwers = state => selectSliceData(state).loadingUserIndicatorAnwers

export {
  selectResponses,
  selectSteps,
  selectResult,
  selectShowIntroModal,
  selectLoadingUserIndicatorAnwers
}
