import React from 'react'
import { Typography, Tooltip, Skeleton } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useStyles } from './style'

const ScoreBadge = ({ title, description, vertical, center, ...props }) => {
  const classes = useStyles({ vertical })

  return (
    <div className={classes.root}>
      <Typography className={center ? `${classes.title} ${classes.center}` : classes.title}> {title || <Skeleton />} </Typography>
      <Typography className={center ? `${classes.score} ${classes.center}` : classes.score}>
        {props.children || <Skeleton />}
      </Typography>
      {description && (
        <Tooltip title={description}>
          <InfoOutlinedIcon className={classes.informationIcon} />
        </Tooltip>
      )}
    </div>

  )
}

export default ScoreBadge
