import React from 'react'
import { InformationalCard } from '../../components'
import { useStyles } from './style'

const DataUsageCard = ({ accState }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <InformationalCard title='How My Data Is Being Used' accState={accState}>

        <div className={classes.cardsContainer}>
          <p>The data collected through the use of the Resilience Dynamic Dashboard® is used anonymously by the Resilience Engine for research purposes.</p>

          <p>Your organisation has requested that the following information is captured. The purpose of this is to track and aggregate data and activity on the dashboard, then filter by the fields below.</p>

          <p><b>Note:</b> Managers and leaders have access to a Team Dashboard which displays an aggregation of their team's data, offering insight into the average resilience and wellbeing of their people as a whole. Managers cannot view individual results and can only see averages for 4 or more participants at a time.</p>
        </div>
      </InformationalCard>
    </div>
  )
}

export default DataUsageCard
