import React, { useEffect } from 'react'
import { useDashboardContext, useOnboarding } from '../../hooks'
import WelcomeModal from './welcome-modal'
import SelfAssessmentModal from './self-assessment-modal'
import ToolpathwaysModal from './tool-pathways-modal'
import AfterSelfAssessmentModal from './after-self-assessment-modal'
import OrganisationalDriversModal from './organisation-drivers-modal'
import ResilienceFundamentalsModal from './resilience-fundamentals-modal'
import CheckinCheckoutModal from './checkin-checkout-modal'
import ResilienceToolkitModal from './resilience-toolkit'
import SelfAssessmentReport from './self-assessment-report-modal'
import FinishModal from './finish-modal'
import ResilienceTrackerModal from './resilience-tracker-modal'
import { cacheImages } from '../../helpers/cacheImages'
import { useEventContext } from '../../contexts/event'

export default function InitialOnboarding () {
  const dashboard = useDashboardContext()
  const { setIsOnBoarding, isOnBoarding, isOnFeature, features, current, next, previous, cancelOnBoarding, userOnBoarding } = useOnboarding()
  const { getUserOnboarding } = useEventContext()
  useEffect(() => {
    if (!getUserOnboarding.hasBeenTriggered || getUserOnboarding.isWorking) return
    if (!userOnBoarding.find(i => i === 'ONBOARDING')) {
      setIsOnBoarding(true)
    }
  }, [userOnBoarding, setIsOnBoarding, getUserOnboarding.hasBeenTriggered, getUserOnboarding.isWorking])

  useEffect(() => {
    cacheImages([
      'https://static.resilienceengine.live/img/onboarding-welcome.svg',
      'https://static.resilienceengine.live/img/onboarding-toolpathways.svg',
      'https://static.resilienceengine.live/img/onboarding-self-assessment-v2.svg',
      'https://static.resilienceengine.live/img/onboarding-after-self-assessment-v2.svg',
      'https://static.resilienceengine.live/img/onboarding-resilience-tracker-v2.svg',
      'https://static.resilienceengine.live/img/onboarding-organisation-drivers.svg',
      'https://static.resilienceengine.live/img/onboarding-resilience-fundamentals-v2.svg',
      'https://static.resilienceengine.live/img/onboarding-checkin-checkout-v2.svg',
      'https://static.resilienceengine.live/img/toolkit-overview-v2.svg',
      'https://static.resilienceengine.live/img/onboarding-self-assessment-report-v2.svg',
      'https://static.resilienceengine.live/img/onboarding-after-self-assessment-v2.svg',
      'https://static.resilienceengine.live/img/additional-user-settings-congratulations.png'
    ])
  }, [])

  if (!features?.length || dashboard.changeDashboardFilter.isWorking) return null
  if (!isOnBoarding) return null
  return <current.component next={next} previous={previous} cancelOnBoarding={cancelOnBoarding} isOnFeature={isOnFeature} />
}

export {
  WelcomeModal,
  SelfAssessmentModal,
  AfterSelfAssessmentModal,
  ResilienceTrackerModal,
  OrganisationalDriversModal,
  ResilienceFundamentalsModal,
  ResilienceToolkitModal,
  CheckinCheckoutModal,
  SelfAssessmentReport,
  ToolpathwaysModal,
  FinishModal
}
