import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
    borderRadius: '10px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)'
  },
  informationIcon: {
    cursor: 'pointer',
    margin: '0 5px',
    width: 19,
    height: 19,
    fill: theme.palette.secondary.main
  },
  startNowButton: {
    width: theme.spacing(18)
  },
  card:{
    width: '164px',
    heigth: '167px'
  }
}))
