import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

// Store
export const login = createSlice({
  name: reducerName,
  initialState: {
    userAuthenticated: false,
    username: '',
    ssoId: ''
  },
  reducers: {
    setIsAuthenticated: (state, { payload }) => {
      state.userAuthenticated = payload
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setIsAuthenticated
} = login.actions

// Reducer
export default login.reducer
