import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '1000px',
    height: '700px',
    borderRadius: '45px',
    padding: theme.spacing(3),
    overflowY: 'clip'
  }
}))
