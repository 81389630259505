import React from 'react'
import { Button } from '@mui/material'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { useStyles } from './style'

const UsersBadge = ({ totalUsers }) => {
  const classes = useStyles()

  return (
    <Button
      startIcon={<PeopleAltIcon />}
      classes={{ startIcon: classes.peopleIcon }}
      className={classes.orgButton}
    >
      Users included: {totalUsers}
    </Button>
  )
}

export default UsersBadge
