import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

export const cicoSlice = createSlice({
  name: reducerName,
  initialState: {
    info: null,
    steps: [],
    contents: []
  },
  reducers: {
    setCICOInfo: (state, { payload }) => {
      const { steps, contents, info } = payload
      state.steps = steps
      state.contents = contents
      state.info = info
    },
    updateProgress: (state, { payload }) => {
      const { progress, state: contentState, stepId, contentId, answers } = payload
      // search for the content to update, and keep the highest value
      state.contents = state.contents.map(content =>
        content.stepId === stepId && content.contentId === contentId
          ? { ...content, answers, state: contentState, progress: content.progress > progress ? content.progress : progress }
          : content
      )
    }
  }
})

export const {
  setCICOInfo,
  updateProgress
} = cicoSlice.actions

// Reducer
export default cicoSlice.reducer
