import React, { useState, createContext } from 'react'
import createMuiTheme from '../theme'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

const initialState = false

export const CustomThemeContext = createContext(initialState)

export function CustomThemeProvider ({ children }) {
  const [isDarkTheme, setIsDarkTheme] = useState(initialState)

  return (
    <CustomThemeContext.Provider value={{ isDarkTheme, setIsDarkTheme }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createMuiTheme({ darkTheme: isDarkTheme })}>
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </CustomThemeContext.Provider>
  )
}
