import React, { useEffect, useState } from 'react'
import { Typography, Switch, CircularProgress } from '@mui/material'
import { useStyles } from './style'
import OneSignal from 'react-onesignal'

const ProfileNotificationsSwitch = ({ name, label, value, disabled, setNativeNotificationStatus, nativeNotificationStatus, updateUserSettings }) => {
  const classes = useStyles()
  const [localLoading, setLocalLoading] = useState(false)

  const handleChange = (e) => {
    const newValue = !value
    if (newValue) {
      if (nativeNotificationStatus === 'granted') {
        updateUserSettings.trigger({ type: 'notifications', name: 'browser', value: true })
      } else {
        OneSignal.showSlidedownPrompt({ force: true })
        setLocalLoading(true)
      }
    } else {
      updateUserSettings.trigger({ type: 'notifications', name: 'browser', value: false })
    }
  }

  useEffect(() => {
    OneSignal.getNotificationPermission().then((permission) => {
      setNativeNotificationStatus(permission)
    })
    OneSignal.on('notificationPermissionChange', (permission) => {
      if (permission.to === 'granted') {
        updateUserSettings.trigger({ type: 'notifications', name: 'browser', value: true })
      }
      setLocalLoading(false)
      setNativeNotificationStatus(permission.to)
    })
    OneSignal.on('popoverCancelClick', (...args) => setLocalLoading(false))
  }, [updateUserSettings, setNativeNotificationStatus])

  return (
    <div className={classes.field}>
      {!!label && <Typography className={classes.fieldName}>{label}</Typography>}
      {localLoading || updateUserSettings.isWorking ? <CircularProgress size={30} /> :
        <Switch
          value={name}
          checked={value}
          disabled={disabled || updateUserSettings.isWorking}
          onChange={handleChange}
          classes={{
            thumb: (() => {
              if (disabled) {
                return classes.switchThumbDisabled
              }
              if (value) {
                return classes.switchThumb
              }
              return null
            })(),
            track: value ? classes.switchTrack : null
          }}
        />
      }
    </div>
  )
}
export default ProfileNotificationsSwitch
