import { Typography, Button, Divider } from '@mui/material'
import React from 'react'
import { useStyles } from './style.js'
import GetAppIcon from '@mui/icons-material/GetApp'
import { lowDescriptions, highDescriptions, levelDescriptions } from '../../../helpers/copy'

const SmallDivider = () => {
  const classes = useStyles()
  return (
    <div className={classes.smallDivider} />
  )
}

const ResilienceSurveyResult = ({ score, level, high, low, variance, pdfLink, levelKey }) => {
  const classes = useStyles()
  const lowLevelKey = ['breakdown', 'fragmentation'].includes(levelKey)
  return (
    <div className={classes.root}>
      {!lowLevelKey && (
        <>
          <Typography className={classes.resultHeader} variant='body2'>Your Resilience Score is {score}</Typography>
          <Typography className={classes.resultLevel} variant='body2'>You are {level === 'Break Even' ? `in the middle of ${level}` : level}</Typography>
          <SmallDivider />
        </>
      )}
      <div className={classes.results}>
        {levelDescriptions[level].map((text, i) =>
          <Typography key={i} className={classes.resultText} variant='body2'><div dangerouslySetInnerHTML={{ __html: text }} /></Typography>
        )}
        {!lowLevelKey &&
          <>
            <Typography variant='subtitle1'>Your Personalized Report</Typography>
            <div className={classes.reportsBox}>
              <Button
                className={classes.reportsBtn}
                startIcon={<GetAppIcon />}
                variant='outlined'
                onClick={() => window.open(`${pdfLink}`)}
              >
                Download Your Report
              </Button>
            </div>
            <Typography className={classes.resultTextBold}>Average High Score - {high}</Typography>
            <Typography className={classes.resultText}>{highDescriptions[high]}</Typography>
            <Divider />
            <Typography className={classes.resultTextBold}>Average Low Score - {low}</Typography>
            <Typography className={classes.resultText}>{lowDescriptions[low]}</Typography>
            <Divider />
            <Typography className={classes.resultTextBold}>Average Variance Score - {variance}</Typography>
            <Typography className={classes.resultText}>Your Average Variance Score illustrates how stable your resilience is. Stability is critical for Resilience. The more variable, the more it illustrates that your Resilient is dependent on specific conditions, and that these aren`t always being met.</Typography>
            <ul>
              <li><Typography className={classes.resultText}>If your variance is high, these conditions are not being met at all well in the contexts in which you operate.</Typography></li>
              <li><Typography className={classes.resultText}>If your variance is medium, these conditions are often met in the context in which you operate. How might you create the conditions in all of your contexts?</Typography></li>
              <li><Typography className={classes.resultText}>If your variance is low, and your Resilience is low, it may in fact not be good news. You are likely to be stuck. To get unstuck, consider the key enablers of Resilience - and particularly your Energy.</Typography></li>
              <li><Typography className={classes.resultText}>If your variance is low, and your Resilience is a middle value, it`s all about alignment of the things that matter for your resilience. It might be alignment of Energy against what you`re doing. Or it might be aligning towards more purposeful living. There are others alignment factors that you can explore elsewhere in the Toolkit.</Typography></li>
              <li><Typography className={classes.resultText}>If your variance is low, and your Resilience is high, that`s good news. Keep at it!</Typography></li>
            </ul>
            <Divider />
          </>}
      </div>
    </div>
  )
}

export default ResilienceSurveyResult
