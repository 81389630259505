import React from 'react'
import ReLoading from '../re-loading'

const ReAuthenticatingAuth = () => {
  return (
    <ReLoading message='Authenticating...​' />
  )
}

export default ReAuthenticatingAuth
