import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: '#F6F8FB',
    border: '0px',
    borderRadius: '100px',
    fontSize: '13px',
    lineHeight: '16px',
    color: '#A0A4A8',
    padding: theme.spacing(1, 2),
    width: '350px'
  }
}))
