import { Typography, Paper, Popover, Grid, Tooltip, Skeleton } from '@mui/material'
import React from 'react'
import { ReButton, DashboardAverage, Empty } from '../../components'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import { useStyles } from './style.js'
import useDashboardContext from '../../hooks/useDashboardContext'

const DashboardTracker = ({ title, description, startExpand = true, emptyTitle, emptyDescription, startHandler, vertical, horizontal, buttonEnabled = true, values = [], ...props }) => {
  const classes = useStyles()
  const [popoverAnchor, setPopoverAnchor] = React.useState()
  const [expandCard, toggleExpandCard] = React.useState(startExpand)
  const { isIndividualView, viewLoading, isOrgProfile } = useDashboardContext()

  const open = Boolean(popoverAnchor)
  const anchorId = open ? 'tracker-popover' : undefined
  const trackerValues = values
  const textStyle = trackerValues && trackerValues.length ? null : classes.emptyTextColor

  const contentStyle = vertical ? classes.verticalContent : horizontal
    ? classes.horizontalContent : classes.content

  const closePopover = () => {
    setPopoverAnchor(null)
  }

  const toggleCardExpansion = () => {
    toggleExpandCard(!expandCard)
  }

  const emptyMessage = !!isOrgProfile ? 'No data to display. This is because not enough people have provided results for the filtered criteria' : 'There is no data to display'

  return (
    <Paper elevation={0} className={classes.root}>
      <Grid container>
        <Grid item xs={11} className={classes.header}>
          <Typography className={textStyle} variant='h5'>{title}</Typography>
          <Tooltip
            title={description}
          >
            <InfoOutlinedIcon className={classes.popoverIcon} />
          </Tooltip>
        </Grid>
        <Grid item xs={1} className={classes.showHideIcon}>
          {expandCard ? (
            <IndeterminateCheckBoxOutlinedIcon className={classes.collapseIcon} onClick={toggleCardExpansion} />
          ) : (
            <AddBoxOutlinedIcon className={classes.expandIcon} onClick={toggleCardExpansion} />
          )}
        </Grid>
        {expandCard && trackerValues.length <= 0 && !startHandler && !viewLoading ? <Empty message={emptyMessage}/> : (
          <Grid item xs={12}>
            {expandCard && startHandler && trackerValues.length === 0 && isIndividualView && !viewLoading && (
              <div className={contentStyle}>
                <Typography className={classes.emptyTitle} variant='h6'>{emptyTitle}</Typography>
                <Typography className={classes.emptyDescription} variant='body2'>{emptyDescription}</Typography>
                <ReButton name='Start now' action={startHandler} disabled={!buttonEnabled} dataTestId='start_now_btn' />
              </div>
            )}
            {viewLoading && <Skeleton height={500} variant='rectangular' />}

            {expandCard && startHandler && trackerValues.length === 0 && !isIndividualView && !viewLoading && (<Empty message={emptyMessage}/>)}

            {expandCard && trackerValues.length > 0 && props.children === undefined && !viewLoading && (
              <Grid container className={contentStyle} gap={2}>
                {trackerValues.filter(tracker => tracker.title && tracker.value).map((result, i) => (
                  <Grid item xs={horizontal ? 3 : 12} key={i}>
                    <DashboardAverage
                      type={result.type}
                      title={result.title}
                      value={result.value}
                      description={result.description}
                    />
                  </Grid>
                ))}
              </Grid>
            )}

            {expandCard && trackerValues.length > 0 && props.children && !viewLoading && (
              <div className={isIndividualView ? contentStyle : classes.orgContent}>
                {props.children}
              </div>
            )}
          </Grid>
        )}
      </Grid>
      <Popover
        id={anchorId}
        open={open}
        anchorEl={popoverAnchor}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Typography className={classes.popover}>{description}</Typography>
      </Popover>
    </Paper>
  )
}

export default DashboardTracker
