import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userOnboardingSlice, userSlice } from '../reducers'
import * as components from '../components/initial-onboarding'
import { useCorvusEventList } from '@emerald-works/react-event-bus-client'
import userOnboarding from '../reducers/userOnboarding'

const useOnboarding = () => {
  const dispatch = useDispatch()
  const user = useSelector(userSlice.selectors.selectUser)
  const features = useMemo(() => user?.features, [user?.features])
  const [onBoardingWasDone] = useCorvusEventList([userOnboarding.eventBus.saveUserOnboarding])
  const userOnBoarding = useSelector(userOnboardingSlice.selectors.selectUserOnboarding)

  const onBoardingStatus = useSelector(userOnboardingSlice.selectors.selectUserOnboarding)
  const currentOnBoardingStep = useSelector(userOnboardingSlice.selectors.selectCurrentOnBoardingStep)
  const isOnBoarding = useSelector(userOnboardingSlice.selectors.selectIsOnBoarding)
  const isOnFeature = useSelector(userOnboardingSlice.selectors.selectIsOnFeature)
  const setCurrentOnBoardingStep = useCallback((step) => dispatch(userOnboardingSlice.actions.setCurrentOnBoardingStep(step)), [dispatch])
  const setIsOnBoarding = useCallback((isOnBoarding) => dispatch(userOnboardingSlice.actions.setIsOnBoarding(isOnBoarding)), [dispatch])
  const setIsOnFeature = useCallback((isOnFeature) => dispatch(userOnboardingSlice.actions.setIsOnFeature(isOnFeature)), [dispatch])

  // logical OR for these lists
  const mapSteps = useMemo(() => ({
    0: { component: components.WelcomeModal, needed: [] },
    1: { component: components.SelfAssessmentModal, needed: ['RESILIENCE_INDICATOR', 'RESILIENCE_EVALUATION'] },
    2: { component: components.AfterSelfAssessmentModal, needed: ['RESILIENCE_INDICATOR', 'RESILIENCE_EVALUATION'] },
    3: { component: components.ToolpathwaysModal, needed: ['TOOL_PATHWAYS'] },
    4: { component: components.ResilienceTrackerModal, needed: ['RESILIENCE_TRACKER'] },
    5: { path: 'drivers', component: components.OrganisationalDriversModal, needed: ['ORGANISATION_DRIVERS'] },
    6: { path: 'fundamentals', component: components.ResilienceFundamentalsModal, needed: ['FUNDAMENTALS'] },
    7: { path: 'cico-main', component: components.CheckinCheckoutModal, needed: ['CHECKIN_CHECKOUT'] },
    8: { component: components.ResilienceToolkitModal, needed: ['RESILIENCE_TOOLKIT'] },
    9: { path: 'reports', component: components.SelfAssessmentReport, needed: ['RESILIENCE_INDICATOR', 'RESILIENCE_EVALUATION', 'RESILIENCE_TRACKER'] },
    10: { component: components.FinishModal, needed: [] }
  }), [])

  const findPreviousOrNextStep = useCallback((isForward, overrideCurrent) => {
    let counter = currentOnBoardingStep + (isForward ? 1 : -1)
    if (overrideCurrent !== undefined) counter = overrideCurrent
    while (true) {
      const nextStep = mapSteps[counter]
      if (!nextStep.needed.length) break
      if (nextStep.needed.find(featureRequired => features.find(featureAvailable => featureAvailable.featureId === featureRequired))) {
        break
      }
      counter = counter + (isForward ? 1 : -1)
    }
    setCurrentOnBoardingStep(counter)
  }, [currentOnBoardingStep, features, mapSteps, setCurrentOnBoardingStep])

  const current = useMemo(() => mapSteps[currentOnBoardingStep], [currentOnBoardingStep, mapSteps])
  const next = useCallback(() => findPreviousOrNextStep(true), [findPreviousOrNextStep])
  const previous = useCallback(() => findPreviousOrNextStep(false), [findPreviousOrNextStep])

  const cancelOnBoarding = useCallback(() => {
    setIsOnBoarding(false)
    setCurrentOnBoardingStep(0)
    setIsOnFeature(false)
    onBoardingWasDone.trigger({ featureId: 'ONBOARDING' })
  }, [setIsOnBoarding, setCurrentOnBoardingStep, setIsOnFeature, onBoardingWasDone])

  const openOnHome = () => {
    setIsOnBoarding(true)
    findPreviousOrNextStep(true, 3)
  }

  const openOnFeature = (path) => {
    setIsOnBoarding(true)
    const [key] = Object.entries(mapSteps).find(([k, step]) => step.path === path)
    setCurrentOnBoardingStep(parseInt(key, 10))
    setIsOnFeature(true)
  }

  return {
    mapSteps,
    onBoardingStatus,
    isOnBoarding,
    isOnFeature,
    setIsOnBoarding,
    cancelOnBoarding,
    features,
    next,
    previous,
    current,
    openOnHome,
    openOnFeature,
    userOnBoarding
  }
}

export default useOnboarding
