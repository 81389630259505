import * as actions from './slice'

export const getInfo = {
  eventName: 'CICO Info Was Requested',
  onSuccess: [{ redux: true, action: actions.setCICOInfo }]
}

export const cicoProgressWasUpdated = {
  eventName: 'CICO Progress Was Updated',
  subscribeOnTrigger: true,
  onSuccess: [{ redux: true, action: actions.updateProgress }]
}

export const cicoProgressWasChanged = {
  eventName: 'CICO Progress Was Changed',
  onSuccess: [{ redux: true, action: actions.updateProgress }]
}
