import React from 'react'
import { Grid } from '@mui/material'
import { useStyles } from './style'

const QuestionRow = ({ question, score }) => {
  const classes = useStyles()

  return (
    <Grid xs={12} item>
      <Grid container justifyContent='space-between' alignItems='center' className={classes.row}>
        <Grid item>{question}</Grid>
        <Grid item>{score}</Grid>
      </Grid>
    </Grid>
  )
}

const ScoreQuestions = ({ questions = [], ...props }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xs={12} item>
          <Grid container justifyContent='space-between' alignItems='center' className={classes.header}>
            <Grid item>Question</Grid>
            <Grid item>Score</Grid>
          </Grid>
        </Grid>
        {questions.map((question, index) => <QuestionRow key={index} question={question.question} score={question.score} />)}

      </Grid>
    </div>

  )
}

export default ScoreQuestions
