import React from 'react'
import { Grid } from '@mui/material'
import { ReButton } from '..'
import { useStyles } from './style'
import useDashboardContext from '../../hooks/useDashboardContext'
import { useDispatch } from 'react-redux'
import { aggregationConfigs } from '../../reducers'

const TrackerFilter = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const selectedBgColor = '#303155'
  const selectedColor = '#FFF'

  const handleChangeRange = (range) => {
    dispatch(aggregationConfigs.actions.setFilterRange(range))
  }

  const { filters } = useDashboardContext()

  return (
    <Grid display='flex' alignItems='center' className={classes.filterBar}>
      <Grid item className={classes.toolbarOpts}>
        <ReButton
          small
          name='Days'
          dataTestId='days_filter_btn'
          action={() => handleChangeRange('day')}
          bgColor={filters.range === 'day' ? selectedBgColor : '#FFF'}
          color={filters.range === 'day' ? selectedColor : '#000'}
        />
        <ReButton
          small
          name='Weeks'
          dataTestId='weeks_filter_btn'
          action={() => handleChangeRange('week')}
          bgColor={filters.range === 'week' ? selectedBgColor : '#FFF'}
          color={filters.range === 'week' ? selectedColor : '#000'}
        />
        <ReButton
          small
          name='Months'
          dataTestId='months_filter_btn'
          action={() => handleChangeRange('month')}
          bgColor={filters.range === 'month' ? selectedBgColor : '#FFF'}
          color={filters.range === 'month' ? selectedColor : '#000'}
        />
      </Grid>
    </Grid>
  )
}

export default TrackerFilter
