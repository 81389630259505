import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import reducerName from './name'

// Store
export const tracker = createSlice({
  name: reducerName,
  initialState: {
    filters: {
      date: { start: moment().startOf('day').subtract(7, 'days').format(), end: moment().endOf('day').format() },
      dateHasErrors: [null, null],
      range: 'day',
      type: '',
      demographicFields: {}
    }
  },
  reducers: {
    setFilters: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload }
    },
    setFilterRange: (state, { payload }) => {
      state.filters.range = payload
    },
    setFilterType: (state, { payload }) => {
      state.filters.type = payload
    },
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setFilters,
  setFilterRange,
  setFilterType
} = tracker.actions

// Reducer
export default tracker.reducer
