import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 18,
    position: 'relative',
    border: '3px solid #E8E8E8',
    borderRadius: 8,
    padding: '5px 28px',
    margin: theme.spacing(0, 1),
    width: ({ vertical }) => vertical ? '100%' : `calc(50% - ${theme.spacing(2)})`
  },
  title: {
    fontWeight: 700,
    fontSize: 14,
    color: '#25282B'
  },
  score: {
    color: '#465270',
    fontSize: 18,
    fontWeight: 400,
    '& b': {
      fontSize: 20,
      color: theme.palette.primary.main,
      fontWeight: 700
    }
  },
  informationIcon: {
    cursor: 'pointer',
    position: 'absolute',
    bottom: 8,
    right: 8,
    width: 19,
    height: 19,
    fill: '#D0D1D2'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  }
}))
